import { useEffect } from 'react'
import Iframe from 'react-iframe'
import { generatePath, useNavigate } from 'react-router-dom'
import { Button } from '@equipe-ninja/saraiva-ui'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded'
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded'
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'
import { Box, Typography } from '@material-ui/core'
import { useClassContext } from '@contexts/class'
import useStyles from './styles'
import { ROUTES } from '@routes/app-routes'
import { useRecoilValue } from 'recoil'
import { courseIdSelector } from '@atoms/course'

export function ClassRoom() {
  const classes = useStyles()
  const { courseId } = useRecoilValue(courseIdSelector)
  const { currentClass, previousClass, nextClass, setCurrentClassAsWatched } =
    useClassContext()
  const navigateTo = useNavigate()

  const navigateToClass = (classId: number | undefined) => {
    classId &&
      navigateTo(
        generatePath(ROUTES.CLASS, {
          classId: classId.toString(),
          courseId: courseId.toString(),
        })
      )
  }

  const classContentStream = currentClass?.dataMaterials?.find(
    (material) => !!material.contentStream
  )

  useEffect(() => {
    window.onmessage = async function (e) {
      if (e.data.eventName === 'onPlay') {
        await setCurrentClassAsWatched()
      }
    }
  }, [window, setCurrentClassAsWatched])

  return (
    <>
      <div className={classes.container}>
        <Box className={classes.boxTitle}>
          <Button
            disabled={!previousClass}
            className={classes.buttonPrev}
            variant="outlined"
            startIcon={<SkipPreviousIcon />}
            color="primary"
            onClick={() => {
              navigateToClass(previousClass?.idClass)
            }}
          >
            <span className={classes.nextPrevButtonText}>aula anterior</span>
          </Button>

          <Box className={classes.boxClassName}>
            {currentClass?.classStudentWatched ? (
              <>
                <div style={{ position: 'relative' }}>
                  <div className={classes.checkedColorEffect} />
                  <CheckCircleRounded className={classes.checkCircle} />
                </div>
              </>
            ) : (
              <VideocamRoundedIcon className={classes.videoCam} />
            )}
            <Typography className={classes.classTitle} variant="h6">
              {currentClass?.nameSubTheme}
            </Typography>
          </Box>

          <Button
            disabled={!nextClass}
            className={classes.buttonNext}
            variant="contained"
            startIcon={<SkipNextIcon />}
            color="primary"
            onClick={() => {
              navigateToClass(nextClass?.idClass)
            }}
          >
            <span className={classes.nextPrevButtonText}>próxima aula</span>
          </Button>
        </Box>

        <Box className={classes.video}>
          {currentClass && classContentStream && (
            <Iframe
              allowFullScreen={true}
              className={classes.iframe}
              url={`https://mdstrm.com/embed/${classContentStream.contentStream}`}
            />
          )}
        </Box>
        <Box className={classes.boxTitleMob}>
          {currentClass?.classStudentWatched ? (
            <>
              <div style={{ position: 'relative' }}>
                <div className={classes.checkedColorEffect} />
                <CheckCircleRounded className={classes.checkCircle} />
              </div>
            </>
          ) : (
            <VideocamRoundedIcon className={classes.videoCam} />
          )}
          <Box className={classes.boxClassName}>
            <Typography variant="caption">Aula atual</Typography>
            <Typography variant="body1">
              {currentClass?.nameSubTheme}
            </Typography>
          </Box>
          <SkipPreviousRoundedIcon
            onClick={() => {
              navigateToClass(previousClass?.idClass)
            }}
            color="action"
            fontSize="large"
            className={classes.buttonPrevMob}
          />
          <SkipNextRoundedIcon
            onClick={() => {
              navigateToClass(nextClass?.idClass)
            }}
            color="action"
            fontSize="large"
            className={classes.buttonNextMob}
          />
        </Box>
      </div>
    </>
  )
}
