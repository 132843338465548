import {
  FC,
  useMemo,
  useEffect,
  ReactNode,
  useContext,
  createContext,
  useState,
  useCallback,
} from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  disciplineDetailState,
  openNextClassExpandedState,
} from '@atoms/course/course-selector'
import { courseDataSelector } from '@atoms/course'
import {
  Disciplines,
  gerarProximoEstadoParaAulaAssistida,
  SubThemeProps,
} from '@domain/discipline'
import { setDisciplineClassWatched } from '@services/discipline/set-discipline-class-watched'
import { ROUTES } from '../../routes/app-routes'

type ClassContextData = {
  disciplines: Disciplines[]
  classDisciplineIndex: number
  currentClass?: SubThemeProps
  previousClass?: SubThemeProps
  currentClassIds: {
    subject_id: number
    theme_id: number
    class_id: number
  }
  nextClass?: SubThemeProps
  setCurrentClassAsWatched: () => Promise<void>
}

export const ClassContext = createContext({} as ClassContextData)

export const ClassProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const { classId, courseId } = useParams()
  const classIdAsInteger: number = (classId &&
    Number(classId)) as unknown as number
  const courseIdAsInteger: number = (courseId &&
    Number(courseId)) as unknown as number

  if (!classIdAsInteger && courseIdAsInteger) {
    navigate(
      generatePath(ROUTES.DISCIPLINE, {
        courseId: courseIdAsInteger.toString(),
      })
    )
  }

  const [disciplines, setDisciplines] = useRecoilState(disciplineDetailState)
  const setOpenNextClassExpanded = useSetRecoilState(openNextClassExpandedState)
  const setCourseDataState = useSetRecoilState(courseDataSelector)
  const [classDisciplineIndex, setClassDisciplineIndex] = useState(0)
  const [classThemeIndex, setClassThemeIndex] = useState(0)
  const [classIndex, setClassIndex] = useState(0)

  useEffect(() => {
    if (!disciplines || disciplines.length === 0) return

    disciplines.forEach((discipline, disciplineIndex) => {
      discipline.dataTheme.forEach((theme, themeIndex) => {
        const currentClassIndex = theme.dataSubTheme.findIndex(
          (aula) => classId && aula.idClass === parseInt(classId, 10)
        )

        if (currentClassIndex !== -1) {
          setClassDisciplineIndex(disciplineIndex)
          setClassThemeIndex(themeIndex)
          setClassIndex(currentClassIndex)

          setOpenNextClassExpanded({
            disciplineId: discipline.disciplineId,
            themeId: theme.themeId,
            subThemeId: theme.dataSubTheme[currentClassIndex].idClass,
          })
        }
      })
    })
  }, [disciplines, classId])

  const currentDiscipline = disciplines?.at(classDisciplineIndex)
  const currentTheme = currentDiscipline?.dataTheme?.at(classThemeIndex)
  const currentClass = currentTheme?.dataSubTheme?.at(classIndex)

  const setCurrentClassAsWatched = useCallback(async () => {
    if (!currentClass || !currentDiscipline || currentClass.classStudentWatched)
      return

    await setDisciplineClassWatched(
      courseIdAsInteger,
      currentDiscipline.disciplineId,
      currentClass.idClass
    ).then(() => {
      setDisciplines((prev) =>
        gerarProximoEstadoParaAulaAssistida(prev, classIdAsInteger)
      )

      setCourseDataState((prev) => ({
        ...prev,
        classProgressRequestId: prev.classProgressRequestId + 1,
      }))
    })
  }, [currentClass, currentDiscipline])

  const previousClass = useMemo(() => {
    let prevClass = currentTheme?.dataSubTheme?.[classIndex - 1]

    if (!prevClass) {
      const prevTheme = currentDiscipline?.dataTheme?.[classThemeIndex - 1]

      if (!prevTheme) {
        const prevDiscipline = disciplines?.[classDisciplineIndex - 1]

        if (!prevDiscipline) return undefined

        const prevDisciplineThemes = prevDiscipline.dataTheme
        const prevThemesSubthemes =
          prevDisciplineThemes?.[prevDisciplineThemes.length - 1]?.dataSubTheme

        prevClass = prevThemesSubthemes?.[prevThemesSubthemes.length - 1]
      } else {
        prevClass = prevTheme.dataSubTheme?.[prevTheme.dataSubTheme.length - 1]
      }
    }

    return prevClass
  }, [
    currentTheme,
    currentDiscipline,
    classIndex,
    classThemeIndex,
    classDisciplineIndex,
    disciplines,
  ])

  const nextClass = useMemo(() => {
    let nextClass = currentTheme?.dataSubTheme?.[classIndex + 1]

    if (!nextClass) {
      const nextTheme = currentDiscipline?.dataTheme?.[classThemeIndex + 1]

      if (!nextTheme) {
        const nextDiscipline = disciplines?.[classDisciplineIndex + 1]

        if (!nextDiscipline) return undefined

        const nextDisciplineThemes = nextDiscipline.dataTheme
        const nextThemesSubthemes = nextDisciplineThemes?.[0]?.dataSubTheme

        nextClass = nextThemesSubthemes?.[0]
      } else {
        nextClass = nextTheme.dataSubTheme?.[0]
      }
    }

    return nextClass
  }, [
    currentTheme,
    currentDiscipline,
    classIndex,
    classThemeIndex,
    classDisciplineIndex,
    disciplines,
  ])

  return (
    <ClassContext.Provider
      value={{
        disciplines,
        classDisciplineIndex,
        currentClassIds: {
          subject_id: currentDiscipline?.disciplineId || 0,
          theme_id: currentTheme?.themeId || 0,
          class_id: currentClass?.idClass || 0,
        },
        currentClass,
        nextClass,
        previousClass,
        setCurrentClassAsWatched,
      }}
    >
      {children}
    </ClassContext.Provider>
  )
}

export const useClassContext = () => useContext(ClassContext)
