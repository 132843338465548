import Joi, { ObjectSchema } from 'joi'
import { validateAndGenerateErrors } from '../../../utils/validationUtils'
import { CertificateConfiguration } from '../certificate-configuration'

type SchemaFormat = CertificateConfiguration

const schema: ObjectSchema<SchemaFormat> = Joi.object({
  name: Joi.string().required(),
  document: Joi.string().regex(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/).required()
})

export function validarCertificateConfigurationSchema(formValues: SchemaFormat) {
  return validateAndGenerateErrors(schema, formValues)
}