type PercentagePipeOptions = {
  fractionDigits?: number
  defaulValueWhenInvalid?: string
}

const defaultOptions: PercentagePipeOptions = {
  fractionDigits: 2,
  defaulValueWhenInvalid: 'Valor inválido',
}

const invalidValues = [null, undefined] as unknown as number[]

export const asPercentage = (
  value: number,
  options: PercentagePipeOptions = {}
) => {
  const { defaulValueWhenInvalid, fractionDigits } = {
    ...defaultOptions,
    ...options,
  }

  if (invalidValues.includes(value)) {
    return defaulValueWhenInvalid
  }

  return `${value.toLocaleString('pt-BR', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })}%`
}
