import httpClient from '../../gateway/generalHttpRequest'

type ResponseJsonType = {
  class_id: number
  course_id: number
  id: number
  subject_id: number
  user_id: number
}

type ResponseClassWatched = {
  classId: number
  courseId: number
  id: number
  disciplineId: number
  userId: number
}

export const setDisciplineClassWatched = async (
  courseId: number, 
  disciplineId: number, 
  classId: number
): Promise<ResponseClassWatched> => {
  const path = `/offers/api/v1/class_progress/${courseId}/${disciplineId}/${classId}`
  const response = await httpClient.post(path) 
  const responseJson = await response.json() as ResponseJsonType
  return {
    classId: responseJson.class_id,
    courseId: responseJson.course_id,
    id: responseJson.id,
    disciplineId: responseJson.subject_id,
    userId: responseJson.user_id
  }
}
