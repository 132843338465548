import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    minHeight: '468px',
    padding: '46px 24px 24px',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  statusBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 'auto',
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: '0.46px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
  },

  done: { backgroundColor: theme.palette.primary.light },

  available: { backgroundColor: theme.palette.secondary.main },

  unavailable: { backgroundColor: theme.palette.grey[500] },

  hasFeedback: { backgroundColor: theme.palette.primary.light },

  scheduleWrapper: {
    display: 'flex',
    flexDirection: 'column',

    '& span + span': {
      marginTop: 4,
    },
  },

  event: {
    marginLeft: 15,
    position: 'relative',
    color: theme.palette.text.secondary,

    '&:after': {
      content: '""',
      width: 9,
      height: 9,
      background: theme.palette.grey[500],
      borderRadius: '50%',
      position: 'absolute',
      top: 4,
      left: -15,
      right: 'auto',
      bottom: 'auto',
    },

    '&:before': {
      content: '""',
      width: 1,
      height: 'calc(100% + 4px)',
      background: theme.palette.grey[500],
      position: 'absolute',
      top: -4,
      left: -11,
      right: 'auto',
      bottom: 'auto',
    },

    '&:first-child:before': {
      top: 4,
      height: 'calc(100% - 4px)',
    },

    '&:last-child:before': {
      top: -4,
      height: 8,
    },
  },

  currentEvent: {
    fontWeight: 700,
    color: theme.palette.primary.dark,

    '&:after': {
      background: theme.palette.primary.dark,
    },
  },

  warning: {
    width: '100%',
    margin: '24px 0 16px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #B68000',
    borderRadius: 4,
    padding: '8px 8px 8px 17px',

    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fill: '#B68000',
      marginRight: 9,
    },
  },

  button: {
    width: '100%',

    '& + button': {
      marginTop: 8,
    },

    '&.backgroundSecondaryLight': {
      background: theme.palette.secondary.light,
      color: theme.palette.primary.dark,

      '&:hover': {
        background: theme.palette.secondary.main,
      },
    },
  },
}))

export default useStyles
