import { selector } from 'recoil'
import { fixationExerciseOverState } from '../fixation-exercise-stateOver'

export const selectedQuestionSelector = selector({
  key: 'selectedQuestionSelector',
  get: ({ get }) => {
    return get(fixationExerciseOverState).questions[
      get(fixationExerciseOverState).questionIndex
    ]
  },
})
