import { TrackingData, trackingDataSlice, TrackingTypes } from './tracking-data'
import { TrackingTask } from './tracking-task'

export const clicouProsseguirCertificado = (
  state: TrackingData,
  service: TrackingTask
) => {
  const payload = {
    event: TrackingTypes.CLICOU_PROSSEGUIR,
    event_text: TrackingTypes.CLICOU_PROSSEGUIR,
    user_id: state.user_id,
    ...trackingDataSlice(state),
  }

  service(payload)
    .then(() => null)
    .catch(() => null)
}
