import { FC, useCallback, useEffect } from 'react'
import { Container, Typography } from '@material-ui/core'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import useAppStyles from '../../shared/AppStyles'
import { BackButton } from '../../shared/BackButton'
import { ExerciciosInicio } from './components/ExerciciosInicio'
import { ExerciciosExecucao } from './components/ExerciciosExecucao'

import { useStyles } from './styles'
import { useParams } from 'react-router-dom'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { courseDataSelector } from '@atoms/course'
import { questionsSelector } from '@atoms/fixation-exercise'
import {
  getCurrentAttempt,
  getFixationExerciseOverview,
} from '@services/fixation-exercise'
import { useAuth } from '@contexts/auth'

export const ExerciciosFixacao: FC = () => {
  const globalClasses = useAppStyles()
  const styles = useStyles()
  const { exerciseId } = useParams()
  const { userInfo } = useAuth()

  const [
    { overview, resetObservable, isExecutingExercise },
    setFixationSubjecId,
  ] = useRecoilState(fixationExerciseOverState)
  const disciplineState = useRecoilValue(courseDataSelector)
  const setQuestions = useSetRecoilState(questionsSelector)

  const disciplineTitle =
    disciplineState?.discipline?.find(
      (disciplne) => disciplne.disciplineId === Number(exerciseId)
    )?.disciplineName ?? ''

  const setCurrentSubjetId = () => {
    setFixationSubjecId((prev) => ({
      ...prev,
      disciplineId: Number(exerciseId),
    }))
  }

  const getOverview = useCallback(
    (id: number, subjectId: number, userId: number) => {
      getFixationExerciseOverview(id)
        .then((response) => {
          setFixationSubjecId((prev) => ({ ...prev, overview: response }))
        })
        .catch(() => {
          return null
        })
        .finally(() => {
          if (subjectId && userId) {
            getAttempt(subjectId, userId)
          }
        })
    },
    []
  )

  const getAttempt = useCallback((disciplineId: number, userId: number) => {
    if (!disciplineId || !userId) return

    getCurrentAttempt(disciplineId, userId).then((data) => {
      setFixationSubjecId((prev) => ({ ...prev, attempt: data }))
      setQuestions((prev) => ({ ...prev, questions: data?.questoes ?? [] }))
    })
  }, [])

  useEffect(() => {
    if (exerciseId) {
      getOverview(
        Number(exerciseId),
        overview?.subjectId ?? 0,
        userInfo?.id ?? 0
      )
    }
  }, [exerciseId, resetObservable])

  useEffect(() => {
    setCurrentSubjetId()
  }, [exerciseId])

  useEffect(() => {
    if (overview?.subjectId && userInfo?.id) {
      getAttempt(overview?.subjectId, userInfo?.id)
    }
  }, [overview?.subjectId, userInfo?.id])

  return (
    <div className={styles.container}>
      <Container>
        <BackButton />
      </Container>

      <Container>
        <Typography
          variant="h4"
          className={`${globalClasses.title} ${styles.header}`}
        >
          <span className={styles.titlePage}>
            Exercício de fixação | {disciplineTitle}
          </span>
          <Typography
            className={styles.subtitle}
            color="textSecondary"
            variant="body2"
          >
            {overview?.quantidadeQuestoes} questões
          </Typography>
        </Typography>
      </Container>

      {isExecutingExercise ? (
        <ExerciciosExecucao title={disciplineTitle} />
      ) : (
        <ExerciciosInicio title={disciplineTitle} />
      )}
    </div>
  )
}
