import { selector } from 'recoil'
import { courseState } from '../course-state'

export const courseIdSelector = selector({
  key: 'courseIdSelector',
  get: ({ get }) => {
    return {
      courseId: get(courseState).course.id,
    }
  },
})
