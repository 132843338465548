import { Footer } from '@components/Footer'
import { Header } from '@components/Header'
import { Button, Container, Link } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { FC } from 'react'
import { ROUTES } from '@routes/app-routes'
import Erro404 from '../../../assets/images/erro404.png'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles'

export const PageNotFound: FC = () => {
  const styles = useStyles()
  const navigate = useNavigate()

  return (
    <>
      <Header title="" />
      <Container className={styles.container404}>
        <div className={styles.content404}>
          <div className={styles.container_text}>
            <Typography className={styles.error} variant="h4">
              Erro 404
            </Typography>
            <Typography className={styles.page_not_found}>
              Página não encontrada!
            </Typography>
            <p className={styles.page_not_foundText}>
              Até nos esforçamos para achar o que você procura.
            </p>
          </div>
          <div className={styles.list_color}>
            <p>O que pode ter acontecido? </p>
            <ul className={styles.list}>
              <li>A página pode ter sido removida.</li>
              <li>A página pode estar temporariamente indisponível.</li>
              <li>O endereço da página pode estar errado.</li>
            </ul>
            <p>
              Tente voltar ou acesse nosso{' '}
              <Link
                className={styles.link}
                href="https://saraivaaprova.zendesk.com/hc/pt-br"
              >
                suporte
              </Link>{' '}
              para conseguir ajuda.
            </p>
          </div>
          <Link href="/" className={styles.link}>
            <Button
              color="primary"
              className={styles.button}
              onClick={() => navigate(ROUTES.HOME)}
            >
              Voltar
            </Button>
          </Link>
        </div>
        <div className={styles.img404}>
          <img
            className={styles.image}
            src={Erro404}
            alt="erro404"
            title="erro"
          />
        </div>
      </Container>
      <Footer />
    </>
  )
}
