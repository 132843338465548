import { ResumoExercicioFixacao } from '@domain/exercicio-fixacao'
import { FixationExerciseOverview } from '../remote-fields/fixation-exercise-overview'

const inbound = (
  remoteData: FixationExerciseOverview
): ResumoExercicioFixacao => {
  const { data } = remoteData
  return {
    tentativaAtual: data.current_attempt,
    emProgresso: data.in_progress,
    quantidadeQuestoes: data.questions_count,
    subjectId: data.subject_id,
    userId: data.user_id,
    totalTentativas: data.total_attempts,
    existeExercicio: false,
  }
}

export const OverviewAdapter = { inbound }
