import { FC, useState, useEffect } from 'react'
import {
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Alert } from '@equipe-ninja/saraiva-ui'
import InputMask from 'react-input-mask'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import {
  validarCertificateConfigurationSchema,
  CertificateConfiguration,
} from '@domain/certificate'
import {
  createCertificateConfiguration,
  createCertificateIssuance,
  getCertificateConfiguration,
} from '@services/certificate'
import {
  certificateConfigurationState,
  isFetchingCertificateConfiguration,
} from '@atoms/certificate/certificate-selector'
import { getCertificatePdf } from '@services/certificate/get-certificate-pdf'

import useStyles from './styles'

import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { trackingStateSelector } from '@atoms/tracking'
import { courseIdSelector } from '@atoms/course'

type DialogCertificateProps = {
  open: boolean
  onClose: () => void
}

enum GenerateCertificateStages {
  CERTIFICATE_CONFIGURATION = 'certificate_configuration',
  CONFIRM_CONFIGURATION = 'confirm_configuration',
  CERTIFICATE_ISSUANCE = 'certificate_issuance',
}

export const DialogCertificate: FC<DialogCertificateProps> = ({
  open,
  onClose,
}) => {
  const [certificateConfiguration, setCertificateConfiguration] =
    useRecoilState(certificateConfigurationState)
  const { courseId } = useRecoilValue(courseIdSelector)

  const setIsFetchingCertificate = useSetRecoilState(
    isFetchingCertificateConfiguration
  )

  const trackingState = useRecoilValue(trackingStateSelector)

  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [stage, setStage] = useState(
    GenerateCertificateStages.CERTIFICATE_CONFIGURATION
  )
  const [newName, setNewName] = useState('')
  const [newDocument, setNewDocument] = useState('')
  const [errors, setErrors] = useState<{
    name: string | boolean
    document: string | boolean
  } | null>(null)

  const [isGeneratingCertificate, setIsGeneratingCertificate] = useState(false)
  const [isCreatingConfig, setIsCreatingConfig] = useState(false)

  useEffect(() => {
    if (!!certificateConfiguration && !!open) {
      setNewName(certificateConfiguration.name)
      setNewDocument(certificateConfiguration.cpf)

      setStage(GenerateCertificateStages.CERTIFICATE_ISSUANCE)
      setIsChecked(true)
    }
  }, [certificateConfiguration, open])

  useEffect(() => {
    if (open) {
      if (!certificateConfiguration) {
        setIsFetchingCertificate(true)

        getCertificateConfiguration()
          .then((certificateConfig) => {
            if (!certificateConfig) return

            setCertificateConfiguration({
              id: certificateConfig.id,
              cpf: certificateConfig.document,
              name: certificateConfig.name,
            })
          })
          .finally(() => {
            setIsFetchingCertificate(false)
            setOpenDialog(true)
          })

        return
      }

      setOpenDialog(true)
      return
    }

    setOpenDialog(false)
  }, [open])

  const clearInfos = () => {
    setStage(GenerateCertificateStages.CERTIFICATE_CONFIGURATION)
    setIsChecked(false)
    setNewName('')
    setNewDocument('')
    setErrors(null)
  }

  const handleValidateInformation = () => {
    const certificateConfiguration = {
      name: newName.trim(),
      document: newDocument.trim(),
    }

    const validationResult = validarCertificateConfigurationSchema(
      certificateConfiguration
    )

    if (!validationResult.isValid) {
      setErrors(validationResult.errors)
      return
    }

    setStage(GenerateCertificateStages.CONFIRM_CONFIGURATION)
    Tracking.clicouValidarCertificado(trackingState.data, tracking)
  }

  const handleConfirmValidation = async () => {
    if (isCreatingConfig) return

    const certificateConfiguration: CertificateConfiguration = {
      name: newName.trim(),
      document: newDocument.trim().replaceAll('.', '').replace('-', ''),
    }

    setIsCreatingConfig(true)

    createCertificateConfiguration(certificateConfiguration)
      .then((config) => {
        setCertificateConfiguration({
          id: config.id,
          name: config.name,
          cpf: config.document,
        })
        setErrors(null)
        Tracking.clicouProsseguirCertificado(trackingState.data, tracking)
        setStage(GenerateCertificateStages.CERTIFICATE_ISSUANCE)
      })
      .finally(() => setIsCreatingConfig(false))
  }

  const handleCloseDialog = () => {
    clearInfos()
    onClose()
    Tracking.clicouFecharCertificado(trackingState.data, tracking)
  }

  const handleCancel = () => {
    clearInfos()
    onClose()
    Tracking.clicouCancelar(trackingState.data, tracking)
  }

  const handleCheck = () => {
    if (!isChecked) {
      Tracking.clicouDeAcordo(trackingState.data, tracking)
    }
    setIsChecked(!isChecked)
  }

  const generateCertificate = async () => {
    if (!certificateConfiguration || isGeneratingCertificate) return

    setIsGeneratingCertificate(true)

    try {
      await createCertificateIssuance(courseId, certificateConfiguration.id)
      Tracking.clicouArquivoCertificado(trackingState.data, tracking)

      const pdfFileBlob = await getCertificatePdf(courseId)

      const fileUrl = URL.createObjectURL(pdfFileBlob)

      window.open(fileUrl)
    } catch (err) {
      console.error(err)
    } finally {
      setIsGeneratingCertificate(false)
      setOpenDialog(false)
    }
  }

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
      <DialogTitle>Gerar certificado</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {stage === GenerateCertificateStages.CERTIFICATE_CONFIGURATION && (
          <Typography variant="body1" className={classes.textPrimary}>
            Valide as informações que estarão nos certificados de seus cursos na
            LFG.
          </Typography>
        )}
        {stage === GenerateCertificateStages.CERTIFICATE_ISSUANCE && (
          <Alert severity="info">
            Os certificados serão gerados pelas informações já validadas por
            você.
          </Alert>
        )}

        {(stage === GenerateCertificateStages.CERTIFICATE_CONFIGURATION ||
          stage === GenerateCertificateStages.CERTIFICATE_ISSUANCE) && (
          <div style={{ marginTop: '24px' }}>
            <TextField
              error={!!errors?.name}
              helperText={errors?.name}
              style={{ marginBottom: '24px' }}
              autoFocus
              label="Nome no certificado"
              type="text"
              fullWidth
              variant="outlined"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              disabled={
                !(stage === GenerateCertificateStages.CERTIFICATE_CONFIGURATION)
              }
            />

            <InputMask
              mask="999.999.999-99"
              maskPlaceholder="-"
              value={newDocument}
              onChange={(e) => setNewDocument(e.target.value)}
              disabled={
                !(stage === GenerateCertificateStages.CERTIFICATE_CONFIGURATION)
              }
            >
              {() => (
                <TextField
                  error={!!errors?.document}
                  helperText={errors?.document}
                  label="CPF no certificado"
                  type="text"
                  fullWidth
                  variant="outlined"
                  disabled={
                    !(
                      stage ===
                      GenerateCertificateStages.CERTIFICATE_CONFIGURATION
                    )
                  }
                />
              )}
            </InputMask>
          </div>
        )}
        {stage === GenerateCertificateStages.CONFIRM_CONFIGURATION && (
          <div className={classes.warning}>
            <WarningRoundedIcon className={classes.warningIcon} />
            <div className={classes.warningContent}>
              <Typography variant="body1" className={classes.textSecondary}>
                Você tem certeza que as informações dos seus certificados da LFG
                estão corretas? Elas não poderão ser modificadas futuramente.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isChecked}
                    onChange={() => handleCheck()}
                  />
                }
                label="Sim, tenho certeza!"
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {/* Validação das informações */}
        {stage === GenerateCertificateStages.CERTIFICATE_CONFIGURATION && (
          <>
            <Button
              className={classes.btnHiddenInXs}
              variant="text"
              color="primary"
              onClick={handleCloseDialog}
            >
              Fechar
            </Button>
            <Button
              className={classes.btnShowUpXs}
              variant="text"
              color="primary"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
            <Button
              className={classes.btnHiddenInXs}
              variant="text"
              color="primary"
              onClick={handleValidateInformation}
            >
              Validar informações
            </Button>
            <Button
              className={classes.btnShowUpXs}
              variant="text"
              color="primary"
              onClick={handleValidateInformation}
            >
              Validar
            </Button>
          </>
        )}
        {/* Confirmação das informações */}
        {stage === GenerateCertificateStages.CONFIRM_CONFIGURATION && (
          <>
            <Button variant="text" color="primary" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              className={classes.btnHiddenInXs}
              variant="contained"
              color="primary"
              onClick={handleConfirmValidation}
              disabled={!isChecked || isCreatingConfig}
            >
              Validar informações
            </Button>
            <Button
              className={classes.btnShowUpXs}
              variant="contained"
              color="primary"
              onClick={handleConfirmValidation}
              disabled={!isChecked || isCreatingConfig}
            >
              Validar
            </Button>
          </>
        )}
        {/* Confirmação da geração do certificado */}
        {stage === GenerateCertificateStages.CERTIFICATE_ISSUANCE && (
          <>
            <Button
              className={classes.btnHiddenInXs}
              variant="text"
              color="primary"
              onClick={handleCloseDialog}
            >
              Fechar
            </Button>
            <Button
              className={classes.btnShowUpXs}
              variant="text"
              color="primary"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>
            <Button
              className={classes.btnHiddenInXs}
              variant="text"
              color="primary"
              onClick={generateCertificate}
              disabled={isGeneratingCertificate}
            >
              Gerar certificado
            </Button>
            <Button
              className={classes.btnShowUpXs}
              variant="text"
              color="primary"
              onClick={generateCertificate}
              disabled={isGeneratingCertificate}
            >
              Gerar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
