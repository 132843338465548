import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    maxHeight: 342,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },

  cardContent: {
    width: '100%',
    flex: 1,
    marginTop: theme.typography.pxToRem(38),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  titleContainer: {
    backgroundColor: theme.palette.primary.light,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: '15px 0 30px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    // maxWidth: '90%',
  },

  assignment: {
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    bottom: '-43px',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  assignmentIcon: {
    color: theme.palette.primary.light,

    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '50%',
    width: 50,
    height: 50,
    padding: '7px',
  },

  description: {
    width: '100%',
    display: 'block',
    marginBottom: theme.typography.pxToRem(24),
    lineHeight: '1.1em',
    fontSize: 16,
    padding: '16px 16px 0px',
  },

  statusContainer: {
    padding: '0rem 1rem',
    width: '100%',
  },

  status: {
    width: '100%',
    padding: '8px 8px',
    backgroundColor: 'grey',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',

    '& span': {
      lineHeight: '1.3em',
      fontSize: 14,
    },
  },

  availableUntil: {
    backgroundColor: 'rgba(31, 136, 121, 0.08)',
    color: theme.palette.text.secondary,

    '& svg': {
      marginRight: 10,
      color: theme.palette.secondary.dark,
    },
  },

  availableFrom: {
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
    color: theme.palette.text.secondary,

    '& svg': {
      marginRight: 10,
      color: '#757575',
    },
  },

  completed: {
    background:
      'linear-gradient(0deg, rgba(64, 64, 161, 0.08), rgba(64, 64, 161, 0.08)), #FFFFFF',
    color: theme.palette.text.secondary,

    '& svg': {
      marginRight: 10,
      color: theme.palette.primary.dark,
    },
  },

  hasFeedback: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,

    '& svg': {
      marginRight: 10,
    },
  },

  linkContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,
    padding: '0rem 1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.typography.pxToRem(20),
      marginBottom: theme.typography.pxToRem(20),
    },
  },

  label: {
    fontSize: 12,
    letterSpacing: 1,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },

  divider: {
    width: '100%',
    marginTop: 24,
  },
}))

export default useStyles
