import { FC } from 'react'
import { RecoilRoot } from 'recoil'
import { SaraivaTheme } from '@equipe-ninja/saraiva-ui'
import { BrowserRouter } from 'react-router-dom'

import { AppRoutes } from './components/routes-modules/AppRoutes'
import { AuthProvider } from './contexts/auth'
import AlertMessage from '@components/AlertMessage'
import { GeneralProvider } from '@contexts/GeneralContext'

export const App: FC = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <AuthProvider>
          <SaraivaTheme theme="lfg">
            <GeneralProvider>
              <AppRoutes />
              <AlertMessage />
            </GeneralProvider>
          </SaraivaTheme>
        </AuthProvider>
      </BrowserRouter>
    </RecoilRoot>
  )
}