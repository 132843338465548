import { courseIdSelector } from '@atoms/course'
import { TipoDownload } from '@domain/geral'
import { Typography, Box, Container } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import LinkIcon from '@material-ui/icons/Link'
import { ROUTES } from '@routes/app-routes'
import { generatePath } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useStyles from './styles'

interface CardMaterialProps {
  key: number
  text: string
  contentName?: string
  colorTag: 'primary' | 'secondary'
  variant: 'classroom' | 'course'
  containerStyle?: CSSProperties
}

export function CardMaterial({
  text,
  contentName,
  colorTag,
  variant,
  containerStyle,
}: CardMaterialProps) {
  const props = {
    colorTag,
    variant,
  }
  const classes = useStyles(props)()
  const { courseId } = useRecoilValue(courseIdSelector)

  const contentRoute = () => {
    return generatePath(ROUTES.FILE_DOWNLOAD, {
      courseId: courseId.toString(),
      filename: contentName ?? '',
      type: TipoDownload.CONTEUDO,
    })
  }

  return (
    <Container className={classes.container} style={containerStyle}>
      <Link
        href={contentRoute()}
        className={classes.link}
        target="_blank"
        rel="noreferrer"
      >
        <Box className={classes.leftTag}></Box>

        <Typography variant="body1" className={classes.name}>
          {text}
        </Typography>

        <LinkIcon className={classes.linkIconPosition} />
      </Link>
    </Container>
  )
}
