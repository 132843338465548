import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  titleWidget: {
    [theme.breakpoints.down('xs')]: {
      '& p': {
        padding: '4px 16px',
      },
    },
  },
  guideTourWidget: {
    '@media (max-width: 959px)': {
      display: 'none',
    },
  },
}))

export default useStyles
