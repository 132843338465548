import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  Main: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
    backgroundColor: '#F5F5F5',
    marginTop: '64px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '32px'
    }
  },
  Pages: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 1224,
    margin: '56px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0'
    }
  }
}))

export default useStyles
