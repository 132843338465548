/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, createContext, FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { GeneralContextValues } from './general-context-type'

const GeneralContext = createContext<GeneralContextValues>({
  errorMessage: '',
  infoMessage: '',
  successMessage: '',
  warningMessage: '',
  setErrorMessage: (() => null) as GeneralContextValues['setErrorMessage'],
  setInfoMessage: (() => null) as GeneralContextValues['setInfoMessage'],
  setSuccessMessage: (() => null) as GeneralContextValues['setSuccessMessage'],
  setWarningMessage: (() => null) as GeneralContextValues['setWarningMessage'],
})

const GeneralProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [warningMessage, setWarningMessage] = useState('')

  return (
    <GeneralContext.Provider
      value={{
        successMessage,
        setSuccessMessage,

        errorMessage,
        setErrorMessage,

        infoMessage,
        setInfoMessage,

        warningMessage,
        setWarningMessage,
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}

GeneralProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { GeneralContext, GeneralProvider }
