import { ConteudoAgendado } from '../conteudo-agendado'
import { EventoConteudoAgendado } from '../evento-conteudo-agendado'
import { StatusConteudoAgendado } from '../status-conteudo-agendado'
import { computarStatusConteudoAgendado } from './computar-status-conteudo-agendado'

export const computarEventosPrazos = (
  conteudoAgendado: ConteudoAgendado
): EventoConteudoAgendado[] => {
  const status = computarStatusConteudoAgendado(conteudoAgendado)
  const dataInicio = conteudoAgendado.dataInicio.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
  const dateFimDiaMes = conteudoAgendado.dataFinal.toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
  })

  return [
    {
      passoAtual: status === StatusConteudoAgendado.INDISPONIVEL,
      texto: `Faça o exercício a partir de ${dataInicio}`,
    },
    {
      passoAtual: status === StatusConteudoAgendado.DISPONIVEL,
      texto: `Envie o exercício até: ${dateFimDiaMes} às 23:59`,
    },
    {
      passoAtual: status === StatusConteudoAgendado.CONCLUIDO,
      texto: 'Após o envio, um professor corrigirá o exercício em até 72H',
    },
  ]
}
