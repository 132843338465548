import { FC, ReactNode } from 'react'
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { ROUTES } from '@routes/app-routes'

import { ExitToApp } from '@material-ui/icons'

import { ModularHeader, MenuItem } from '@equipe-ninja/saraiva-ui'
import { GuideTourWidget } from '@components/GuideTourWidget'

import {
  NotificationWidget,
  TitleWidget,
  UserMenuWidget,
  LogoWidget,
} from '@equipe-ninja/saraiva-ui/core/ModularHeader/widgets'
import LogoLFG from '@images/logo-lfg.svg'
import useStyles from './styles'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { courseDetailState } from '@atoms/course/course-selector'
import LocalLibraryRoundedIcon from '@material-ui/icons/LocalLibraryRounded'
import { useAuth } from '../../../contexts/auth'
import { EcomRoutesMapping } from '../../../utils/ecom-routes-mapping'
import { generalState } from '@atoms/general'
import { redirecionarParaEcommerce } from '@domain/redirecionamentos'
import { trackingStateSelector } from '@atoms/tracking'
import { tracking } from '@services/tracking'
import { Tracking } from '@domain/trackings'
import { exibirGuiaOnboarding } from '@domain/guia-onboarding'

const { COURSES, CERTIFICATE, ORDERS, PROFILE } = EcomRoutesMapping

interface IHeaderProps {
  title: string
}

interface MenuItem {
  icon?: ReactNode
  onClick: () => void
  label: string
  hasDivider?: boolean | undefined
}

export const Header: FC<IHeaderProps> = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const { userInfo, sessionToken, logout } = useAuth()
  const courseState = useRecoilValue(courseDetailState)
  const trackingState = useRecoilValue(trackingStateSelector)
  const setGeneralState = useSetRecoilState(generalState)
  const { courseId } = useParams()

  const hideGuideTour = () => {
    return !exibirGuiaOnboarding(location.pathname)
  }

  const redirecionarParaMeusCursos = (
    route: EcomRoutesMapping,
    token: string | undefined
  ) => {
    Tracking.clicouMeusCursos(trackingState.data, tracking)
    redirecionarParaEcommerce(route, token)
  }

  const redirecionarParaMeusCertificados = (
    route: EcomRoutesMapping,
    token: string | undefined
  ) => {
    Tracking.clicouMeusCertificados(trackingState.data, tracking)
    redirecionarParaEcommerce(route, token)
  }

  const redirecionarParaBibliotecaVirtual = () => {
    Tracking.clicouBibliotecaVirtual(trackingState.data, tracking)
    setGeneralState((prev) => ({ ...prev, isLibraryDialogOpen: true }))
  }

  const userMenuItems: MenuItem[] | undefined = [
    {
      label: 'Meus Cursos',
      onClick: () => redirecionarParaMeusCursos(COURSES, sessionToken),
    },
    {
      label: 'Meus Pedidos',
      onClick: () => redirecionarParaEcommerce(ORDERS, sessionToken),
    },
    {
      label: 'Meus Dados',
      onClick: () => redirecionarParaEcommerce(PROFILE, sessionToken),
    },
    {
      label: 'Meus Certificados',
      onClick: () =>
        redirecionarParaMeusCertificados(CERTIFICATE, sessionToken),
    },
    {
      label: 'Biblioteca Virtual',
      onClick: () => redirecionarParaBibliotecaVirtual(),
      icon: () => <LocalLibraryRoundedIcon style={{ margin: '0 8px 0 0' }} />,
      hasDivider: true,
    },
    {
      label: 'Sair',
      onClick: () => logout(),
      icon: () => <ExitToApp style={{ margin: '0 8px 0 0' }} />,
      hasDivider: true,
    },
  ]

  return (
    <ModularHeader
      leftWidgets={[
        <LogoWidget
          logo={LogoLFG}
          onClick={() =>
            navigate(
              generatePath(ROUTES.DASHBOARD_COURSE, {
                courseId: courseId || '',
              })
            )
          }
          key={2}
        />,
        <TitleWidget
          key={3}
          text={courseState.name}
          className={classes.titleWidget}
        />,
      ]}
      rightWidgets={[
        <GuideTourWidget
          className={classes.guideTourWidget}
          key={0}
          hide={hideGuideTour()}
        />,

        <NotificationWidget
          hide={false}
          key={1}
          onWidgetClick={() => null}
          items={[]}
        />,

        <>
          {userInfo && (
            <UserMenuWidget
              key={2}
              email={userInfo.email}
              username={userInfo.fullName}
              onLogin={() => null}
              mobileOpenBehavior="popover"
              isUserLogged={true}
              onClickUserAvatar={() =>
                redirecionarParaEcommerce(PROFILE, sessionToken)
              }
              items={userMenuItems}
            />
          )}
        </>,
      ]}
    />
  )
}
