import { DefaultValue, selector } from 'recoil'
import { guideTourState } from './guide-tour-state'

export const selectedTypeSelector = selector({
  key: 'selectedTypeSelector',
  get: ({ get }) => {
    return {
      selectedType: get(guideTourState).selectedType,
    }
  },

  set: ({ get, set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const { selectedType } = newValue

      set(guideTourState, {
        ...get(guideTourState),
        selectedType,
      })
    }
  },
})
