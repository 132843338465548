import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './styles'
import { Videocam, CheckCircleRounded } from '@material-ui/icons'
import { RowClassMaterial } from '../RowClassMaterial'
import { FC, useEffect, useRef, useState } from 'react'
import { SubThemeProps } from '@domain/discipline'
import { useRecoilState, useRecoilValue } from 'recoil'
import { openNextClassExpandedState } from '@atoms/course/course-selector'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import cls from 'classnames'
import { ROUTES } from '@routes/app-routes'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { trackingStateSelector } from '@atoms/tracking'

export const SubThemeAccordion: FC<SubThemeProps> = ({
  nameSubTheme,
  idClass,
  time,
  classStudentWatched,
  dataMaterials,
  disciplineName,
  nameTheme,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [openNextClassExpanded] = useRecoilState(openNextClassExpandedState)
  const trackingState = useRecoilValue(trackingStateSelector)
  const navigateTo = useNavigate()
  const { courseId } = useParams()
  const refClass = useRef<null | HTMLDivElement>(null)
  const isDesktop = window.innerWidth >= 960

  const handleOpenClass = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    Tracking.clicouAula(
      trackingState.data,
      tracking,
      disciplineName,
      nameTheme,
      nameSubTheme
    )

    courseId &&
      navigateTo(
        generatePath(ROUTES.CLASS, {
          courseId: courseId,
          classId: idClass.toString(),
        })
      )
  }
  const materials = useRef<HTMLHeadingElement>(null)
  const [widthSubTheme, setWidthSubTheme] = useState(
    materials.current?.clientWidth || 0
  )

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidthSubTheme(materials.current?.clientWidth || 0)
    })
  }, [])

  useEffect(() => {
    if (isDesktop && openNextClassExpanded.subThemeId === idClass) {
      setExpanded(true)
      refClass.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [openNextClassExpanded])

  return (
    <div className={classes.root} ref={materials}>
      <Accordion
        className={classes.accordion}
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
      >
        <AccordionSummary
          className={cls(
            classes.accordionSummary,
            openNextClassExpanded.subThemeId === idClass &&
              classes.activeItemColor
          )}
          ref={(el) => (refClass.current = el)}
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          {classStudentWatched ? (
            <>
              <div className={classes.checkedColorEffect} />
              <div
                style={{ zIndex: 3, paddingTop: 4 }}
                onClick={handleOpenClass}
              >
                <CheckCircleRounded className={classes.checkCicle} />
              </div>
            </>
          ) : (
            <div onClick={handleOpenClass}>
              <Videocam />
            </div>
          )}

          <Typography className={classes.heading} onClick={handleOpenClass}>
            <span className={classes.headingName}>{nameSubTheme}</span>
            <span className={classes.headingTime}>{time}</span>
          </Typography>

          {widthSubTheme > 358 && (
            <span className={classes.headingMaterials}>Materias da aula</span>
          )}
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          {dataMaterials &&
            dataMaterials.map((material, index: number) => (
              <RowClassMaterial
                key={index}
                classId={idClass}
                material={material}
                disciplineName={disciplineName}
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
