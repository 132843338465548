import { computarTipoDispositivo } from '@domain/geral'
import type { TrackingData } from '@domain/trackings'
import { atom } from 'recoil'

type TrackingStateType = {
  data: TrackingData
  hasLoadedAllData: boolean
  observableId: number
}

export const trackingState = atom<TrackingStateType>({
  key: 'trackingState',
  default: {
    data: {
      user_id: 0,
      context_ip: '',
      context_locale: navigator.language,
      context_page_path: window.location.pathname,
      context_page_referrer: document.referrer,
      context_page_url: window.location.href,
      context_screen: window.innerWidth,
      context_user_agent: navigator.userAgent,
      device_category: computarTipoDispositivo(navigator.userAgent),
      id_course: 0,
      name_course: '',
      nome_categoria: '',
      nome_subcategoria: '',
      progresso_curso: '',
    },
    hasLoadedAllData: false,
    observableId: 0,
  },
})
