import useStyles from './styles'
import { Divider, Typography, Box, Link } from '@material-ui/core'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'

import { CardMaterial } from '@pages/Class/components/CardMaterial'
import { useClassContext } from '@contexts/class'
import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { trackingStateSelector } from '@atoms/tracking'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { isMediaMaterial } from '@domain/material/business'

export const ClassMaterial: FC = () => {
  const classes = useStyles()
  const { currentClass } = useClassContext()
  const trackingState = useRecoilValue(trackingStateSelector)

  const trackingClassMaterial = (text: string, fileName: string) => {
    const fileType = fileName.split('.')[1].toUpperCase()
    Tracking.clicouMaterialAula(trackingState.data, tracking, text, fileType)
  }

  return !currentClass?.dataMaterials ? null : (
    <div className={classes.container}>
      <Box className={`${classes.boxTitle}`}>
        <div className={'tour-consumo-materiais-aula-passo-1'}>
          <InsertDriveFile className={classes.insertIcon} />
          <Typography variant="h6" className={classes.name}>
            Materiais da aula
          </Typography>
        </div>
      </Box>

      <Divider />
      <Box className={classes.materials}>
        {currentClass.dataMaterials.map(
          (material) =>
            !isMediaMaterial(material) && (
              <Link
                style={{ textDecorationLine: 'none' }}
                key={material.materialId}
                onClick={() =>
                  trackingClassMaterial(
                    material.nameMaterial,
                    material.fileName
                  )
                }
              >
                <CardMaterial
                  key={material.materialId}
                  text={material.nameMaterial}
                  contentName={material.fileName}
                  variant="classroom"
                  colorTag="secondary"
                />
              </Link>
            )
        )}
      </Box>
    </div>
  )
}
