import { MaterialComplementar } from '@domain/curso'

type RemoteResponse = {
  active: boolean
  course_id: number
  description: string
  id: number
  name: string
  order: number
  summary: string
}

const inbound = (remoteData: RemoteResponse[]): MaterialComplementar[] => {
  return remoteData
    .filter((material) => material.active === true)
    .map((data) => ({
      id: data.id,
      descricao: data.description,
      nome: data.name,
      ordem: data.order,
      resumo: data.summary,
    }))
}

export const MaterialComplementarAdapter = {
  inbound,
}
