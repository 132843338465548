import { createContext, FC, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  redirectToLogin,
  setSessionToken,
  isAuthenticated,
  getSessionToken,
  parseJwt,
  removeSessionToken,
} from '../../auth'
import { useQuery } from '../../hooks/use-query'
import { EcomRoutesMapping } from '../../utils/ecom-routes-mapping'
import { handleUrlWithHttps } from '../../utils/handle-url-with-https'
import { AuthContextData } from './types'

export const AuthContext = createContext({} as AuthContextData)

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider: FC = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()

  useEffect(() => {
    if (query.sessionToken && typeof query.sessionToken === 'string') {
      setSessionToken(query.sessionToken)
      navigate(location.pathname)
    }
  }, [query])

  if (!isAuthenticated()) {
    redirectToLogin()
  }

  const sessionToken = getSessionToken() as string

  const { user } = parseJwt(sessionToken)

  const logout = () => {
    const { REACT_APP_ECOMMERCE_URL } = process.env

    removeSessionToken()

    const ecommerceUrl = handleUrlWithHttps(REACT_APP_ECOMMERCE_URL as string)

    window.location.href = `${ecommerceUrl}${EcomRoutesMapping.LOGOUT}?previousUrl=${REACT_APP_ECOMMERCE_URL}`
  }

  return (
    <AuthContext.Provider
      value={{
        sessionToken,
        userInfo: {
          id: user.id,
          email: user.email,
          fullName: `${user.first_name} ${user.last_name}`,
          integrationType: user.integration_type,
        },
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
