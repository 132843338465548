import { FC, useContext, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Container, Typography } from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'

import { useStyles } from './styles'
import { RedoExerciseDialog } from '../RedoExerciseDialog'
import { createNewAttempt } from '@services/fixation-exercise/create-new-attempt'
import { GeneralContext } from '@contexts/GeneralContext'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { Tracking } from '@domain/trackings'
import { trackingStateSelector } from '@atoms/tracking'
import { tracking } from '@services/tracking'

export const ExerciciosInicio: FC<{ title: string }> = ({ title }) => {
  const styles = useStyles()

  const { setErrorMessage } = useContext(GeneralContext)
  const [{ overview }, setState] = useRecoilState(fixationExerciseOverState)
  const trackingState = useRecoilValue(trackingStateSelector)

  const [isRedoExerciseDialogOpen, setIsRedoExerciseDialogOpen] =
    useState(false)

  function handleInitExercise() {
    if (!overview?.subjectId) return

    createNewAttempt(overview?.subjectId)
      .then((response) => {
        setState((prev) => ({
          ...prev,
          attemptId: response.idTentativa,
          isExecutingExercise: true,
          resetObservable: Date.now(),
        }))
      })
      .catch(() => setErrorMessage('Não foi possivel criar uma nova tentativa'))
      .finally(() =>
        Tracking.clicouIniciarAtividade(
          trackingState.data,
          tracking,
          title,
          'Exercício de Fixação'
        )
      )
  }

  function handleRedoExercise() {
    setIsRedoExerciseDialogOpen(true)
  }

  function handleResumeExercise() {
    setState((prev) => ({ ...prev, isExecutingExercise: true }))

    Tracking.clicouContinuarAtividade(
      trackingState.data,
      tracking,
      title,
      'Exercício de Fixação'
    )
  }

  function handleVisualizeLastAttempt() {
    setState((prev) => ({
      ...prev,
      isExecutingExercise: true,
      showAllAnswers: true,
    }))

    Tracking.clicouVerUltimaTentativa(
      trackingState.data,
      tracking,
      title,
      'Exercício de Fixação'
    )
  }

  return (
    <Container className={styles.container}>
      <Typography color="textPrimary" variant="body1">
        Reforce seus conhecimentos sobre {title}
      </Typography>

      <div className={styles.warningsGroup}>
        <div className="group">
          <Typography color="textPrimary" component="strong" variant="body1">
            Funcionamento do exercício
          </Typography>

          <Typography color="textPrimary" variant="body2">
            Você poderá realizar o exercício quantas vezes desejar para treinar
            seus conhecimentos
          </Typography>
        </div>

        <div className="group">
          <Typography color="textPrimary" component="strong" variant="body1">
            Flexibilidade para seus estudos
          </Typography>

          <Typography color="textPrimary" variant="body2">
            A resposta estará disponível para consulta após realizar cada
            questão e/ou ao concluir todo o exercício
          </Typography>
        </div>
      </div>

      <div className={styles.callToAction}>
        {overview?.emProgresso === true ? (
          <Button
            color="primary"
            variant="contained"
            onClick={handleResumeExercise}
          >
            Continuar exercício de fixação
          </Button>
        ) : (
          <>
            {overview?.totalTentativas === 0 ? (
              <Button
                color="secondary"
                variant="contained"
                className={styles.initExerciseButton}
                onClick={handleInitExercise}
              >
                Iniciar exercício de fixação
              </Button>
            ) : (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleRedoExercise}
                >
                  Refazer Exercício de fixação
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleVisualizeLastAttempt}
                >
                  Visualizar última tentativa
                </Button>
              </>
            )}
          </>
        )}
      </div>

      <Typography color="textPrimary" variant="body2">
        Tentativas realizadas: {overview?.totalTentativas}
      </Typography>

      <RedoExerciseDialog
        label="Atenção: Ao refazer o exercício, você não terá mais acesso à tentativa anterior."
        isOpen={isRedoExerciseDialogOpen}
        onClose={() => setIsRedoExerciseDialogOpen(false)}
        title={title}
      />
    </Container>
  )
}
