import { QuestaoExercicioFixacao } from '@domain/exercicio-fixacao'
import { DefaultValue, selector } from 'recoil'
import { fixationExerciseOverState } from '../fixation-exercise-stateOver'

const updaterQuestionIndex = (
  index: number,
  questions: QuestaoExercicioFixacao[]
): Record<string, never> | { questionIndex: number } => {
  if (index < 0) return {}

  if (index > questions.length - 1) return {}

  return { questionIndex: index }
}

export const questionIndexSelector = selector({
  key: 'questionIndexSelector',
  get: ({ get }) => get(fixationExerciseOverState).questionIndex,

  set: ({ get, set }, data) => {
    if (!(data instanceof DefaultValue)) {
      set(fixationExerciseOverState, {
        ...get(fixationExerciseOverState),
        ...updaterQuestionIndex(data, get(fixationExerciseOverState).questions),
      })
    }
  },
})
