import { DefaultValue, selector } from 'recoil'
import { courseState } from '../course-state'

export const courseDataSelector = selector({
  key: 'courseDataSelector',
  get: ({ get }) => {
    return {
      course: get(courseState).course,
      progress: get(courseState).progress,
      materiaisComplementar: get(courseState).materiaisComplementar,
      simulados: get(courseState).simulados,
      discipline: get(courseState).discipline,
      isFetching: get(courseState).isFetching,
      openNextClassExpanded: get(courseState).openNextClassExpanded,
      classProgressRequestId: get(courseState).classProgressRequestId,
      apostila: get(courseState).apostila,
    }
  },

  set: ({ get, set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const {
        isFetching,
        course,
        materiaisComplementar,
        simulados,
        progress,
        discipline,
        apostila,
        openNextClassExpanded,
        classProgressRequestId,
      } = newValue

      set(courseState, {
        ...get(courseState),
        course,
        materiaisComplementar,
        simulados,
        progress,
        discipline,
        apostila,
        openNextClassExpanded,
        isFetching,
        classProgressRequestId,
      })
    }
  },
})
