import { createStyles, makeStyles } from '@material-ui/core/styles'
import tinycolor from 'tinycolor2'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    accordion: {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
    },
    accordionSummary: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.common.white,
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      minHeight: '52px',
      boxSizing: 'border-box',
      padding: '0px 24px',
      '& > .MuiIconButton-root': {
        padding: '16px',
      },
      '& > .MuiAccordionSummary-content': {
        margin: '0px',
        alignItems: 'center',
      },
      '& > .MuiAccordionSummary-content.Mui-expanded ': {
        margin: '12px 0',
      },
      '&.Mui-expanded': {
        margin: '0px',
        minHeight: '52px',
      },
      // mudando cor do headingMaterials quando acordion aberto
      '& > .MuiAccordionSummary-content.Mui-expanded > span': {
        color: '#0A5A51',
      },
      '& > .MuiAccordionSummary-expandIcon.Mui-expanded': {
        color: '#0A5A51',
      },
      '@media (max-width: 599px)': {
        padding: '0px 24px 0px 16px',
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
    },
    heading: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
      marginLeft: '20px',
    },
    headingName: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '143%',
      color: '#000000',
    },
    headingTime: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '166%',
      color: theme.palette.text.secondary,
    },
    headingMaterials: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      width: '110px',
      marginRight: '2px',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '14px',
      color: '#0000008A',
      '@media (max-width: 600px)': {
        display: 'none',
      },
    },
    checkCicle: {
      color: theme.palette.secondary.light,
      zIndex: 4,
    },
    activeItemColor: {
      backgroundColor: tinycolor.mix(theme.palette.secondary.light, 'FFF', 90),
    },
    checkedColorEffect: {
      position: 'absolute',
      height: 18,
      width: 18,
      backgroundColor: theme.palette.primary.dark,
      zIndex: 2,
      left: 27,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        left: 19,
      },
    },
  })
)
