import { Disciplines } from '@domain/discipline'

export interface formDataDiscipline {
  data: Array<{
    disciplina_id: number
    disciplina_title: string
    disciplina_description: string
    disciplina_count_class: number
    disciplina_count_class_watched: number
    disciplina_themes: Array<{
      theme_id: number
      theme_title: string
      classes: Array<{
        class_id: number
        class_title: string
        class_stream: string
        class_student_watched: boolean
        contents: Array<{
          content_title: string
          content_filename: string | null
          content_id: number
          content_stream: string | null
        }>
      }>
    }>
  }>
}

export const adapterDisciplines = (
  formData: formDataDiscipline
): Disciplines[] => {
  return formData.data.map((discipline) => {
    return {
      disciplineId: discipline['disciplina_id'],
      disciplineName: discipline['disciplina_title'],
      numberOfClassesTotal: discipline['disciplina_count_class'],
      numberOfClassesCompleted: discipline['disciplina_count_class_watched'],
      dataTheme: discipline['disciplina_themes'].map((theme) => ({
        nameTheme: theme['theme_title'],
        themeId: theme['theme_id'],
        dataSubTheme: theme['classes'].map((classe) => ({
          idClass: classe['class_id'],
          nameSubTheme: classe['class_title'],
          time: classe['class_stream'],
          classStudentWatched: classe['class_student_watched'],
          dataMaterials: classe['contents'].map((content) => ({
            nameMaterial: content['content_title'],
            fileName: content['content_filename'],
            materialId: content['content_id'],
            contentStream: content['content_stream'],
          })),
        })),
      })),
    }
  }) as Disciplines[]
}
