import httpClient from '@gateway/generalHttpRequest'

type CreateCertificateIssuanceResponseData = {
  certificate_configuration_id: number
  course_id: number
  id: number
  inserted_at: string
  updated_at: string
}

export const createCertificateIssuance = async (
  courseId: number,
  certificateConfigurationId: number
) => {
  const path = '/offers/api/v1/certificate_issuance'
  const payload = {
    course_id: courseId,
    certificate_configuration_id: certificateConfigurationId
  }
  const response = await httpClient.post<CreateCertificateIssuanceResponseData>(
    path,
    payload
  )
  const responseJson = await response.json()
  return responseJson
}
