import { TrackingData, trackingDataSlice, TrackingTypes } from './tracking-data'
import { TrackingTask } from './tracking-task'

export const clicouMaterialComplementar = (
  state: TrackingData,
  service: TrackingTask,
  nomeMaterial: string,
  tipoMaterial: string
) => {
  const payload = {
    event: TrackingTypes.CLICOU_MATERIAL_COMPLEMENTAR,
    event_text: TrackingTypes.CLICOU_MATERIAL_COMPLEMENTAR,
    user_id: state.user_id,
    nome_material_curso: nomeMaterial,
    formato_material: tipoMaterial,
    ...trackingDataSlice(state),
  }

  service(payload)
    .then(() => null)
    .catch(() => null)
}
