import { courseState } from '@atoms/course/course-state'
import { DefaultValue, selector } from 'recoil'
import { trackingState } from '../tracking-state'

export const trackingStateSelector = selector({
  key: 'trackingStateSelector',
  get: ({ get }) => {
    return {
      data: {
        ...get(trackingState).data,
        name_course: get(courseState).course.name,
        id_course: get(courseState).course.id,
        nome_categoria: get(courseState).course.categoria?.nome ?? '',
        nome_subcategoria: get(courseState).course.subcategoria?.nome ?? '',
        progresso_curso: `${get(courseState).progress.percentualConcluido}%`,
      },
      hasLoadedAllData: get(trackingState).hasLoadedAllData,
      observableId: get(trackingState).observableId,
    }
  },

  set: ({ get, set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(trackingState, {
        ...get(trackingState),
        data: { ...newValue.data },
        hasLoadedAllData: newValue.hasLoadedAllData,
        observableId: newValue.observableId,
      })
    }
  },
})
