export interface DataContentProps {
  id: number
  name: string
  path: string
  furl: string
  type: string
  order: number
  active: boolean
}

export const adapterContent = (data: DataContentProps[]) => {
  return data.filter((content: DataContentProps) => content.active === true)
}
