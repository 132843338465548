import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
      height: '64px',
      padding: '10px 24px',
    },
  },

  topDialog: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  buttonOn: {
    color: '#4040A1',
  },
  buttonOff: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  stepperOn: {
    backgroundColor: '#4040A1',
  },
  stepperOff: {
    backgroundColor: '#D8D8D8',
  },
  stepperAling: {
    display: 'flex',
    paddingLeft: 24,
  },
  buttonAling: {
    display: 'flex',
    overflow: 'hidden',
  },
  bottomDialog: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  contentDialog: {
    padding: '24px !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
}))
