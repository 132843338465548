import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentTheme: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      padding: '8px 29px 8px 68px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      '@media (max-width: 600px)': {
        paddingLeft: '58px',
      },
    },
    divLinkMaterial: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 'auto',
      cursor: 'pointer',
    },
    nameContentTheme: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '166%',
      letterSpacing: '0.4px',
      color: theme.palette.text.secondary,
      marginLeft: '14px',
    },
    link: {
      cursor: 'pointer',
      width: 16,
      height: 16,
    },
    hidden: {
      display: 'none',
    },
    fileIcon: {
      fontSize: 16,
      color: theme.palette.secondary.dark,
    },
  })
)
