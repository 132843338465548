import { getSessionToken, isUnauthorized, redirectToLogin } from '../auth'

interface DefaultRequestHeader {
  'Content-Type': 'application/json'
  accept: 'application/json'
  'application-token'?: string
}

function RequestHeaderBuilder() {
  const DEFAULT_REQUEST_HEADER: DefaultRequestHeader = {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'application-token': process.env.REACT_APP_TOKEN,
  }

  function decorate(customRequestHeader: HeadersInit) {
    return { ...DEFAULT_REQUEST_HEADER, ...customRequestHeader }
  }

  return {
    buildHeader(headers: HeadersInit) {
      return decorate(headers)
    },
  }
}

const headerBuilder = RequestHeaderBuilder()

interface RequestResult<T> extends Response {
  json(): Promise<T>
}

function RequestBuilder() {
  const { REACT_APP_FUSION_ORIGIN } = process.env

  function normalizePath(path: string) {
    if (path.startsWith('/')) {
      return path.slice(1)
    }

    return path
  }

  function buildUrl(path: string) {
    return `${REACT_APP_FUSION_ORIGIN}/${normalizePath(path)}`
  }

  function buildBody(payload?: unknown) {
    if (!payload) {
      return {}
    }

    return {
      body: JSON.stringify(payload),
    }
  }

  async function verifyResponse(response: Response) {
    if (isUnauthorized(response.status)) {
      redirectToLogin()
    }
  }

  async function build<T, S>(
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    payload?: S,
    headers?: HeadersInit
  ): Promise<RequestResult<T>> {
    let options = {
      method,
      headers: headerBuilder.buildHeader({
        ...headers,
        'session-token': getSessionToken() as string,
      }),
      mode: 'cors',
    } as const

    if (method !== 'GET') {
      options = { ...options, ...buildBody(payload) }
    }

    const result = await fetch(buildUrl(path), options)
    verifyResponse(result)

    return result
  }

  return {
    get<T = unknown, S = unknown>(
      path: string,
      payload?: S,
      headers?: HeadersInit
    ) {
      return build<T, S>(path, 'GET', payload, headers)
    },

    post<T = unknown, S = unknown>(
      path: string,
      payload?: S,
      headers?: HeadersInit
    ) {
      return build<T, S>(path, 'POST', payload, headers)
    },

    patch<T = unknown, S = unknown>(
      path: string,
      payload?: S,
      headers?: HeadersInit
    ) {
      return build<T, S>(path, 'PATCH', payload, headers)
    },

    put<T = unknown, S = unknown>(
      path: string,
      payload?: S,
      headers?: HeadersInit
    ) {
      return build<T, S>(path, 'PUT', payload, headers)
    },

    delete<T = unknown, S = unknown>(
      path: string,
      payload?: S,
      headers?: HeadersInit
    ) {
      return build<T, S>(path, 'DELETE', payload, headers)
    },
  }
}

const requestBuilder = RequestBuilder()

export default { ...headerBuilder, ...requestBuilder }
export type { DefaultRequestHeader, RequestResult }
