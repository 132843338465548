import { selector } from 'recoil'
import { courseState } from '../course-state'

export const currentClassSelector = selector({
  key: 'currentClassSelector',
  get: ({ get }) => {
    return {
      courseId: get(courseState).course.id,
      isFetching: get(courseState).isFetching,
      ...get(courseState).course.classCurrent,
    }
  },
})
