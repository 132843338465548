export class RemoteDateUtils {
  static parseDateString(dateString: string): Date {
    if (!dateString)
      throw new Error(
        `${dateString} is an invalid value to be converted into Date`
      )

    return new Date(`${dateString}T00:00:00`)
  }
}
