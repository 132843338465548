import { FC } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { ArrowForwardSharp } from '@material-ui/icons'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '@routes/app-routes'
import { useRecoilValue } from 'recoil'
import { courseDataSelector, materialComplementarSelector } from '@atoms/course'
import useStyles from './styles'

export const CourseMaterialsRedirector: FC = () => {
  const classes = useStyles()
  const { courseId } = useRecoilValue(materialComplementarSelector)
  const { simulados } = useRecoilValue(courseDataSelector)

  const linkUrl = (materialId: number) => {
    const targetPath = generatePath(ROUTES.MATERIALS_ID, {
      courseId: courseId.toString(),
      materialId: materialId.toString(),
    })

    return targetPath
  }

  return (
    <Grid
      container
      style={{ flexGrow: 1, flexDirection: 'column' }}
      spacing={2}
      alignItems="stretch"
    >
      {simulados.map((item) => (
        <Grid
          item
          style={{ maxWidth: '100%' }}
          md={3}
          sm={6}
          xs={12}
          key={item.id}
        >
          <Link style={{ textDecoration: 'none' }} to={linkUrl(item.id)}>
            <Paper className={classes.card}>
              <div className={classes.purpleBorder}></div>
              <div>
                <Typography variant="overline" style={{ lineHeight: '1em' }}>
                  {item.nome}
                </Typography>
                <Typography variant="subtitle2" className={classes.text}>
                  {item.resumo}
                </Typography>
              </div>
              <div className={classes.linkContainer}>
                <span className={classes.label}>Acessar</span>
                <ArrowForwardSharp
                  style={{ fontSize: 23, color: 'rgba(0, 0, 0, 0.54)' }}
                />
              </div>
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
