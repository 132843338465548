import { Disciplines } from '@domain/discipline'
import { DefaultValue, selector } from 'recoil'
import { courseState, openNextClassExpanded } from './course-state'

export const courseDetailState = selector({
  key: 'courseDetailState',
  get: ({ get }) => {
    return {
      ...get(courseState).course,
      isFetching: get(courseState).isFetching,
    }
  },

  set: ({ get, set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const { isFetching, ...course } = newValue

      set(courseState, {
        ...get(courseState),
        ...course,
        isFetching,
      })
    }
  },
})

export const disciplineDetailState = selector({
  key: 'disciplineDetailState',
  get: ({ get }) => {
    return get(courseState).discipline
  },

  set: ({ get, set }, data) => {
    set(courseState, {
      ...get(courseState),
      discipline: [...(data as Disciplines[])],
    })
  },
})

export const openNextClassExpandedState = selector({
  key: 'openNextClassExpandedState',
  get: ({ get }) => {
    return get(courseState).openNextClassExpanded
  },

  set: ({ get, set }, data) => {
    if (!(data instanceof DefaultValue)) {
      set(courseState, {
        ...get(courseState),
        openNextClassExpanded: data as openNextClassExpanded,
      })
    }
  },
})

export const progressCourseState = selector({
  key: 'progressCourseState',
  get: ({ get }) => {
    return get(courseState).progress
  },

  set: ({ get, set }, data) => {
    if (!(data instanceof DefaultValue)) {
      set(courseState, {
        ...get(courseState),
        progress: { ...data },
      })
    }
  },
})
