import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginBottom: theme.typography.pxToRem(8),
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },

  completedPercentage: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.typography.pxToRem(8)
    }
  },

  title: {
    marginBottom: 16,
    color: theme.palette.text.primary
  },

  flagIcon: {
    marginRight: theme.typography.pxToRem(8),
    fill: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.typography.pxToRem(8)
    }
  },

  progressbar: {
    '& > div.MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.light
    },

    '& > div.MuiLinearProgress-barColorSecondary': {
      backgroundColor: theme.palette.success.light
    },

    [theme.breakpoints.down('xs')]: {
      height: `${theme.typography.pxToRem(10)} !important`
    }
  },

  generateCertificateBtn: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.secondary.light,

    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },

    marginTop: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(8)
  },

  separator: {
    width: 1,
    height: '20px',
    backgroundColor: theme.palette.divider,
    margin: '0px 16px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}))

export default useStyles
