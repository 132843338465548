import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './styles'
import { ThemeAccordion } from '../ThemeAccordion'
import { FC, useEffect, useState } from 'react'
import { Disciplines, ThemeProps } from '@domain/discipline'
import { useRecoilState, useRecoilValue } from 'recoil'
import { openNextClassExpandedState } from '@atoms/course/course-selector'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { trackingStateSelector } from '@atoms/tracking'

type SimpleAccordionProps = Disciplines

export const SimpleAccordion: FC<SimpleAccordionProps> = ({
  disciplineName,
  disciplineId,
  numberOfClassesTotal,
  numberOfClassesCompleted,
  dataTheme,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [openNextClassExpanded] = useRecoilState(openNextClassExpandedState)
  const trackingState = useRecoilValue(trackingStateSelector)

  useEffect(() => {
    if (openNextClassExpanded.disciplineId === disciplineId) {
      setExpanded(true)
    }
  }, [openNextClassExpanded])

  const handleExpandedDisciplineName = () => {
    Tracking.clicouDisciplina(trackingState.data, tracking, disciplineName)
    setExpanded(!expanded)
  }

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} expanded={expanded}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="accordion-discipline"
          onClick={handleExpandedDisciplineName}
        >
          <Typography className={classes.heading}>
            <span
              className={`${classes.headingName} ${
                expanded && classes.headingNameExpanded
              }`}
            >
              {disciplineName}
            </span>
            <span className={classes.headingInfo}>
              Aulas: {`${numberOfClassesCompleted} de ${numberOfClassesTotal}`}
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {dataTheme &&
            dataTheme.map((theme: ThemeProps, index: number) => (
              <ThemeAccordion
                key={index}
                themeId={theme.themeId}
                nameTheme={theme.nameTheme}
                dataSubTheme={theme.dataSubTheme}
                disciplineName={disciplineName}
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
