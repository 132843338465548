import { Apostilas, Disciplines } from '@domain/discipline'

const APOSTILA = 'Apostila'

type DisciplinasFiltradas = {
  discipline: Disciplines[]
  apostila: Record<string, Apostilas>
}

export const filtraApostila = (
  filterData: Disciplines[]
): DisciplinasFiltradas => {
  const apostilasEDisciplinas = filterData.reduce<DisciplinasFiltradas>(
    (acc: DisciplinasFiltradas, cur: Disciplines) => {
      const apostilas = cur.dataTheme.filter(
        (discipline) => discipline.nameTheme === APOSTILA
      )

      const apostilaOrganizada = apostilas
        .map((theme) => {
          const listaArquivos: Apostilas = theme?.dataSubTheme
            .map(
              (aula) =>
                aula.dataMaterials?.map((file) => ({
                  nomeArquivo: file?.fileName,
                  id: file?.materialId,
                  nomeExibição: aula.nameSubTheme,
                })) ?? []
            )
            .flat()

          return listaArquivos
        })
        .flat()

      return {
        discipline: [
          ...acc.discipline,
          {
            ...cur,
            dataTheme: cur.dataTheme.filter(
              (theme) => theme.nameTheme !== APOSTILA
            ),
          },
        ],
        apostila: { ...acc.apostila, [cur.disciplineId]: apostilaOrganizada },
      }
    },
    { discipline: [], apostila: {} }
  )

  return apostilasEDisciplinas
}
