import { TrackingData, trackingDataSlice, TrackingTypes } from './tracking-data'
import { TrackingTask } from './tracking-task'

export const clicouCancelar = (state: TrackingData, service: TrackingTask) => {
  const payload = {
    event: TrackingTypes.CLICOU_CANCELAR,
    event_text: TrackingTypes.CLICOU_CANCELAR,
    user_id: state.user_id,
    ...trackingDataSlice(state),
  }

  service(payload)
    .then(() => null)
    .catch(() => null)
}
