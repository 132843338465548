import { selector } from 'recoil'
import { CertificateConfiguration, certificateState } from './certificate-state'

export const certificateConfigurationState = selector({
  key: 'certificateConfigurationState',
  get: ({ get }) => {
    return get(certificateState).certificateConfiguration
  },
  set: ({ get, set }, data) => {
    set(certificateState, {
      ...get(certificateState),
      certificateConfiguration: { ...(data as CertificateConfiguration) }
    })
  }
})

export const isFetchingCertificateConfiguration = selector({
  key: 'isFetchingCertificateConfiguration',
  get: ({ get }) => {
    return get(certificateState).isFetching
  },
  set: ({ get, set }, data) => {
    set(certificateState, {
      ...get(certificateState),
      isFetching: data as boolean
    })
  }
})
