import { ROUTES } from '@routes/app-routes'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rotasPermitidas = Object.values(ROUTES)
  .filter((v) => {
    return [
      ROUTES.CLASS,
      ROUTES.DISCIPLINE,
      ROUTES.DASHBOARD_COURSE,
      ROUTES.HOME,
    ].includes(v)
  })
  .map((v) => {
    return new RegExp(`^${v.replace(/(:[^/]*)/g, '\\d+')}$`)
  })

export const exibirGuiaOnboarding = (pathname: string): boolean => {
  return rotasPermitidas.some((e) => !!pathname.match(e))
}
