import { useState, useRef, FC, useContext } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import NewReleasesRoundedIcon from '@material-ui/icons/NewReleasesRounded'
import { Hidden, HiddenProps } from '@material-ui/core'
import cls from 'classnames'
import useStyles from './styles'
import { ShepherdTourContext } from 'react-shepherd'

type GuideTourWidgetProps = {
  /** className usado para customizar o componente */
  className?: string
  /** Flag de curto-circuito para esconder o widget, default `false` */
  hide?: boolean
  /**
   * Propriedades para controlar quando esconder um objeto usa a mesma api do Componente do MUI Hidden
   * {@link https://v4.mui.com/components/hidden/#hidden [Detalhes]}
   */
  hidden?: Omit<HiddenProps, 'implementation' | 'initialWidth'>
}

export const GuideTourWidget: FC<GuideTourWidgetProps> = (props) => {
  const { className, hidden, hide } = props

  const [state, setState] = useState({
    ref: null,
    isOpen: false,
  })
  const avatarRef = useRef(null)
  const classes = useStyles()
  const tour = useContext(ShepherdTourContext)

  const handleMenu = () => {
    setState((prev) => ({ ...prev, isOpen: true, ref: avatarRef.current }))
  }

  const handleClose = () => {
    setState((prev) => ({ ...prev, isOpen: false, ref: null }))
  }

  const handleStartGuideTour = () => {
    tour?.start()
    handleClose()
  }

  if (hide) return null

  return (
    <Hidden {...hidden}>
      <IconButton
        className={cls([className])}
        onClick={handleMenu}
        edge="end"
        aria-label="Iniciar tutorial de usabilidade"
      >
        <InfoOutlinedIcon className={classes.widgetIcon} ref={avatarRef} />
      </IconButton>

      <Hidden>
        <Menu
          anchorEl={state.ref}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={state.isOpen}
          onClose={handleClose}
        >
          <MenuItem
            className={cls(classes.menuItem)}
            onClick={() => handleStartGuideTour()}
          >
            <NewReleasesRoundedIcon
              className={cls(classes.newReleasesRoundedIcon)}
            />
            <Typography className={cls(classes.primaryText, classes.ellipsis)}>
              Entenda os materiais de estudo
              <br />
              <Typography
                className={cls(classes.secondaryText, classes.ellipsis)}
              >
                Entenda quais materiais estão disponíveis para <br />
                aprimorar seus estudos nessa página.
              </Typography>
            </Typography>
          </MenuItem>
        </Menu>
      </Hidden>
    </Hidden>
  )
}
