import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  summary: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  title: {
    marginBottom: '4px',
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',

    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

export default useStyles
