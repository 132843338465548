import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  stepsList: {
    margin: '24px 0 0',
    padding: '0',
  },
  marginTop24: {
    marginTop: 24,
  },
  captions: {
    marginTop: 40,
    [theme.breakpoints.down(900)]: {
      marginTop: 24,
    },
  },
  underlinedText: {
    textDecoration: 'underline',
    color: theme.palette.text.secondary,
  },
  imageEffect: {
    filter: 'drop-shadow(0px 4px 14px rgba(0,0,0,0.25))',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
    },
  },
}))

export default useStyles
