import CircularProgress from '@material-ui/core/CircularProgress'
import { FC } from 'react'
import useStyles from './styles'

export const CircularLoader: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}
