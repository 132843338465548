import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'contents',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        width: '100%',
        minWidth: 360,
      },
    },
    course: {
      width: '100%',
      maxWidth: 358,
      marginTop: 64,
      background: theme.palette.grey[100],
      [theme.breakpoints.down('sm')]: {
        order: 2,
        marginTop: 8,
        maxWidth: 960,
      },
    },
    class: {
      width: '100%',
      marginTop: 64,
      height: 'calc(100vh - 64px)',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        order: 1,
        minWidth: '100%',
        marginTop: 0,
        height: '100%',
      },
      '&& > div:nth-child(3)': {
        paddingTop: 32,
      },
    },
    questionMobile: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        order: 3,
        margin: '24px 24px 56px',
        display: 'block',
        border: '1px solid rgb(235,235,235)',
        borderRadius: '8px',
        minWidth: '300px',
        padding: '12px 0px 12px 16px',
      },
    },
    questionDesktop: {
      margin: '24px 56px 0',
      border: '1px solid rgb(235,235,235)',
      borderRadius: '8px',
      minWidth: '300px',
      padding: '12px 0px 12px 16px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
)
