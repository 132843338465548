import { ConteudoAgendado } from '@domain/conteudos-agendados'
import { selector } from 'recoil'
import { getCourseScheduledContent } from '@services/scheduled-content'
import { courseState } from '@atoms/course/course-state'

export const conteudosAgendadosSelector = selector<ConteudoAgendado[]>({
  key: 'conteudosAgendadosSelector',
  get: async ({ get }) => {
    const response = await getCourseScheduledContent(get(courseState).course.id)

    return response
  },
})
