import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  textQuestion: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    display: 'inline-block',
    marginLeft: 9,
    verticalAlign: 'center'
  },
  textDescription: {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  textEmail: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  question: {
    color: theme.palette.primary.main,
    display: 'inline-block'
  },
  boxTitle: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export default useStyles
