import { FC, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ProgressBar, CardInfo } from '@equipe-ninja/saraiva-ui'
import { Grid, Button, Typography } from '@material-ui/core'
import { FlagOutlined } from '@material-ui/icons'
import useStyles from './styles'
import { DialogCertificate } from '@components/DialogCertificate'
import { isFetchingCertificateConfiguration } from '@atoms/certificate/certificate-selector'
import { progressCourseState } from '@atoms/course/course-selector'
import { asPercentage } from '@utils/data-pipes'
import { trackingStateSelector } from '@atoms/tracking'
import { tracking } from '@services/tracking'
import { Tracking } from '@domain/trackings'

export const CourseProgress: FC = () => {
  const classes = useStyles()
  const [openDialogCertificate, setOpenDialogCertificate] = useState(false)
  const { percentualConcluido, totalAulas, totalAulasConcluidas } =
    useRecoilValue(progressCourseState)
  const trackingState = useRecoilValue(trackingStateSelector)

  const isFetchingCertificate = useRecoilValue(
    isFetchingCertificateConfiguration
  )

  const isGenerateCertificateVisible = percentualConcluido >= 70.0

  const openDialogCertificateHandle = () => {
    setOpenDialogCertificate(true)
    Tracking.clicouGerarCertificado(trackingState.data, tracking)
  }

  return (
    <>
      <CardInfo className={{ backgroundColor: '#fff' }}>
        <Typography variant="h6" className={classes.title}>
          Acompanhe seu progresso
        </Typography>

        <Grid container className={classes.contentContainer}>
          <FlagOutlined className={classes.flagIcon} />

          <Typography variant="caption">
            Conclua 70% das aulas para conseguir seu certificado
          </Typography>

          <div className={classes.separator}></div>

          <Typography variant="caption">
            Aulas completadas: {totalAulasConcluidas} de {totalAulas}
          </Typography>

          <Typography variant="caption" className={classes.completedPercentage}>
            {asPercentage(percentualConcluido, { fractionDigits: 0 })}
          </Typography>
        </Grid>

        <ProgressBar
          value={percentualConcluido}
          size="full"
          color={isGenerateCertificateVisible ? 'secondary' : 'primary'}
          className={classes.progressbar}
        />

        {isGenerateCertificateVisible && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.generateCertificateBtn}
            onClick={() => openDialogCertificateHandle()}
            disabled={isFetchingCertificate}
          >
            Gerar meu certificado!
          </Button>
        )}
      </CardInfo>

      <DialogCertificate
        open={openDialogCertificate}
        onClose={() => setOpenDialogCertificate(false)}
      />
    </>
  )
}
