import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    height: '128px',
    position: 'absolute',
    display: 'flex',
    padding: '32px',
    boxSizing: 'border-box',
    backgroundColor: '#FAFAFA',
    [theme.breakpoints.down('md')]: {
      padding: '32px 20px',
    },
  },
  logo: {
    padding: 0,
    width: 108,
    height: 64,
    float: 'left',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 78,
    },
  },
  divider: {
    display: 'block',
    margin: '0px 56px 0px 56px',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      margin: '0px 12px',
      float: 'left',
    },
  },
  texts: {
    display: 'block',
    textAlign: 'left',
    float: 'left',
    alignContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      float: 'left',
      textAlign: 'left',
      fontSize: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px',
    },
  },
  polity: {
    flexGrow: 1,
    color: '#4A4A4A',
    fontSize: '16px',
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  copyright: {
    color: '#4A4A4A',
    marginTop: 22,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
    },
  },
}))

export default useStyles
