import { EcomRoutesMapping } from '@utils/ecom-routes-mapping'
import { handleUrlWithHttps } from '@utils/handle-url-with-https'

const redirectToEnvEcommerce = (
  route: string,
  sessionToken: string | undefined
): string => {
  const { REACT_APP_ECOMMERCE_URL } = process.env

  const ecommerceUrl = handleUrlWithHttps(REACT_APP_ECOMMERCE_URL as string)

  return `${ecommerceUrl}${route}?sessionToken=${sessionToken}`
}

export const redirecionarParaEcommerce = (
  route: EcomRoutesMapping,
  token: string | undefined
) => {
  window.location.href = redirectToEnvEcommerce(route, token)
}
