import { TrackingTask } from '@domain/trackings'
import httpClient from '../../gateway/generalHttpRequest'

export const tracking: TrackingTask = (payload) => {
  return new Promise((resolve, reject) => {
    const { event, user_id, ...payloadData } = payload
    const data = {
      event,
      user_id,
      payload: payloadData,
    }
    const path = '/trackings'
    httpClient
      .post<void>(path, data)
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}
