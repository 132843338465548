/* eslint-disable react/no-unescaped-entities */
import { useContext } from 'react'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'
import copy from 'copy-to-clipboard'
import { GeneralContext } from '@contexts/GeneralContext'
import { useRecoilValue } from 'recoil'
import { courseDataSelector } from '@atoms/course'
import { eCursoSegundaFase } from '@domain/curso/business'

interface QuestionProps {
  className?: string
}
export function Question({ className }: QuestionProps) {
  const classes = useStyles()

  const { setSuccessMessage } = useContext(GeneralContext)
  const { course } = useRecoilValue(courseDataSelector)
  const showAlert = eCursoSegundaFase(course)

  const copyEmail = () => {
    copy(document.getElementById('spanEmail')?.textContent || '')
    setSuccessMessage('Endereço de e-mail copiado com sucesso')
  }

  if (!showAlert) return null

  return (
    <Box className={className}>
      <Box className={classes.boxTitle}>
        <QuestionAnswerIcon className={classes.question} />
        <Typography className={classes.textQuestion}>
          DÚVIDAS SOBRE O CONTEÚDO
        </Typography>
      </Box>

      <Typography className={classes.textDescription}>
        Mande um e-mail com suas dúvidas da disciplina para
        <span
          id="spanEmail"
          className={classes.textEmail}
          onClick={() => copyEmail()}
        >
          {' '}
          plantaodeduvidas@saraivaeducacao.com.br
        </span>
        , com o título "Nome da disciplina | Dúvida”.
      </Typography>
    </Box>
  )
}
