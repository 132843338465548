import { ConteudoAgendado } from '../conteudo-agendado'
import { StatusConteudoAgendado } from '../status-conteudo-agendado'
import { computarStatusConteudoAgendado } from './computar-status-conteudo-agendado'

export const computarTextoStatusConteudoAgendado = (
  conteudoAgendado: ConteudoAgendado
): string => {
  const status = computarStatusConteudoAgendado(conteudoAgendado)

  switch (status) {
    case StatusConteudoAgendado.DISPONIVEL:
      return 'disponível'
    case StatusConteudoAgendado.INDISPONIVEL:
      return `disponível a partir de ${conteudoAgendado.dataInicio.toLocaleDateString(
        'pt-BR'
      )}`
    default:
      return 'finalizado'
  }
}
