import { DadosTentativa } from '@domain/exercicio-fixacao'
import { RemoteAttempt } from '../remote-fields/current-attempt'

const inbound = (remoteData: RemoteAttempt): DadosTentativa => {
  return {
    emProgresso: remoteData.in_progress,
    numeroTentativa: remoteData.attempt,
    quantindadeQuestoes: remoteData.questions_count,
    tentativaId: remoteData.id,
    questoes: remoteData.questions.map((question) => ({
      alternativaCorretaId: question.correct_exercise_alternative_id,
      questaoId: question.id,
      disciplinaId: Number(question.subject_id),
      respostaQuestao: question.user_answer,
      conteudo: question.content,
      alternativas: question.alternatives.map((alternative) => ({
        ordem: alternative.order,
        conteudo: alternative.content,
        alternativaId: alternative.id,
      })),
    })),
  }
}

export const CurrentAttemptAdapter = { inbound }
