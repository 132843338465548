import { FC } from 'react'
import { CircularLoader } from '@components/CircularLoader'

type ContentWithLoaderProps = {
  /** indica se irar exibir o loader ou o conteudo */
  isLoading: boolean
}

/**
 * Componente que irá exibir um loader de acordo com a prop fornecida, ou exibirá
 * as children em caso negativo
 * */
export const ContentWithLoader: FC<ContentWithLoaderProps> = (props) => {
  const { isLoading, children } = props

  return <>{isLoading ? <CircularLoader /> : <>{children}</>}</>
}
