import { FC, useEffect } from 'react'
import classNames from 'classnames'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Button } from '@equipe-ninja/saraiva-ui'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import {
  Cancel,
  CheckCircle,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'

import { useStyles } from './styles'
import { alternativaCorretaQuestao } from '@domain/exercicio-fixacao/business'
import { selectedQuestionSelector } from '@atoms/fixation-exercise'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { saveLog } from '@services/fixation-exercise'

export const Questao: FC = () => {
  const styles = useStyles()

  const selectedQuestions = useRecoilValue(selectedQuestionSelector)
  const [p, setState] = useRecoilState(fixationExerciseOverState)

  const {
    disciplineId,
    questionIndex,
    isExecutingLog,
    attempt,
    showCurrentAnswer,
    showAllAnswers,
  } = p

  console.log(selectedQuestions)

  const savingLogs = (alternative: number) => {
    setState((prev) => ({ ...prev, isExecutingLog: true }))
    saveLog({
      disciplineId,
      exerciseId: selectedQuestions.questaoId,
      alternativeId: alternative,
      attemptNumber: attempt?.numeroTentativa ?? 0,
    })
      .then(() => {
        setState((prev) => ({
          ...prev,
          questions: prev.questions.map((question) => {
            return question.questaoId === selectedQuestions.questaoId
              ? { ...question, respostaQuestao: alternative }
              : question
          }),
        }))
      })
      .catch(() => {
        return null
      })
      .finally(() => {
        setState((prev) => ({ ...prev, isExecutingLog: false }))
      })
  }

  useEffect(() => {
    setState((prev) => ({ ...prev, showCurrentAnswer: false }))
  }, [selectedQuestions])

  const isVisibilityButtonDisabled = !selectedQuestions?.respostaQuestao

  const showCorrectAnswer = showCurrentAnswer || showAllAnswers

  const isCorrect =
    selectedQuestions?.alternativaCorretaId ===
    selectedQuestions?.respostaQuestao

  const isUserAlternative = (altertiveId: number) => {
    return altertiveId === selectedQuestions?.respostaQuestao
  }

  const disableOptions = () => {
    if (isExecutingLog) return true
    return showCorrectAnswer
  }

  const computeAnswerCSSClass = (altertiveId: number) => {
    if (!showCorrectAnswer || !isUserAlternative(altertiveId)) {
      return 'question-alternative'
    }

    return classNames(
      'question-alternative',
      isCorrect ? 'correct' : 'incorrect'
    )
  }

  return (
    <div>
      <form className={styles.card}>
        <Typography variant="h5" className={styles.questionBadge}>
          Questão {questionIndex + 1}
        </Typography>

        <Typography variant="body1" className={styles.questionEnunciation}>
          {selectedQuestions?.conteudo}
        </Typography>

        <FormControl style={{ width: '100%' }}>
          <RadioGroup
            aria-labelledby="alternativas-da-questão"
            className={styles.questionRadioGroup}
            value={selectedQuestions?.respostaQuestao ?? 'Não inicializada'}
            onChange={(e) => savingLogs(Number(e.target.value))}
            name={`questão ${selectedQuestions?.questaoId}`}
          >
            {selectedQuestions?.alternativas?.map((alternative) => (
              <div
                key={alternative.alternativaId}
                className={computeAnswerCSSClass(alternative.alternativaId)}
              >
                <FormControlLabel
                  className={styles.questionAlternativeLabel}
                  disabled={disableOptions()}
                  value={alternative.alternativaId}
                  control={<Radio className={styles.radio} color="primary" />}
                  label={alternative.conteudo}
                />

                {showCorrectAnswer &&
                  isUserAlternative(alternative.alternativaId) && (
                    <>
                      {isCorrect ? (
                        <div className="answer-message">
                          <CheckCircle htmlColor="#1f8563" />
                          <span>Resposta correta!</span>
                        </div>
                      ) : (
                        <div className="answer-message">
                          <Cancel htmlColor="#a42c31" />
                          <span>
                            Resposta incorreta! A alternativa correta é a letra{' '}
                            <strong>
                              {alternativaCorretaQuestao(selectedQuestions)}
                            </strong>
                            .
                          </span>
                        </div>
                      )}
                    </>
                  )}
              </div>
            ))}
          </RadioGroup>
        </FormControl>

        {!showAllAnswers && (
          <Button
            className={styles.answerVisibilityButton}
            disabled={isVisibilityButtonDisabled}
            startIcon={showCurrentAnswer ? <VisibilityOff /> : <Visibility />}
            color="primary"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                showCurrentAnswer: !prev.showCurrentAnswer,
              }))
            }
          >
            {showCurrentAnswer
              ? 'Ocultar Resposta da Questão'
              : 'Visualizar resposta da questão'}
          </Button>
        )}
      </form>
    </div>
  )
}
