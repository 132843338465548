import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  divContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    width: '100%',
    marginTop: '24px',
  },
}))

export default useStyles
