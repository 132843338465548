import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    maxWidth: 413,
    margin: 'auto',
  },
  title: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 1.25),
  },
  progressBar: {
    margin: theme.spacing(0, 0, 1.25),
  },
  subTitle: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    margin: 0,
  },
}))

export default useStyles
