import { Schema, ObjectSchema } from 'joi'

const ptErrors = {
  'string.empty': 'Campo obrigatório',
  'array.includes': 'Campo obrigatório',
  'date.base': 'Campo obrigatório',
  'object.base': 'Campo obrigatório',
  'custom.beforeDate': 'Data inválida',
  'custom.afterDate': 'Data inválida',
  'custom.passedDate': 'Data inválida',
  'custom.equalsfields': 'Campos iguais',
  'string.email': 'O email deve ser um email válido',
  'custom.inThePast': 'Não pode ser uma data retroativa',
  'string.pattern.base': 'Campo com formato inválido',
  'custom.requiredValue': 'Campo obrigatório',
  'number.base': 'O valor informado deve ser um número',
}

type FormType<T> = T extends ObjectSchema<infer U> ? U : T

type ValidateSchemaReturnType<T> = {
  errors: { [key in keyof T]: boolean | string }
  isValid: boolean
}

type ValidateSingleFieldReturnType<
  SchemaType extends ObjectSchema,
  Key extends keyof FormType<SchemaType>
> = {
  [P in keyof Pick<FormType<SchemaType>, Key>]: boolean | string
}

const isInacessibleFieldError = (error: Error) => {
  return !!error?.message?.match('failed custom validation because')
}

function validateSchemaAndSliceField<
  SchemaType extends ObjectSchema,
  Key extends keyof FormType<SchemaType>
>(
  schema: SchemaType,
  formValues: FormType<SchemaType>,
  field: Key,
  value: FormType<SchemaType>[Key],
  context: object
): ValidateSingleFieldReturnType<SchemaType, Key> {
  const { errors } = validateAndGenerateErrors(
    schema,
    { ...formValues, [field]: value },
    context
  )

  return Object.fromEntries(
    Object.entries(errors).filter(([k]) => k === field)
  ) as ValidateSingleFieldReturnType<SchemaType, Key>
}

/**
 * Valida um campo de um schema individualmente
 * @param {*} field Nome do campo do schema que sera validado
 * @param {*} value O valor que será validado
 * @param {*} schema O schema completo do dominio
 * @returns Objeto com o formato com o field e mensagem de erro ou falso quando o
 * campo não possuir error
 *
 * ### Ex.:
 * ```js
 * const schema = Joi.object({name: Joi.string().required()})
 * validateSingleFieldAndGenerateErrors('name', null, schema)//{name: 'Campo obrigatorio'}
 * validateSingleFieldAndGenerateErrors('name', 'John', schema)//{name: false}
 * ```
 */
export function validateSingleFieldAndGenerateErrors<
  SchemaType extends ObjectSchema,
  Key extends keyof FormType<SchemaType>
>(
  schema: SchemaType,
  formValues: FormType<SchemaType>,
  field: Key,
  value: FormType<SchemaType>[Key],
  context: object = {}
): ValidateSingleFieldReturnType<SchemaType, Key> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldSchema: Schema = (schema as any)._ids._byKey.get(field)?.schema

  if (!fieldSchema) return { [field]: false } as never

  try {
    const { error = false } = fieldSchema.validate(value, {
      messages: ptErrors,
      abortEarly: true,
      allowUnknown: true,
      context,
    })

    if (error && isInacessibleFieldError(error)) {
      return validateSchemaAndSliceField(
        schema,
        formValues,
        field,
        value,
        context
      ) as never
    }

    if (!error) return { [field]: false } as never

    return {
      [field]: error.details.map((e) => e.message).join(' '),
    } as never
  } catch (error) {
    if (error instanceof Error) {
      if (error?.message?.match('Invalid reference exceeds the schema root')) {
        return validateSchemaAndSliceField(
          schema,
          formValues,
          field,
          value,
          context
        ) as never
      }
    }

    throw error
  }
}

/**
 *
 * @param {*} formValues objeto com os valores que sera validado pelo schema
 * @param {*} schema schema de validacoes
 * @returns objeto com mesmo formato do valors do form
 *
 * ### Exemplo
 * ```js
 * const values = {name: '', lastName: ''}
 * const schema = {name: Joi.string().required()}
 *
 * validateAndGenerateErrors(values, schema)
 * // {isValid: false, errors: {name: 'Campo obrigatório', lastName: false}}
 * ```
 */
export function validateAndGenerateErrors<T extends ObjectSchema>(
  schema: T,
  formValues: FormType<T>,
  context: object = {}
): ValidateSchemaReturnType<FormType<T>> {
  const fieldsWithoutErrors = Object.fromEntries(
    Object.keys(formValues).map((e) => [e, false])
  )

  const { error = false } = schema.validate(formValues, {
    messages: ptErrors,
    abortEarly: false,
    allowUnknown: true,
    context,
  })

  return {
    isValid: error === false,
    errors: {
      ...fieldsWithoutErrors,
      ...(error
        ? Object.fromEntries(
            error.details.map((a) => [a.context?.label, a.message])
          )
        : {}),
    },
  }
}
