import { TrackingData, trackingDataSlice, TrackingTypes } from './tracking-data'
import { TrackingTask } from './tracking-task'

export const conteudoCurso = (state: TrackingData, service: TrackingTask) => {
  const payload = {
    event: TrackingTypes.CONTEUDO_CURSO,
    event_text: TrackingTypes.CONTEUDO_CURSO,
    user_id: state.user_id,
    ...trackingDataSlice(state),
  }

  service(payload)
    .then(() => null)
    .catch(() => null)
}
