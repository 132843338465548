import { TipoDownload } from '@domain/geral'
import { redirecionamentoGenerico } from '@domain/redirecionamentos'
import { getCourseContentLink } from '@services/discipline/get-course-content-link'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const FileDownload: FC = () => {
  const { filename, courseId, type = TipoDownload.CONTEUDO } = useParams()

  const fetchFileUrlAndRedirect = () => {
    if (filename && courseId) {
      getCourseContentLink(
        parseInt(courseId, 10),
        filename,
        type as TipoDownload
      ).then((url) => {
        redirecionamentoGenerico(url, false)
      })
    }
  }

  useEffect(() => {
    fetchFileUrlAndRedirect()
  }, [])

  return null
}
