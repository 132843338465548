import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    background:
      'linear-gradient(0deg, rgba(64, 64, 161, 0.08), rgba(64, 64, 161, 0.08)), #FFFFFF;',
    borderRadius: 4,

    marginTop: 12,
    padding: '12px 18px',

    '& p': {
      marginLeft: 10,
      marginTop: 3,
      color: theme.palette.text.primary,
    },

    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}))

export default useStyles
