import { Curso } from '@domain/curso'
import httpClient from '../../gateway/generalHttpRequest'
import { getRequestResponseHandler } from '@services/utils/response-handlers'
import { CourseAdapter } from './adapters/course'

export const getCourse = (courseId: number): Promise<Curso> => {
  return new Promise((resolve, reject) => {
    const path = `/offers/api/v1/course/${courseId}`
    httpClient
      .get<Curso>(path)
      .then((response) => getRequestResponseHandler(response))
      .then((jsonData) => resolve(CourseAdapter.inbound(jsonData)))
      .catch((err) => reject(err))
  })
}
