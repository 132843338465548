import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonBack: {
    color: theme.palette.primary.dark,
    background: theme.palette.grey[100],
    height: 32,
    margin: '16px 24px',
    '&:hover': {
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      margin: 0,
      color: theme.palette.primary.contrastText,
      background: 'rgba(0, 0, 0, 0)',
    },
  },
  subtitle: {
    boxShadow: '-4px 2px 13px rgba(0,0,0,0.25)',
    backgroundColor: '#F5F5F5',
    borderRadius: 'none',
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  subtitleMaterial: {
    padding: '16px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  card: {
    padding: 16,
    '&:last-child': {
      color: 'red',
      padding: '16px 0px 0px 0px',
    },
  },
  selectDiscipline: {
    minWidth: 120,
    width: '85%',
    margin: '8px 24px 24px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 60px)',
      marginTop: 24,
      height: 61,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  boxThemes: {
    marginTop: 16,
    paddingBottom: 8,
    height: 'calc(100vh - 130px - 64px - 229px - 16px - 56px)',
    overflowY: 'scroll',
    background: theme.palette.grey[200],
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw-48px)',
      height: '100%',
      background: theme.palette.grey[100],
      overflowY: 'hidden',
      margin: '24px 24px 32px',
    },
    '@media screen and (max-width: 360px)': {
      margin: '24px 0px 32px',
    },
  },
  boxMaterials: {
    bottom: 0,
    background: theme.palette.grey[100],
    padding: '0 24px 20px',
    marginTop: 24,
    '& > div:nth-of-type(n+2)': {
      marginTop: 12,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      filter: 'drop-shadow(-6px 0 13px rgba(0,0,0,0.25))',
      maxWidth: 358,
      position: 'fixed',
      height: 229,
      overflow: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
    },
  },
  boxDiscipline: {
    position: 'relative',
    height: 'calc(100vh - 130px - 64px - 229px)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  disciplineName: {
    fontFamily: 'roboto',
    fontSize: 20,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 272,
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
    },
  },
  override: {
    '&#mobile-tab-overrides': {
      '& > div > div:nth-child(2) > div > hr': {
        margin: '0 24px',
      },
      '& > div > div:nth-child(3) > div > hr': {
        margin: '0 24px',
      },
      '& > div > div:first-child': {
        margin: '0 24px',
      },
    },
  },
}))

export default useStyles
