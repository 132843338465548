import { EcomRoutesMapping } from '@utils/ecom-routes-mapping'
import { handleUrlWithHttps } from '@utils/handle-url-with-https'
import { isBefore } from 'date-fns'

const redirectToMyCourses = (sessionToken: string) => {
  const { REACT_APP_ECOMMERCE_URL } = process.env

  const ecommerceUrl = handleUrlWithHttps(REACT_APP_ECOMMERCE_URL as string)

  window.location.href = `${ecommerceUrl}${EcomRoutesMapping.COURSES}?sessionToken=${sessionToken}`
}

const redirectToHome = () => {
  const { REACT_APP_ECOMMERCE_URL } = process.env

  const ecommerceUrl = handleUrlWithHttps(REACT_APP_ECOMMERCE_URL as string)

  window.location.href = ecommerceUrl
}

export const redirecionarParaEcommerce = (
  sessionToken: string,
  expirationDate: Date
) => {
  if (isBefore(expirationDate, Date.now())) {
    redirectToHome()
    return
  }

  redirectToMyCourses(sessionToken)
}
