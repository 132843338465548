import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    '@media (max-width: 599px)': {
      padding: '0px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 960px)': {
      padding: '0 0 0px',
    },
    '@media (max-width: 599px)': {
      marginBottom: 16,
    },
  },
  titlePage: {
    display: 'block',
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
  actionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '268px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '8px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 8,
    },
    '@media (max-width: 599px)': {
      display: 'none',
    },
  },
  actionHeaderSm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '268px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px 12px 0px 8px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    '@media (min-width: 599px)': {
      display: 'none',
    },
  },
  columnTextAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
  },
  textAction: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '166%',
    color: theme.palette.text.secondary,
  },
  labelAction: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '143%',
    color: theme.palette.text.secondary,
    marginBottom: '8px',
  },
  mainDiscipline: {
    display: 'grid',
    gridTemplateColumns: '1fr 286px',
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
  mainDisciplineMobile: {
    display: 'none',
    '@media (max-width: 960px)': {
      display: 'block',
    },
  },
  columnAcordion: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
    gap: '24px',
    marginRight: '64px',
    '@media (max-width: 960px)': {
      marginBottom: '56px',
      marginRight: '0px',
    },
    '@media (max-width: 599px)': {
      gap: '16px',
    },
  },
  columnContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    paddingLeft: '24px',
    borderLeft: '1px solid #E0E0E0',
    '@media (max-width: 960px)': {
      paddingLeft: '0px',
      borderLeft: 'none',
      width: '100%',
    },
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  subtitleContent: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.25,
    lineHeight: '1em',
    margin: '0px',
  },
  tabs: {
    width: '100%',
    '@media (min-width: 960px)': {
      display: 'none',
    },
  },
}))

export default useStyles
