import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    accordion: {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
    },
    accordionSummary: {
      background: 'rgba(0, 0, 130, 0.08)',
      borderRadius: '8px',
      padding: '0px 24px',
      width: '580px',
      boxSizing: 'border-box',

      '& > .MuiIconButton-root': {
        padding: '16px',
      },
      '& > .MuiAccordionSummary-content': {
        margin: '10px 0',
        alignItems: 'center',
      },
      '& > .MuiAccordionSummary-expandIcon': {
        color: '#000082',
      },
      '&.Mui-expanded': {
        minHeight: '56px',
      },
      '@media (max-width: 960px)': {
        width: '100%',
      },
      '@media (max-width: 599px)': {
        padding: '0px 24px 0px 16px',
        width: '100%',
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: '0px',
    },
    heading: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 599px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    headingName: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '160%',
      color: '#000082',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '407px',
      textOverflow: 'ellipsis',
      '@media (max-width: 599px)': {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '150%',
      },
    },
    headingNameExpanded: {
      whiteSpace: 'normal',
      overflow: 'visible',
      maxWidth: '407px',
      textOverflow: 'unset',
    },
    headingInfo: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '166%',
      minWidth: '75px',
      textAlign: 'end',
      color: theme.palette.text.secondary,
    },
  })
)
