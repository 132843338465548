import { BuscarDadosTentativa } from '@domain/exercicio-fixacao'
import httpClient from '@gateway/generalHttpRequest'
import { CurrentAttemptAdapter } from './adapter/current-attempt'
import { RemoteAttempt } from './remote-fields/current-attempt'

export const getCurrentAttempt: BuscarDadosTentativa = (
  disciplineId,
  userId
) => {
  return new Promise((resolve) => {
    const path = `/contents/api/v1/fixation_exercise/attempt?user_id=${userId}&subject_id=${disciplineId}`

    httpClient
      .get<RemoteAttempt>(path)
      .then((response) => response.json())
      .then((data) => resolve(CurrentAttemptAdapter.inbound(data)))
      .catch(() => resolve(null))
  })
}
