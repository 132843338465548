/**
 * Enum para indicar os tipos de download de arquivo, usado para direcionamento
 * do endpoint correto
 */
export enum TipoDownload {
  /** usado para conteudo de disciplina */
  CONTEUDO = 'conteudo',
  /** usada para material complemental */
  MATERIAL = 'material',
}
