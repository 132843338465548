import { FC, useEffect, useState } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { Container, Typography } from '@material-ui/core'

import { ROUTES } from '@routes/app-routes'
import useStyles from './styles'
import useAppStyles from '@components/AppStyles'
import { CardExtraContent } from '@equipe-ninja/saraiva-ui'
import { PictureAsPdf, Poll, Notes } from '@material-ui/icons'
import { getContent } from '@services/complementary-material/get-complementary-content'
import { DataContentProps } from '@domain/material'
import { trackingStateSelector } from '@atoms/tracking'
import { useRecoilValue } from 'recoil'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { BackButton } from '@components/BackButton'
import { courseDataSelector } from '@atoms/course'
import { MaterialComplementar } from '@domain/curso'
import { TipoDownload } from '@domain/geral'
import { redirecionamentoGenerico } from '@domain/redirecionamentos'
import { ContentWithLoader } from '@components/ContentWithLoader'
interface IconsType {
  planilha: string
  pdf: string
  txt: string
}

export const Materiais: FC = () => {
  const globalClasses = useAppStyles()
  const [contents, setContents] = useState<DataContentProps[]>([])
  const [isFetching, setIsFetching] = useState(true)
  const { materialId, courseId } = useParams()
  const classes = useStyles()
  const trackingState = useRecoilValue(trackingStateSelector)
  const { materiaisComplementar, simulados } =
    useRecoilValue(courseDataSelector)
  const currentMaterial: MaterialComplementar | null =
    materiaisComplementar.find((m) => m.id === Number(materialId)) ?? null

  const currentSimulado: MaterialComplementar | null =
    simulados.find((m) => m.id === Number(materialId)) ?? null

  const handleGetContent = () => {
    setIsFetching(true)
    getContent(materialId as string)
      .then((data) => {
        setContents(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handleRedirectUrlPath = (
    url: string,
    materialName: string,
    materialType: string,
    path: string
  ) => {
    if (url === null) return

    if (currentMaterial !== null) {
      Tracking.clicouMaterialComplementar(
        trackingState.data,
        tracking,
        materialName,
        materialType
      )
    }

    if (currentSimulado !== null) {
      Tracking.clicouMaterialCurso(
        trackingState.data,
        tracking,
        materialName,
        materialType
      )
    }

    if (courseId) {
      const url = new URL(
        generatePath(ROUTES.FILE_DOWNLOAD, {
          courseId: courseId,
          filename: encodeURIComponent(path),
          type: TipoDownload.MATERIAL,
        }),
        window.location.origin
      )

      redirecionamentoGenerico(url.toString(), true)
    }
  }

  const icons = {
    pdf: <PictureAsPdf style={{ fontSize: 100 }} />,
    planilha: <Poll style={{ fontSize: 100 }} />,
    txt: <Notes style={{ fontSize: 100 }} />,
  }

  useEffect(() => {
    handleGetContent()
  }, [])

  return (
    <ContentWithLoader isLoading={isFetching}>
      <Container>
        <BackButton />
        <Typography variant="h4" className={globalClasses.title}>
          {currentMaterial?.nome || currentSimulado?.nome}
        </Typography>

        <Typography variant="body2">
          {currentMaterial?.descricao || currentSimulado?.descricao}
        </Typography>

        <div className={classes.divContent}>
          {contents &&
            contents.map((content: DataContentProps) => (
              <CardExtraContent
                key={content.id}
                hoverTitle="Acesse o arquivo"
                icon={icons[content.type as keyof IconsType]}
                onClick={() =>
                  handleRedirectUrlPath(
                    content.furl,
                    content.name,
                    content.type,
                    content.path
                  )
                }
                color="light"
                title={content.name}
              />
            ))}
        </div>
      </Container>
    </ContentWithLoader>
  )
}
