import { FC, Suspense } from 'react'
import { Box, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'

import { ExerciseList } from '@components/ExerciseList'

import useAppStyles from '@components/AppStyles'
import useStyles from './styles'
import { CircularLoader } from '@components/CircularLoader'
import { BackButton } from '@components/BackButton'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '@routes/app-routes'

export const Exercicios: FC = () => {
  const globalClasses = useAppStyles()
  const localClasses = useStyles()

  const backButtonLink = (): string => {
    return generatePath(ROUTES.HOME)
  }

  return (
    <div style={{ width: '100%' }}>
      <BackButton link={backButtonLink()} />

      <Typography
        variant="h4"
        className={globalClasses.title}
        style={{ marginBottom: 0 }}
      >
        Exercícios
      </Typography>

      <Box className={localClasses.warning}>
        <ErrorOutline />
        <Typography variant="body2">
          Os gabaritos serão ser disponibilizados 1 semana antes da 2ª fase da
          OAB
        </Typography>
      </Box>

      <Suspense fallback={<CircularLoader />}>
        <ExerciseList />
      </Suspense>
    </div>
  )
}
