import { FC } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Dialog } from '@equipe-ninja/saraiva-ui'
import { Typography } from '@material-ui/core'

import { useStyles } from './styles'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { retryAttempt } from '@services/fixation-exercise'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { trackingStateSelector } from '@atoms/tracking'

type RedoExerciseDialogProps = {
  isOpen: boolean
  onClose: () => void
  label?: string
  title: string
}

export const RedoExerciseDialog: FC<RedoExerciseDialogProps> = ({
  isOpen,
  onClose,
  label,
  title,
}) => {
  const styles = useStyles()

  const [{ attempt }, setState] = useRecoilState(fixationExerciseOverState)
  const trackingState = useRecoilValue(trackingStateSelector)

  function redoExercise() {
    if (!attempt?.tentativaId) return
    setState((prev) => ({ ...prev, isExecutingLog: true }))
    retryAttempt(attempt?.tentativaId)
      .then(() => {
        setState((prev) => ({
          ...prev,
          resetObservable: Date.now(),
          showAllAnswers: false,
          showCurrentAnswer: false,
        }))
      })
      .catch(() => {
        return null
      })
      .finally(() => {
        setState((prev) => ({ ...prev, isExecutingLog: false }))
        Tracking.clicouConfirmaRefazer(
          trackingState.data,
          tracking,
          title,
          'Exercício de Fixação'
        )
      })

    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      title="Refazer o exercício"
      primaryButton="Refazer"
      secondaryButton="Cancelar"
      handleClose={onClose}
      handleConfirm={redoExercise}
      confirmWithoutCheck={() => {
        //
      }}
    >
      <div className={styles.dialogContent}>
        <Typography color="textPrimary" variant="body1">
          Deseja refazer todas as questões?
        </Typography>
        <Typography>{label}</Typography>
      </div>
    </Dialog>
  )
}
