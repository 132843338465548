import useStyles from './styles'
import LogoColor from '../../assets/logo-lfg-color.svg'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { ROUTES } from '@routes/app-routes'
import { useNavigate } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'

export function Footer() {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.footer}>
      <Button className={classes.logo} onClick={() => navigate(ROUTES.HOME)}>
        <img src={LogoColor} alt="logo" className={classes.logo} />
      </Button>
      <Divider orientation="vertical" className={classes.divider} />
      <div className={classes.texts}>
        <Link
          target="_blank"
          href="https://www.canaldatransparencia.com.br/cogna/"
          className={classes.polity}
        >
          Política de Privacidade
        </Link>

        <Typography variant="body2" className={classes.copyright}>
          Copyright © 2022 - LFG. Todos os direitos reservados.
        </Typography>
      </div>
    </div>
  )
}
