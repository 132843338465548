import { trackingStateSelector } from '@atoms/tracking'
import { debounce } from 'lodash'
import { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { AuthContext } from '@contexts/auth'

export const useTrackingInformation = () => {
  const location = useLocation()
  const { userInfo } = useContext(AuthContext)
  const setTrakingState = useSetRecoilState(trackingStateSelector)

  const debouncedResizeHandler = useRef(
    debounce((value) => {
      setTrakingState((prev) => ({
        ...prev,
        data: { ...prev.data, context_screen: value },
      }))
    }, 600)
  ).current

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resizeHandler = (event: any) =>
      debouncedResizeHandler(event?.target?.innerWidth)
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  useEffect(() => {
    setTrakingState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        context_page_url: `${window.location.origin}${location.pathname}`,
        context_page_path: location.pathname,
        user_id: userInfo?.id ?? 0,
      },
      observableId: Date.now(),
    }))
  }, [location.pathname])
}
