import { Disciplines, ThemeProps, SubThemeProps } from '@domain/discipline'

type RetornoBuscaAulaPorId = null | [number, number, number]

const buscarNasAulas = (
  a3: SubThemeProps[],
  id: number,
  sid: number,
  tId: number
): RetornoBuscaAulaPorId => {
  return a3.reduce<RetornoBuscaAulaPorId>((prev, e) => {
    if (prev) return prev

    return e?.idClass === id ? [sid, tId, e.idClass] : null
  }, null)
}

const buscarNosTemas = (
  a2: ThemeProps[],
  id: number,
  sId: number
): RetornoBuscaAulaPorId => {
  return a2.reduce<RetornoBuscaAulaPorId>((prev, e) => {
    if (prev) return prev

    return buscarNasAulas(e.dataSubTheme, id, sId, e.themeId)
  }, null)
}

const buscarNasDisciplinas = (
  a1: Disciplines[],
  id: number
): RetornoBuscaAulaPorId => {
  return a1.reduce<RetornoBuscaAulaPorId>((prev, e) => {
    if (prev) return prev

    return buscarNosTemas(e.dataTheme, id, e.disciplineId)
  }, null)
}

const buscarUltimaAula = (
  disciplinas: Disciplines[]
): RetornoBuscaAulaPorId => {
  if (disciplinas.length === 0) return [0, 0, 0]

  const ultimaDisciplina = disciplinas[disciplinas.length - 1]
  const temas = ultimaDisciplina.dataTheme
  const ultimoTema = temas[temas.length - 1]
  const aulas = ultimoTema.dataSubTheme
  const ultimaAula = aulas[aulas.length - 1]

  return [ultimaDisciplina.disciplineId, ultimoTema.themeId, ultimaAula.idClass]
}

export const buscarAulaPorIdNasDisciplinas = (
  disciplinas: Disciplines[],
  aulaIdBuscada: number
): RetornoBuscaAulaPorId => {
  const resultado = buscarNasDisciplinas(disciplinas, aulaIdBuscada)

  if (resultado) return resultado

  return buscarUltimaAula(disciplinas)
}
