import { Disciplines, ThemeProps, SubThemeProps } from '@domain/discipline'

function proximoEstadoDasAulas(
  aulas: SubThemeProps[],
  id: number
): SubThemeProps[] {
  return aulas.map((e) => ({
    ...e,
    classStudentWatched: e.idClass == id || e.classStudentWatched,
  }))
}

function proximoEstadoDosTemas(temas: ThemeProps[], id: number): ThemeProps[] {
  return temas.map((e) => ({
    ...e,
    dataSubTheme: proximoEstadoDasAulas(e.dataSubTheme, id),
  }))
}

function proximoEstadoDasDisciplinas(
  disciplinas: Disciplines[],
  id: number
): Disciplines[] {
  return disciplinas.map((e) => ({
    ...e,
    dataTheme: proximoEstadoDosTemas(e.dataTheme, id),
  }))
}

export const gerarProximoEstadoParaAulaAssistida = (
  disciplinas: Disciplines[],
  aulaId: number
): Disciplines[] => {
  return proximoEstadoDasDisciplinas(disciplinas, aulaId)
}
