import {
  ErroAcessoCursoNaoAutorizado,
  ErroInternoServidor,
} from '@domain/erros'
import { atualizarSessionToken } from '@domain/geral'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRequestResponseHandler = (response: any) => {
  const ACCEPTED_STATUS = [200]
  const FORBIDDEN_STATUS = 403
  const INTERNAL_ERROR = 500

  const headers = Object.fromEntries([...response.headers])
  const hasToken = Object.keys(headers).includes('session-token')

  if (hasToken) {
    atualizarSessionToken(headers['session-token'])
  }

  if (ACCEPTED_STATUS.includes(response?.status)) {
    return response.json()
  }

  if (FORBIDDEN_STATUS === response?.status) {
    return Promise.reject(new ErroAcessoCursoNaoAutorizado())
  }

  if (INTERNAL_ERROR === response?.status) {
    return Promise.reject(new ErroInternoServidor())
  }

  return Promise.reject(new Error('Invalid response code for get-request'))
}
