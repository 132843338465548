import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 0,
    position: 'relative'
  },
  titlePage: {
    display: 'block',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    right: 0,
    '@media screen and (min-width: 603px)': {
      transform: 'translateY(-50%)'
    },
    '@media screen and (max-width: 602px)': {
      left: 0,
      top: 50
    }
  }
}))
