import { FC, useEffect, useState } from 'react'
import { Divider, Paper, Typography } from '@material-ui/core'
import {
  ArrowForwardSharp,
  Assignment,
  EventAvailable,
  EventNote,
  EventBusy,
  Spellcheck,
} from '@material-ui/icons'
import { generatePath, Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { courseDetailState } from '@atoms/course/course-selector'
import useStyles from './styles'
import { ROUTES } from '@routes/app-routes'

interface TemporaryProps {
  state?: 'availableUntil' | 'availableFrom' | 'completed' | 'hasFeedback'
}
interface AccessWindowedContent {
  title: string
  description: string
  state: 'availableUntil' | 'availableFrom' | 'completed' | 'hasFeedback'
  statusText: number
  statusData: string
}

const statusIcons = {
  availableUntil: <EventAvailable />,
  availableFrom: <EventNote />,
  completed: <EventBusy />,
  hasFeedback: <Spellcheck />,
}

export const AccessWindowedContentRedirector: FC<TemporaryProps> = () => {
  const classes = useStyles()
  const [status, setStatus] = useState<AccessWindowedContent>()
  const courseState = useRecoilValue(courseDetailState)

  const resolveStatus = () => {
    const templateDate = courseState.secondPhaseExercise.dataFinal
      .split('-')
      .reverse()
      .join('/')

    setStatus({
      title: courseState?.secondPhaseExercise?.title,
      description: courseState?.secondPhaseExercise?.textEnunciated,
      state: 'availableFrom',
      statusText: courseState?.secondPhaseExerciseCount,
      statusData: templateDate,
    })
  }

  const linkUrl = () => {
    if (courseState.id) {
      return generatePath(ROUTES.EXERCISES, {
        courseId: courseState.id.toString(),
      })
    }

    return ''
  }

  useEffect(resolveStatus, [courseState.id])

  return (
    <Link style={{ textDecorationLine: 'none' }} to={linkUrl()}>
      <Paper className={`${classes.card}`}>
        <div className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            Exercícios OAB 2ª Fase
          </Typography>
          <div className={classes.assignment}>
            <Assignment className={classes.assignmentIcon} />
          </div>
        </div>

        <div className={classes.cardContent}>
          <Typography variant="caption" className={classes.description}>
            Peças processuais e blocos de exercícios
          </Typography>
          {status && status.state && (
            <div className={classes.statusContainer}>
              <div className={`${classes.status} ${classes[status.state]}`}>
                {statusIcons[status.state]}
                <Typography variant="caption">
                  Consulte a disponibilidade dos exercícios
                </Typography>
              </div>
            </div>
          )}
          <Divider className={classes.divider} />
          <div className={classes.linkContainer}>
            <span className={classes.label}>Acessar Página de Exercícios</span>
            <ArrowForwardSharp
              style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          </div>
        </div>
      </Paper>
    </Link>
  )
}
