import { FC } from 'react'
import useStyles from './styles'
import { Box, Typography, Card } from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'
import { ReportProblemOutlined } from '@material-ui/icons'
import cls from 'classnames'
import {
  ConteudoAgendado,
  StatusConteudoAgendado,
} from '@domain/conteudos-agendados'
import {
  computarEventosPrazos,
  computarStatusConteudoAgendado,
  computarTextoStatusConteudoAgendado,
  computarTextoTipoConteudoAgendado,
} from '@domain/conteudos-agendados/negocio'
import { redirecionamentoGenerico } from '@domain/redirecionamentos'
import { Tracking } from '@domain/trackings'
import { useRecoilValue } from 'recoil'
import { trackingStateSelector } from '@atoms/tracking'
import { tracking } from '@services/tracking'

type ExerciceCardProps = {
  conteudo: ConteudoAgendado
  onVerEnunciado: (titulo: string, enunciado: string) => void
  onVerGabarito: (titulo: string, enunciado: string) => void
}

export const ExerciceCard: FC<ExerciceCardProps> = (props) => {
  const { conteudo, onVerEnunciado, onVerGabarito } = props
  const classes = useStyles()
  const eventos = computarEventosPrazos(conteudo)
  const statusConteudoAgendado = computarStatusConteudoAgendado(conteudo)
  const isShowingWarning =
    statusConteudoAgendado === StatusConteudoAgendado.CONCLUIDO

  const trackingState = useRecoilValue(trackingStateSelector)

  const onClickFazerExercicio = (nomeAtividade: string) => {
    Tracking.clicouFazerAtividade(trackingState.data, tracking, nomeAtividade)
    redirecionamentoGenerico(conteudo.linkFormulario)
  }

  const onClickVerEnunciado = (nomeAtividade: string) => {
    onVerEnunciado(
      `${conteudo.tituloEnunciado} | Enunciado`,
      conteudo.textoEnunciado
    )
    Tracking.clicouVerEnunciado(trackingState.data, tracking, nomeAtividade)
  }

  const onClickVerGabarito = (nomeAtividade: string) => {
    onVerGabarito(
      `${conteudo.tituloEnunciado} | Gabarito`,
      conteudo.textoGabarito
    )
    Tracking.clicouVerGabarito(trackingState.data, tracking, nomeAtividade)
  }

  return (
    <Card className={classes.card}>
      <Box
        className={`${classes.statusBar} ${classes[statusConteudoAgendado]}`}
      >
        {computarTextoStatusConteudoAgendado(conteudo)}
      </Box>

      <div>
        <Typography variant="body1" style={{ marginBottom: 4 }}>
          {conteudo.tituloEnunciado}
        </Typography>

        <Typography variant="body2" style={{ marginBottom: 24 }}>
          {computarTextoTipoConteudoAgendado(conteudo)}
        </Typography>

        <Typography variant="body2" style={{ marginBottom: 4 }}>
          Prazos:
        </Typography>

        <Box className={classes.scheduleWrapper}>
          {eventos.map((event) => (
            <Typography
              variant="caption"
              key={event.texto}
              className={cls(
                classes.event,
                event.passoAtual && classes.currentEvent
              )}
            >
              {event.texto}
            </Typography>
          ))}
        </Box>

        {isShowingWarning && (
          <Box className={classes.warning}>
            <ReportProblemOutlined />
            <Typography variant="body2">
              Encontre a correção no e-mail cadastrado no exercício.
            </Typography>
          </Box>
        )}
      </div>

      <div>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => onClickVerEnunciado(conteudo.tituloEnunciado)}
        >
          Ver Enunciado
        </Button>

        {statusConteudoAgendado === StatusConteudoAgendado.DISPONIVEL && (
          <Button
            color="secondary"
            variant="contained"
            className={`${classes.button} backgroundSecondaryLight`}
            onClick={() => onClickFazerExercicio(conteudo.tituloEnunciado)}
          >
            Fazer Exercício
          </Button>
        )}

        {statusConteudoAgendado === StatusConteudoAgendado.CONCLUIDO && (
          <Button
            className={classes.button}
            color="primary"
            onClick={() => onClickVerGabarito(conteudo.tituloEnunciado)}
            variant="contained"
          >
            Ver Gabarito
          </Button>
        )}
      </div>
    </Card>
  )
}
