export enum ROUTES {
  HOME = '/',
  ACCESS_DENIED = '/acesso-negado',
  DASHBOARD_COURSE = '/:courseId',
  MATERIALS_ID = '/:courseId/materiais/:materialId',
  EXERCISES = '/:courseId/exercicios',
  CLASS = '/:courseId/aula/:classId',
  DISCIPLINE = '/:courseId/disciplina',
  FIXATION_EXERCISES = '/:courseId/exercicios-fixacao/:exerciseId',
  FILE_DOWNLOAD = '/dlcontent/:courseId/:filename/:type',
  INTERNAL_ERROR = '/erro-interno',
}
