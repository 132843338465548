import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Button, Tabs } from '@equipe-ninja/saraiva-ui'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core'
import { CardMaterial } from '@pages/Class/components/CardMaterial'
import { CardExercicio } from '../CardExercicio'
import { ThemeAccordion } from '@pages/Discipline/components/ThemeAccordion'
import { useClassContext } from '@contexts/class'
import useStyles from './styles'
import { ROUTES } from '@routes/app-routes'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentClassSelector } from '@atoms/course'
import cls from 'classnames'
import { Tracking } from '@domain/trackings'
import { trackingStateSelector } from '@atoms/tracking'
import { tracking } from '@services/tracking'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { courseState } from '@atoms/course/course-state'

export const CourseContent: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { disciplines, classDisciplineIndex } = useClassContext()
  const { courseId } = useRecoilValue(currentClassSelector)

  const [selectedDisciplineId, setSelectedDisciplineId] = useState(0)
  const [selectedDisciplineName, setSelectedDisciplineName] = useState('')

  const trackingState = useRecoilValue(trackingStateSelector)
  const setFixationSubjecId = useSetRecoilState(fixationExerciseOverState)
  const { apostila } = useRecoilValue(courseState)

  useEffect(() => {
    if (!disciplines || disciplines.length === 0) return

    setSelectedDisciplineId(
      disciplines[classDisciplineIndex]?.disciplineId || 0
    )

    setSelectedDisciplineName(disciplines[classDisciplineIndex]?.disciplineName)
  }, [classDisciplineIndex, disciplines])

  const activeDiscipline = disciplines.find(
    (discipline) => discipline.disciplineId === selectedDisciplineId
  )

  const themes = activeDiscipline?.dataTheme || []

  const handleBack = () => {
    navigate(
      generatePath(ROUTES.DISCIPLINE, {
        courseId: courseId.toString(),
      })
    )
  }

  const findDisciplineName = (disciplineId: number) => {
    const disciplineName = disciplines.filter(
      (discipline) => discipline.disciplineId === disciplineId
    )
    return disciplineName[0]?.disciplineName
  }

  const handleChangeActiveDiscipline = (disciplineId: number) => {
    setSelectedDisciplineId(disciplineId)

    const disciplineName = findDisciplineName(disciplineId)

    setSelectedDisciplineName(disciplineName)

    Tracking.clicouDisciplina(trackingState.data, tracking, disciplineName)
  }

  const apostilaDisciplina = apostila[selectedDisciplineId] ?? []

  const showThemes = () => {
    return (
      <>
        <Typography variant="body1" className={classes.subtitle}>
          Temas da disciplina
        </Typography>

        <Box className={classes.boxThemes}>
          {themes &&
            themes.map((theme) => (
              <ThemeAccordion
                themeId={theme.themeId}
                key={theme.themeId}
                nameTheme={theme.nameTheme}
                dataSubTheme={theme.dataSubTheme}
                disciplineName={selectedDisciplineName}
              />
            ))}
        </Box>
      </>
    )
  }

  const showMaterials = () => {
    return (
      <>
        <Box className={classes.boxMaterials}>
          <Typography
            className={`${classes.subtitleMaterial} tour-consumo-materiais-disciplina-passo-2`}
          >
            <div className={'tour-consumo-materiais-disciplina-passo-2'}>
              Materiais da disciplina
            </div>
          </Typography>

          <CardExercicio
            text="Exercício de fixação"
            colorTag="primary"
            variant="course"
            key={56}
            disciplineName={selectedDisciplineName}
          />

          {apostilaDisciplina.map((item) => (
            <CardMaterial
              text={item.nomeExibição}
              contentName={item.nomeArquivo}
              colorTag="primary"
              variant="course"
              key={item.id}
            />
          ))}
        </Box>
      </>
    )
  }

  const disciplinesInTabs = () => {
    return [
      {
        label: 'Temas da disciplina',
        content: showThemes(),
      },
      {
        label: 'Materiais da disciplina',
        content: showMaterials(),
      },
    ]
  }

  useEffect(() => {
    setFixationSubjecId((prev) => ({
      ...prev,
      disciplineId: activeDiscipline?.disciplineId || 0,
    }))
  }, [activeDiscipline?.disciplineId])

  return (
    <>
      <Box>
        <Button
          className={classes.buttonBack}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          color="primary"
        >
          voltar
        </Button>
        <FormControl
          variant="standard"
          className={classes.selectDiscipline}
          fullWidth
        >
          <Select
            id="demo-simple-select-standard"
            value={activeDiscipline?.disciplineId || 0}
            onChange={(e) =>
              handleChangeActiveDiscipline(e.target.value as number)
            }
          >
            {disciplines.map((discipline) => (
              <MenuItem
                value={discipline.disciplineId}
                key={discipline.disciplineId}
              >
                <span className={classes.disciplineName}>
                  {discipline.disciplineName}
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box className={classes.boxDiscipline}>
        {showThemes()}
        {showMaterials()}
      </Box>

      <Box
        className={cls(classes.showMobile, classes.override)}
        id="mobile-tab-overrides"
      >
        <Tabs color="primary" variant="scrollable" showDivider mode="grow">
          {disciplinesInTabs()}
        </Tabs>
      </Box>
    </>
  )
}
