import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    padding: '0'
  },
  card: {
    border: '1px solid rgb(235,235,235)',
    marginBottom: 16,
    padding: '24px 16px'
  },
  subject: {
    color: '#757575'
  },
  cardContentStyle: {
    padding: 0,
    marginBottom: 16,

    '& h6': {
      marginBottom: 16,
      lineHeight: '1em'
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: 40,

      '& h6': {
        marginBottom: 8
      }
    }
  },
  cardActionsStyle: {
    padding: 0,
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  btnKeepOnSubjectTo: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.secondary.light,

    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  btnGetOffSubjectTo: {
    [theme.breakpoints.down('xs')]: {
      '&:not(:first-child)': {
        marginLeft: 0
      }
    }
  }
}))
