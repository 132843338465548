import { TipoDownload } from '@domain/geral'
import httpClient from '@gateway/generalHttpRequest'
import { getRequestResponseHandler } from '@services/utils/response-handlers'

export const getCourseContentLink = (
  courseId: number,
  filename: string,
  type: TipoDownload = TipoDownload.CONTEUDO
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const path =
      type === TipoDownload.MATERIAL
        ? `/contents/api/v1/material_link/${courseId}?filename=${encodeURIComponent(
            filename
          )}`
        : `/offers/api/v1/course_content_link/${courseId}?filename=${encodeURIComponent(
            filename
          )}`

    httpClient
      .get(path)
      .then((response) => getRequestResponseHandler(response))
      .then((jsonData: { url: string }) => resolve(jsonData.url))
      .catch((err) => {
        reject(err)
      })
  })
}
