import { ConteudoAgendado } from '../conteudo-agendado'
import { TipoConteudoAgendado } from '../tipo-conteudo-agendado'

export const computarTextoTipoConteudoAgendado = (
  conteudoAgendado: ConteudoAgendado
): string => {
  switch (conteudoAgendado.tipo) {
    case TipoConteudoAgendado.BLOCO_QUESTOES:
      return 'Bloco de questões'
    default:
      return 'Peça processual'
  }
}
