import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    '@media screen and (max-width: 962px)': {
      marginTop: 0,
      gap: 0,
      gridTemplateColumns: '1fr',
      padding: 0
    },
    display: 'grid',
    gridTemplateColumns: '224px 1fr',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(20),
    marginTop: theme.typography.pxToRem(24),

    '@media screen and (max-width: 603px)': {
      marginTop: theme.typography.pxToRem(27)
    },

    '&.is-template-visualization': {
      '@media screen and (max-width: 603px)': {
        marginTop: -5
      }
    }
  },
  templateVisualizationMessage: {
    width: '100%',
    backgroundColor: '#5ECFB5',
    color: theme.palette.primary.dark,
    fontWeight: 500,
    padding: '0.5rem 1rem',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(16),

    '@media screen and (max-width: 603px)': {
      marginTop: theme.typography.pxToRem(47)
    }
  },
  actualQuestionCallToAction: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(24)
  },
  templateButton: {
    backgroundColor: '#5ECFB5',
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white
    }
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),

    '& > h6': {
      color: '#000'
    }
  }
}))
