export type CertificateConfigurationResponseData = {
  cpf: string
  id: number
  inserted_at: string
  name: string
  updated_at: string
  user_id: number
}

export type CertificateConfiguration = {
  document: string
  id: number
  name: string
  createdAt: Date
  updatedAt: Date
}

export const adapterCertificateConfiguration = 
  (data: CertificateConfigurationResponseData): CertificateConfiguration => {
  return {
    document: data.cpf,
    name: data.name,
    id: data.id,
    createdAt: new Date(data.inserted_at),
    updatedAt: new Date(data.updated_at)
  }
}