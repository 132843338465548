import { courseIdSelector } from '@atoms/course'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { trackingStateSelector } from '@atoms/tracking'
import { Tracking } from '@domain/trackings'
import { Typography, Box, Container } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { ROUTES } from '@routes/app-routes'
import { getFixationExerciseOverview } from '@services/fixation-exercise'
import { tracking } from '@services/tracking'
import { useCallback, useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import useStyles from './styles'

interface CardExercicioProps {
  key: number
  text: string
  colorTag: 'primary' | 'secondary'
  variant: 'classroom' | 'course'
  containerStyle?: CSSProperties
  disciplineName: string
}

export function CardExercicio({
  text,
  colorTag,
  variant,
  containerStyle,
  disciplineName,
}: CardExercicioProps) {
  const props = {
    colorTag,
    variant,
  }
  const classes = useStyles(props)()
  const { courseId } = useRecoilValue(courseIdSelector)
  const trackingState = useRecoilValue(trackingStateSelector)
  const [{ disciplineId, overview }, setOverview] = useRecoilState(
    fixationExerciseOverState
  )

  const [isFetching, setIsFetching] = useState(false)
  const navigateTo = useNavigate()

  const contentRoute = () => {
    return generatePath(ROUTES.FIXATION_EXERCISES, {
      courseId: courseId.toString(),
      exerciseId: String(disciplineId),
    })
  }

  const redirectExercice = () => {
    navigateTo(contentRoute())
    Tracking.clicouAtividade(
      trackingState.data,
      tracking,
      disciplineName,
      'Exercício de Fixação'
    )
  }

  const getOverview = useCallback((id: number) => {
    setIsFetching(true)
    getFixationExerciseOverview(id)
      .then((response) =>
        setOverview((prev) => ({ ...prev, overview: response }))
      )
      .catch(() => {
        return null
      })
      .finally(() => {
        setIsFetching(false)
      })
  }, [])

  useEffect(() => {
    getOverview(disciplineId)
  }, [disciplineId])

  if (isFetching) return null

  if (!overview?.existeExercicio) {
    return null
  }

  return (
    <Container className={classes.container} style={containerStyle}>
      <Link onClick={redirectExercice} className={classes.link}>
        <Box className={classes.leftTag}></Box>
        <div>
          <Typography variant="body1" className={classes.nameExercise}>
            {text}
          </Typography>
          <Typography variant="caption" className={classes.nameCaption}>
            {`Tentativas realizadas: ${overview?.totalTentativas}`}
          </Typography>
        </div>
      </Link>
    </Container>
  )
}
