import httpClient from '@gateway/generalHttpRequest'
import type { CertificateConfigurationResponseData } from './adapter-certificate-configuration'
import { adapterCertificateConfiguration } from './adapter-certificate-configuration'

export const getCertificateConfiguration = async () => {
  const path = '/offers/api/v1/certificate_configuration'
  const response = await httpClient.get<CertificateConfigurationResponseData>(path)

  if(response.status === 404) {
    return null
  }

  const responseJson = await response.json()
  return adapterCertificateConfiguration(responseJson)
}