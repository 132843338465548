import { makeStyles } from '@material-ui/core/styles'

const contentWidth = 936

const useStyles = makeStyles((theme) => ({
  exemploButton: {
    border: '1px solid black',
    backgroundColor: theme.palette.primary.main,
  },
  Main: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 128px)',
    backgroundColor: '#F5F5F5',
    paddingTop: '64px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '86px',
    },
  },
  MainContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    minWidth: 320,
    margin: 'auto',
    minHeight: 'calc(100vh - 128px)',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
  Pages: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: contentWidth,
    margin: '56px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '16px',
      width: 'calc(100% - 32px)',
    },
  },
  displayMobileNone: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  contentArea: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minWidth: contentWidth,
    },
  },
  sheetTopPositioning: {
    position: 'fixed',
    right: `calc(50% - ${contentWidth / 2}px - 32px)`,
    zIndex: 1000,
  },
}))

export default useStyles
