import { Footer } from '@components/Footer'
import { Header } from '@components/Header'
import { Container } from '@material-ui/core'
import Erro500 from '@assets/images/erro500.png'
import Typography from '@material-ui/core/Typography'
import { FC } from 'react'
import { useStyles } from '../PageNotFound/styles'

export const Error500: FC = () => {
  const styles = useStyles()

  return (
    <>
      <Header title="" />
      <Container className={styles.container500}>
        <div className={styles.content500}>
          <div className={styles.container_text}>
            <Typography className={styles.error} variant="h4">
              Erro 500
            </Typography>
            <Typography className={styles.page_not_found}>
              Erro no servidor interno
            </Typography>
            <p className={styles.page_not_foundText}>
              Nossa, você chegou em uma hora difícil!
            </p>
            <div className={styles.textColor}>
              <p className={styles.text}>Nossos servidores estão fora do ar.</p>
              <p className={styles.text}>Em breve tudo estará sob controle!</p>
              <p className={styles.textReload}>
                Pode recarregar a página ou tentar mais tarde?
              </p>
            </div>
          </div>
        </div>
        <div className={styles.img}>
          <img
            className={styles.image}
            src={Erro500}
            alt="erro500"
            title="erro"
          />
        </div>
      </Container>
      <Footer />
    </>
  )
}
