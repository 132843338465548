import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    '@media screen and (max-width: 603px)': {
      marginTop: theme.typography.pxToRem(51)
    },
    marginTop: theme.typography.pxToRem(8)
  },
  warningsGroup: {
    borderLeft: '3px solid',
    borderColor: theme.palette.primary.light,
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(56),
    paddingLeft: theme.typography.pxToRem(12),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24),

    '& > .group': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.typography.pxToRem(9),

      '& > strong': {
        fontWeight: 700
      }
    }
  },
  initExerciseButton: {
    backgroundColor: '#5ECFB5',
    color: theme.palette.primary.main,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white
    }
  },
  callToAction: {
    marginBottom: theme.typography.pxToRem(20),
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(20)
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16)
  }
}))
