import { Typography } from '@material-ui/core'
import { FC } from 'react'
import useStylesDiscipline from '../../styles'
import useStyles from './styles'
import { Check } from '@material-ui/icons'
import { progressCourseState } from '@atoms/course/course-selector'
import { useRecoilState } from 'recoil'

export const CourseSummary: FC = () => {
  const classesDiscipline = useStylesDiscipline()
  const classes = useStyles()
  const [progressData] = useRecoilState(progressCourseState)

  return (
    <div className={classes.summary}>
      <Typography
        variant="h5"
        className={`${classesDiscipline.subtitleContent} ${classes.title}`}
      >
        Resumo do curso
      </Typography>
      <span className={classes.text}>
        Total de disciplinas: {progressData.totalDisiciplinas}
      </span>
      <div className={classes.progress}>
        <span className={classes.text}>
          <Check style={{ fontSize: 16, marginRight: '5.5px' }} />
          Aulas assistidas: {progressData.totalAulasConcluidas} de{' '}
          {progressData.totalAulas}
        </span>
        <span className={classes.text}>
          <Check style={{ fontSize: 16, marginRight: '5.5px' }} />
          Progresso total: {progressData.percentualConcluido}%
        </span>
      </div>
    </div>
  )
}
