import { FC, useEffect } from 'react'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import { ClassProvider } from '@contexts/class'
import { CourseContent } from './components/CourseContent'
import { ClassRoom } from './components/ClassRoom'
import { useStyles } from './styles'
import { Question } from './components/Question'
import { ContentWithLoader } from '@components/ContentWithLoader'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentClassSelector } from '@atoms/course'
import { ClassMaterial } from './components/ClassMaterial'
import { useRepositionZenDesk } from '@hooks/use-reposition-zendesk'
import { selectedTypeSelector } from '@atoms/guide-tour'

export const Class: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { isFetching } = useRecoilValue(currentClassSelector)
  const setTourType = useSetRecoilState(selectedTypeSelector)

  useRepositionZenDesk()

  useEffect(() => {
    setTourType((prev) => ({ ...prev, selectedType: 'salaDeAula' }))
  }, [setTourType])

  return (
    <ClassProvider>
      <ContentWithLoader isLoading={isFetching}>
        <div className={classes.container}>
          <Box className={classes.course}>
            <CourseContent />
          </Box>

          <Box className={classes.class}>
            <ClassRoom />

            {isDesktop && <Question className={classes.questionDesktop} />}

            <ClassMaterial />
          </Box>

          <Question className={classes.questionMobile} />
        </div>
      </ContentWithLoader>
    </ClassProvider>
  )
}
