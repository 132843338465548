import { useEffect } from 'react'

export const useRepositionZenDesk = () => {
  useEffect(() => {
    const widgetElement = document.querySelector('#launcher')
    widgetElement?.classList.add('repositionZenDesk')
    return () => {
      widgetElement?.classList.remove('repositionZenDesk')
    }
  }, [])
}

