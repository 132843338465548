import { ProgressoCurso } from '@domain/curso'

type RemoteProgressCount = {
  class_count: number
  progress_count: number
  progress_percentage: number
  subjects_count: number
}

const inbound = (remoteData: RemoteProgressCount): ProgressoCurso => {
  return {
    totalAulas: remoteData.class_count,
    totalAulasConcluidas: remoteData.progress_count,
    percentualConcluido: remoteData.progress_percentage,
    totalDisiciplinas: remoteData.subjects_count,
  }
}

export const CourseProgressAdapter = { inbound }
