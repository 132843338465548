import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './styles'
import { SubThemeAccordion } from '../SubThemeAccordion'
import { FC, useEffect, useState } from 'react'
import { ThemeProps } from '@domain/discipline'
import { useRecoilState, useRecoilValue } from 'recoil'
import { openNextClassExpandedState } from '@atoms/course/course-selector'
import { Tracking } from '@domain/trackings'
import { trackingStateSelector } from '@atoms/tracking'
import { tracking } from '@services/tracking'

type ThemeAccordionProps = ThemeProps

export const ThemeAccordion: FC<ThemeAccordionProps> = ({
  nameTheme,
  themeId,
  dataSubTheme,
  disciplineName,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [openNextClassExpanded] = useRecoilState(openNextClassExpandedState)
  const trackingState = useRecoilValue(trackingStateSelector)

  useEffect(() => {
    if (openNextClassExpanded.themeId === themeId) {
      setExpanded(true)
    }
  }, [openNextClassExpanded])

  const handleExpandedThemeAccordion = () => {
    Tracking.clicouTema(trackingState.data, tracking, disciplineName, nameTheme)
    setExpanded(!expanded)
  }

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} expanded={expanded}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => handleExpandedThemeAccordion()}
        >
          <Typography className={classes.heading}>
            <span className={classes.headingName}>{nameTheme}</span>
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          {dataSubTheme &&
            dataSubTheme.map((subTheme, index: number) => (
              <SubThemeAccordion
                key={index}
                idClass={subTheme.idClass}
                nameSubTheme={subTheme.nameSubTheme}
                time={subTheme.time}
                classStudentWatched={subTheme.classStudentWatched}
                dataMaterials={subTheme.dataMaterials}
                disciplineName={disciplineName}
                nameTheme={nameTheme}
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
