import { SalvarAlternativa } from '@domain/exercicio-fixacao'
import httpClient from '@gateway/generalHttpRequest'

export const saveLog: SalvarAlternativa = (params) => {
  const { disciplineId, exerciseId, alternativeId, attemptNumber } = params

  const payload = {
    select_exercise_alternative_id: alternativeId,
    attempt_number: attemptNumber,
  }

  return new Promise((resolve, reject) => {
    const path = `contents/api/v1/fixation_exercise/upsert_log/?subject_id=${disciplineId}&exercise_id=${exerciseId}`

    httpClient
      .post<void>(path, payload)
      .then((response) => response.json())
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}
