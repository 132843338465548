import { makeStyles } from '@material-ui/core/styles'

const useAppStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: 32,
  },

  title: {
    color: theme.palette.primary.dark,
    fontWeight: 400,
    fontSize: 34,
    letterSpacing: 0.25,
    lineHeight: '1em',
    padding: '0 0 8px',
    marginBottom: 24,
    borderBottom: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },

  subtitle: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.25,
    lineHeight: '1em',
    padding: '0',
    margin: '40px 0 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
}))

export default useAppStyles
