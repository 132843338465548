import { Apostilas, Disciplines } from '@domain/discipline'
import { atom } from 'recoil'
import { Curso, ProgressoCurso, MaterialComplementar } from '@domain/curso'

export type openNextClassExpanded = {
  disciplineId: number | null
  themeId: number | null
  subThemeId: number | null
}

export type CourseStateType = {
  /** Indica se está ocorrendo uma chamada a um serviço remoto */
  isFetching: boolean
  /** Indica os dados do curso */
  course: Curso
  /** Indica os dados da disciplina */
  discipline: Disciplines[]
  progress: ProgressoCurso
  /** informação do acordion que vai ficar aberto */
  openNextClassExpanded: openNextClassExpanded
  /** lista de materiais complementar do curso */
  materiaisComplementar: MaterialComplementar[]
  /** lista de simulados do curso */
  simulados: MaterialComplementar[]
  /** usada para como observavel para atualizar buscas de progress/proxima aula */
  classProgressRequestId: number
  /** lista de apostilas da disciplina */
  apostila: Record<string, Apostilas>
}

export const courseState = atom<CourseStateType>({
  key: 'courseState',
  default: {
    isFetching: true,
    classProgressRequestId: 0,
    course: {
      categoria: null,
      subcategoria: null,
      id: 0,
      name: '',
      classCurrent: {
        classTitle: '',
        classId: 0,
        classFullname: '',
        classRelationshipId: 0,
        isFirstClass: false,
      },
      secondPhaseExercise: {
        dataFinal: '',
        dataInicial: '',
        linkForms: '',
        answerSheet: '',
        answerSheetDate: '',
        textEnunciated: '',
        title: '',
        type: '',
      },
      secondPhaseExerciseExists: false,
      secondPhaseExerciseCount: 0,
    },
    discipline: [
      {
        disciplineName: '',
        disciplineId: 0,
        numberOfClassesTotal: 0,
        numberOfClassesCompleted: 0,
        dataTheme: [],
      },
    ],
    openNextClassExpanded: {
      disciplineId: null,
      themeId: null,
      subThemeId: null,
    },
    progress: {
      totalAulas: 0,
      totalAulasConcluidas: 0,
      percentualConcluido: 0,
      totalDisiciplinas: 0,
    },
    materiaisComplementar: [],
    simulados: [],
    apostila: {},
  },
})
