import { ProgressoCurso } from '@domain/curso'
import httpClient from '../../gateway/generalHttpRequest'
import { CourseProgressAdapter } from './adapter-progress'
import { getRequestResponseHandler } from '@services/utils/response-handlers'

export const getProgress = (courseId: number): Promise<ProgressoCurso> => {
  return new Promise((resolve, reject) => {
    const path = `offers/api/v1/class_progress_user/${courseId}`

    httpClient
      .get<ProgressoCurso>(path)
      .then((response) => getRequestResponseHandler(response))
      .then((data) => resolve(CourseProgressAdapter.inbound(data)))
      .catch((err) => reject(err))
  })
}
