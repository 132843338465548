import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  materials: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '@media (max-width: 960px)': {
      marginBottom: '56px',
    },
  },
  title: {
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
  gridColumns: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '@media (max-width: 960px)': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '24px',
    },
    '@media (max-width: 870px)': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '40px',
      justifyItems: 'center',
    },
    '@media (max-width: 599px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  },
}))

export default useStyles
