/* eslint-disable @typescript-eslint/no-unused-vars */
import { TrackingData, trackingDataSlice, TrackingTypes } from './tracking-data'
import { TrackingTask } from './tracking-task'

export const clicouMeusCertificados = (
  state: TrackingData,
  service: TrackingTask
) => {
  const {
    id_course,
    name_course,
    nome_categoria,
    nome_subcategoria,
    ...context
  } = trackingDataSlice(state)

  const payload = {
    event: TrackingTypes.CLICOU_MEUS_CERTIFICADOS,
    event_text: TrackingTypes.CLICOU_MEUS_CERTIFICADOS,
    user_id: state.user_id,
    ...context,
  }

  service(payload)
    .then(() => null)
    .catch(() => null)
}
