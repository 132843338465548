import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '@routes/app-routes'

import { Curso } from '@pages/Curso'
import { AccessDenied } from '@pages/AccessDenied'
import { PageNotFound } from '@pages/PageNotFound'
import { Materiais } from '@pages/Materiais'
import { Exercicios } from '@pages/Exercicios'
import { Class } from '@pages/Class'
import { Discipline } from '@pages/Discipline'
import { ExerciciosFixacao } from '@pages/ExerciciosFixacao'
import { CourseDataLayer } from '@pages/CourseDataLayer'
import { SimpleLayout } from '../layouts/simple'
import { DefaultLayout } from '../layouts/default'
import { FileDownload } from '@pages/FileDownload'
import { Error500 } from '@pages/Error500/erro500'
import { Suspense, useMemo } from 'react'
import { ShepherdTour } from 'react-shepherd'
import { selectedStepsSelector } from '@atoms/guide-tour'
import { useRecoilValue } from 'recoil'

const tourOptions = {
  defaultStepOptions: {
    scrollTo: true,
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

export const AppRoutes = () => {
  const steps = useRecoilValue(selectedStepsSelector)

  const guideTourMemo = useMemo(() => {
    return steps.map((step) => ({ ...step }))
  }, [steps])

  return (
    <ShepherdTour steps={guideTourMemo} tourOptions={tourOptions}>
      <Routes>
        <Route path={ROUTES.FILE_DOWNLOAD} element={<FileDownload />} />

        <Route path={ROUTES.HOME} element={<CourseDataLayer />}>
          <Route path={ROUTES.HOME} element={<DefaultLayout />}>
            <Route path={ROUTES.HOME} element={<Curso />} />
            <Route path={ROUTES.ACCESS_DENIED} element={<AccessDenied />} />
            <Route path={ROUTES.CLASS} element={<Class />} />
            <Route path={ROUTES.DASHBOARD_COURSE} element={<Curso />} />
            <Route path={ROUTES.DISCIPLINE} element={<Discipline />} />
            <Route path={ROUTES.EXERCISES} element={<Exercicios />} />
            <Route path={ROUTES.MATERIALS_ID} element={<Materiais />} />
          </Route>

          <Route path={ROUTES.FIXATION_EXERCISES} element={<SimpleLayout />}>
            <Route
              path={ROUTES.FIXATION_EXERCISES}
              element={
                <Suspense fallback={null}>
                  <ExerciciosFixacao />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
        <Route path={ROUTES.INTERNAL_ERROR} element={<Error500 />} />
      </Routes>
    </ShepherdTour>
  )
}
