import { atom } from 'recoil'
import { ShepherdOptionsWithType } from 'react-shepherd'

export type GuideTourType =
  | 'home'
  | 'homeSegundaFase'
  | 'disciplina'
  | 'salaDeAula'

type GuideTourStateType = {
  steps: Record<GuideTourType, Array<ShepherdOptionsWithType>>
  selectedType: GuideTourType
}

export const guideTourState = atom<GuideTourStateType>({
  key: 'guideTourState',
  default: {
    selectedType: 'home',
    steps: {
      home: [
        {
          id: 'tour-home-passo1-material-complementar',
          attachTo: {
            element: '.tour-home-passo1-material-complementar',
            on: 'bottom',
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              classes: 'shepherd-button-primary',
              text: 'PRÓXIMO',
              type: 'next',
            },
          ],
          classes: 'shepherdGuideTour',
          highlightClass: 'highlightClass',
          title: '<p class="iconeGuide">i</p>Materiais complementares',
          text: 'Aqui você encontra materiais criados para dar <b class="textGuide">suporte e complementar seu processo de aprendizagem:</b> como técnicas de estudo e atualizações.',
          canClickTarget: true,
          when: {
            show: () => {
              console.log('mostrar tour-home-passo1-material-complementar')
            },
            hide: () => {
              console.log(
                'esconder o passo tour-home-passo1-material-complementar'
              )
            },
          },
        },
      ],
      homeSegundaFase: [
        {
          id: 'tour-home-passo1-material-complementar',
          attachTo: {
            element: '.tour-home-passo1-material-complementar',
            on: 'bottom',
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              classes: 'shepherd-button-primary',
              text: 'PRÓXIMO',
              type: 'next',
            },
          ],
          classes: 'shepherdGuideTour',
          highlightClass: 'highlightClass',
          title: '<p class="iconeGuide">i</p>Materiais complementares',
          text: 'Aqui você encontra materiais criados para dar <b class="textGuide">suporte e complementar seu processo de aprendizagem:</b> como técnicas de estudo e atualizações.',
          canClickTarget: true,
          when: {
            show: () => {
              console.log('mostrar tour-home-passo1-material-complementar')
            },
            hide: () => {
              console.log(
                'esconder o passo tour-home-passo1-material-complementar'
              )
            },
          },
        },
        {
          id: 'tour-home-passo-2-fase-OAB',
          attachTo: {
            element: '.tour-home-passo-2-fase-OAB',
            on: 'right',
          },
          title: '<p class="iconeGuide">i</p>Exercícios OAB 2ª Fase',
          text: 'Exercícios OAB 2ª Fase são atividades que visam a sua preparação para a Segunda Fase da OAB. Assim, você poderá <b class="textGuide">realizar questões e peças processuais </b>que serão corrigidas por professores especializados.',
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: 'ANTERIOR',
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: 'FECHAR',
              type: 'cancel',
            },
          ],
          when: {
            show: () => {
              console.log('mostrar tour-home-passo-2-fase-OAB')
            },
            hide: () => {
              console.log('esconder tour-home-passo-2-fase-OAB')
            },
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          canClickTarget: false,
          classes: 'shepherdGuideTour',
        },
      ],
      disciplina: [
        {
          id: 'tour-disciplina-passo-1',
          attachTo: {
            element: '.tour-disciplina-passo-1',
            on: 'bottom',
          },
          title: '<p class="iconeGuide">i</p>Materiais do curso',
          text: 'São materiais que contemplam todas as disciplinas e assuntos <b class="textGuide">tratados durante todo o seu curso</b>.',
          buttons: [
            {
              classes: 'shepherd-button-primary',
              text: 'FECHAR',
              type: 'cancel',
            },
          ],
          when: {
            show: () => {
              console.log('mostrar tour-disciplina-passo-1 ')
            },
            hide: () => {
              console.log('esconder tour-disciplina-passo-1')
            },
          },
          cancelIcon: { enabled: true },
          modalOverlayOpeningPadding: 4,
          canClickTarget: false,
          classes: 'shepherdGuideTour',
          highlightClass: 'highlight',
          scrollTo: { behavior: 'smooth', block: 'center' },
        },
      ],
      salaDeAula: [
        {
          id: 'tour-consumo-materiais-aula-passo-1',
          attachTo: {
            element: '.tour-consumo-materiais-aula-passo-1',
            on: 'right-start',
          },
          title: '<p class="iconeGuide">i</p>Materiais da aula',
          text: 'São materiais referentes <b class="textGuide">apenas ao assunto desta aula</b>. Aqui você poderá encontrar os slides, anotações do professor, dentre outros.',
          buttons: [
            {
              classes: 'shepherd-button-primary',
              text: 'PRÓXIMO',
              type: 'next',
            },
          ],
          when: {
            show: () => {
              console.log('mostrar tour-consumo-materiais-aula-passo-1')
            },
            hide: () => {
              console.log('esconder tour-consumo-materiais-aula-passo-1')
            },
          },
          cancelIcon: { enabled: true },
          canClickTarget: false,
          classes: 'shepherdGuideTour',
          highlightClass: 'highlight',
          scrollTo: { behavior: 'smooth', block: 'center' },
        },
        {
          id: 'tour-consumo-materiais-disciplina-passo-2',
          attachTo: {
            element: '.tour-consumo-materiais-disciplina-passo-2',
            on: 'right-start',
          },
          title: '<p class="iconeGuide">i</p>Materiais da disciplina',
          text: 'São materiais que contemplam os <b class="textGuide">assuntos específicos da disciplina</b>.',
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: 'ANTERIOR',
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: 'FECHAR',
              type: 'cancel',
            },
          ],
          when: {
            show: () => {
              console.log('mostrar  tour-consumo-materiais-disciplina-passo-2')
            },
            hide: () => {
              console.log('esconder tour-consumo-materiais-disciplina-passo-2')
            },
          },
          cancelIcon: { enabled: true },
          canClickTarget: false,
          classes: 'shepherdGuideTour',
          highlightClass: 'highlight',
          scrollTo: { behavior: 'smooth', block: 'center' },
        },
      ],
    },
  },
})
