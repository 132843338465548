import httpClient from '@gateway/generalHttpRequest'
import type { CertificateConfigurationResponseData } from './adapter-certificate-configuration'
import { adapterCertificateConfiguration } from './adapter-certificate-configuration'

type CreateCertificateConfigurationParams = {
  name: string
  document: string
}

export const createCertificateConfiguration = 
  async (data: CreateCertificateConfigurationParams) => 
  {
    const path = '/offers/api/v1/certificate_configuration'
    const payload = { cpf: data.document, name: data.name }
    const response = await httpClient.post<CertificateConfigurationResponseData>(path, payload)
    const responseJson = await response.json()
    return adapterCertificateConfiguration(responseJson)
  }