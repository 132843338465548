import { Typography } from '@material-ui/core'
import { FC } from 'react'
import useStylesDiscipline from '../../styles'
import { CourseMaterialsRedirector } from './CourseMaterialRedirector'
import useStyles from './styles'

export const CourseMaterials: FC = () => {
  const classesDiscipline = useStylesDiscipline()
  const classes = useStyles()

  return (
    <div className={classes.materials}>
      <Typography
        variant="h5"
        className={`${classesDiscipline.subtitleContent} ${classes.title} tour-disciplina-passo-1`}
      >
        <div className={'tour-disciplina-passo-1'}>Materiais do curso</div>
      </Typography>
      <div className={classes.gridColumns}>
        <CourseMaterialsRedirector />
      </div>
    </div>
  )
}
