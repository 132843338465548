import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  customGrid: {
    display: 'flex',
    alignItems: 'strech',
    justifyContent: 'space-beetween',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridLeft: {
    width: '100%',
    marginRight: 0,

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 16,
    },
  },
  gridRight: {
    width: '100%',
    maxWidth: 263,
    marginLeft: 16,

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginLeft: 0,
    },
  },
}))

export default useStyles
