import { FC, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { SheetTop } from '@equipe-ninja/saraiva-ui'
import { Header } from '../../shared/Header'
import { Footer } from '../../shared/Footer'
import Link from '@material-ui/core/Link'
import LocalLibraryRoundedIcon from '@material-ui/icons/LocalLibraryRounded'
import { DialogBibliotecaVirtual } from '@pages/DialogBibliotecaVirtual'
import useStyles from './styles'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { redirecionarParaBibliotecaVirtual } from '@domain/redirecionamentos'
import { useRecoilState } from 'recoil'
import { generalState } from '@atoms/general'

export const DefaultLayout: FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const [state, setState] = useRecoilState(generalState)
  const header = useRef<HTMLHeadingElement>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [widthHeader, setWidthHeader] = useState(
    header.current?.clientWidth || 0
  )

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidthHeader(header.current?.clientWidth || 0)
    })
  }, [])

  const handleCreatePageName = () => {
    const path = location.pathname.replace('/', '')
    let pageName = path.split('/')[0]
    pageName = pageName.replaceAll('-', ' ')

    return pageName
  }

  const onClickBibliotecaVirtual = () => {
    redirecionarParaBibliotecaVirtual()
  }

  return (
    <>
      {location.pathname.includes('/aula') ? (
        <>
          {(widthHeader > 960 || widthHeader === 0) && (
            <Header title={handleCreatePageName()} />
          )}
          <main className={classes.MainContent} ref={header}>
            <Outlet />
          </main>

          <DialogBibliotecaVirtual
            open={state.isLibraryDialogOpen}
            onClose={() =>
              setState((prev) => ({ ...prev, isLibraryDialogOpen: false }))
            }
          />
        </>
      ) : (
        <>
          <Header title={handleCreatePageName()} />

          <main className={classes.Main}>
            <div className={classes.contentArea}>
              {false && !isMobile && (
                <div className={classes.sheetTopPositioning}>
                  <SheetTop
                    label="Biblioteca Virtual"
                    color="primaryLight"
                    startIcon={<LocalLibraryRoundedIcon />}
                    accessbilityAction={onClickBibliotecaVirtual}
                    accessbilityLabel="Acessar a biblioteca Virtual"
                  >
                    <Link
                      style={{ marginRight: 4 }}
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          isLibraryDialogOpen: true,
                        }))
                      }
                    >
                      Clique aqui
                    </Link>{' '}
                    para acessar
                  </SheetTop>
                </div>
              )}

              <div className={classes.Pages}>
                <Outlet />
              </div>
            </div>
          </main>

          <DialogBibliotecaVirtual
            open={state.isLibraryDialogOpen}
            onClose={() =>
              setState((prev) => ({ ...prev, isLibraryDialogOpen: false }))
            }
          />

          <Footer />
        </>
      )}
    </>
  )
}
