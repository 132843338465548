import { FC, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Container, Tooltip, Typography } from '@material-ui/core'
import { Button } from '@equipe-ninja/saraiva-ui'
import { Spellcheck } from '@material-ui/icons'

import { TodasQuestoes } from './TodasQuestoes'
import { Questao } from './Questao'

import { useStyles } from './styles'
import { RedoExerciseDialog } from '../RedoExerciseDialog'
import classNames from 'classnames'
import { questionIndexSelector } from '@atoms/fixation-exercise'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { finishAttempt } from '@services/fixation-exercise'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { trackingStateSelector } from '@atoms/tracking'

export const ExerciciosExecucao: FC<{ title: string }> = ({ title }) => {
  const styles = useStyles()

  const [{ questions, questionIndex, showAllAnswers, attempt }, setState] =
    useRecoilState(fixationExerciseOverState)

  const [activeQuestionNumber, setActiveQuestionNumber] = useRecoilState(
    questionIndexSelector
  )

  const trackingState = useRecoilValue(trackingStateSelector)

  const [isRedoExerciseDialogOpen, setIsRedoExerciseDialogOpen] =
    useState(false)

  const correctAnsweredQuestionsCount = questions.filter(
    (question) => question.respostaQuestao === question.alternativaCorretaId
  ).length

  const correctAnswersPercentage =
    (correctAnsweredQuestionsCount * 100) / questions.length

  const hasAnsweredAllQuestions = questions.every(
    (question) => !!question.respostaQuestao
  )

  const isLastQuestion = questions.length - 1 === questionIndex

  const isFirstQuestion = questionIndex === 0

  function handleBackButtonClick() {
    setActiveQuestionNumber((prev) => prev - 1)
  }

  function handleNextButtonClick() {
    setActiveQuestionNumber((prev) => prev + 1)
  }

  function handleTemplateVisualization() {
    setState((prev) => ({ ...prev, showAllAnswers: true, questionIndex: 0 }))
  }

  function handleRedoExercise() {
    setIsRedoExerciseDialogOpen(true)

    Tracking.clicouRefazerAtividade(
      trackingState.data,
      tracking,
      title,
      'Exercício de Fixação'
    )
  }

  function finishExercise() {
    if (!attempt?.tentativaId) return

    if (!attempt.emProgresso) {
      setState((prev) => ({ ...prev, isExecutingExercise: false }))
      return
    }
    setState((prev) => ({ ...prev, isExecutingLog: true }))
    finishAttempt(attempt?.tentativaId)
      .then(() => {
        setState((prev) => ({
          ...prev,
          resetObservable: Date.now(),
          showAllAnswers: false,
          showCurrentAnswer: false,
          isExecutingExercise: false,
        }))
      })
      .catch(() => {
        return null
      })
      .finally(() => {
        setState((prev) => ({ ...prev, isExecutingLog: false }))
        Tracking.clicouFinalizarAtividade(
          trackingState.data,
          tracking,
          title,
          'Exercício de Fixação'
        )
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeQuestionNumber, showAllAnswers])

  return (
    <>
      {showAllAnswers && (
        <Container>
          <Typography className={styles.templateVisualizationMessage}>
            <Spellcheck />

            <span>
              Parabéns, você finalizou o exercício! Você acertou{' '}
              {correctAnswersPercentage.toFixed(2)}% das questões (
              {correctAnsweredQuestionsCount} de {questions.length}).
            </span>
          </Typography>
        </Container>
      )}

      <Container
        className={classNames(styles.container, {
          'is-template-visualization': showAllAnswers,
        })}
      >
        <TodasQuestoes />

        <Questao />
      </Container>

      <Container className={styles.actualQuestionCallToAction}>
        {showAllAnswers ? (
          <>
            <Button color="primary" onClick={handleRedoExercise}>
              Refazer Exercício
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={finishExercise}
            >
              Finalizar Exercício
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={isFirstQuestion}
              color="primary"
              onClick={handleBackButtonClick}
            >
              Anterior
            </Button>

            {isLastQuestion ? (
              <>
                {hasAnsweredAllQuestions ? (
                  <Button
                    color="secondary"
                    onClick={handleTemplateVisualization}
                    variant="contained"
                    className={styles.templateButton}
                  >
                    Ver Gabarito
                  </Button>
                ) : (
                  <Tooltip
                    placement="top"
                    title="Para ver o gabarito você deve responder todas as questões"
                  >
                    <div>
                      <Button
                        color="secondary"
                        onClick={() => {
                          //
                        }}
                        variant="contained"
                        disabled
                      >
                        Ver Gabarito
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </>
            ) : (
              <Button
                color="primary"
                onClick={handleNextButtonClick}
                variant="contained"
              >
                Proximo
              </Button>
            )}
          </>
        )}
      </Container>

      <RedoExerciseDialog
        label="Atenção: Ao refazer o exercício, você não terá mais acesso à tentativa anterior."
        isOpen={isRedoExerciseDialogOpen}
        onClose={() => setIsRedoExerciseDialogOpen(false)}
        title={title}
      />
    </>
  )
}
