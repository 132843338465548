import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 32,
    marginBottom: 'calc(72px - 56px)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      marginTop: 24,
    },
    '& > *': {
      flexBasis: 'calc((100% - 48px) / 3)',
      flexShrink: 0,
      flexGrow: 0,

      [theme.breakpoints.down('sm')]: {
        flexBasis: 'calc((100% - 24px) / 2)',
      },

      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%',
      },
    },
  },
}))

export default useStyles
