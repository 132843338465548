import { Tab, Tabs, Typography } from '@material-ui/core'
import { FC, MouseEventHandler, useEffect, useState } from 'react'
import useAppStyles from '@components/AppStyles'
import useStyles from './styles'
import { BackButton } from '@components/BackButton'
import { ArrowForward } from '@material-ui/icons'
import { CourseMaterials } from './components/CourseMaterials'
import { CourseSummary } from './components/CourseSummary'
import { SimpleAccordion } from './components/SimpleAccordion'
import { Disciplines } from '@domain/discipline'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { disciplineDetailState } from '@atoms/course/course-selector'
import { currentClassSelector } from '@atoms/course'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '@routes/app-routes'
import { ContentWithLoader } from '@components/ContentWithLoader'
import { selectedTypeSelector } from '@atoms/guide-tour'

export const Discipline: FC = () => {
  const globalClasses = useAppStyles()
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const { courseId } = useParams()
  const disciplinesData = useRecoilValue(disciplineDetailState)
  const currentClassState = useRecoilValue(currentClassSelector)
  const navigateTo = useNavigate()
  const setTourType = useSetRecoilState(selectedTypeSelector)

  const renderAcoordion = () => {
    return (
      disciplinesData &&
      disciplinesData.map((dicipline: Disciplines) => {
        return (
          <SimpleAccordion
            key={dicipline.disciplineId}
            disciplineId={dicipline.disciplineId}
            disciplineName={dicipline.disciplineName}
            numberOfClassesTotal={dicipline.numberOfClassesTotal}
            numberOfClassesCompleted={dicipline.numberOfClassesCompleted}
            dataTheme={dicipline.dataTheme}
          />
        )
      })
    )
  }

  const backButtonRoute = () => {
    if (courseId) {
      return generatePath(ROUTES.DASHBOARD_COURSE, { courseId: courseId })
    }

    return ''
  }

  const onClickContinuar: MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault()

    navigateTo(
      generatePath(ROUTES.CLASS, {
        courseId: currentClassState.courseId.toString(),
        classId: currentClassState.classId.toString(),
      })
    )
  }

  useEffect(() => {
    setTourType((prev) => ({ ...prev, selectedType: 'disciplina' }))
  }, [setTourType])

  return (
    <ContentWithLoader isLoading={currentClassState.isFetching}>
      <div className={classes.container}>
        <BackButton link={backButtonRoute()} />
        <Typography
          variant="h4"
          className={`${globalClasses.title} ${classes.header}`}
        >
          <span className={classes.titlePage}>Disciplinas</span>
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={(e, value) => setTabValue(value)}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Disciplinas" value={0} />
            <Tab label="materiais do curso" value={1} />
            <Tab label="Resumo do curso" value={2} />
            <Typography className={classes.marginLeftAuto} />
          </Tabs>

          <button className={classes.actionHeader} onClick={onClickContinuar}>
            <div className={classes.columnTextAction}>
              <span className={classes.textAction}>
                {currentClassState.classTitle}
              </span>
              <span className={classes.labelAction}>Continuar Aula</span>
            </div>
            <ArrowForward />
          </button>
        </Typography>

        <main className={classes.mainDiscipline}>
          <div className={classes.columnAcordion}>{renderAcoordion()}</div>
          <div className={classes.columnContent}>
            <CourseSummary />
            <CourseMaterials />
          </div>
        </main>

        <main className={classes.mainDisciplineMobile}>
          {tabValue === 0 && (
            <div className={classes.columnAcordion}>
              <button
                className={classes.actionHeaderSm}
                onClick={onClickContinuar}
              >
                <div className={classes.columnTextAction}>
                  <span className={classes.textAction}>
                    {currentClassState.classTitle}
                  </span>
                  <span className={classes.labelAction}>Continuar Aula</span>
                </div>
                <ArrowForward />
              </button>
              {renderAcoordion()}
            </div>
          )}
          {tabValue !== 0 && (
            <div className={classes.columnContent}>
              {tabValue === 1 && <CourseMaterials />}
              {tabValue === 2 && <CourseSummary />}
            </div>
          )}
        </main>
      </div>
    </ContentWithLoader>
  )
}
