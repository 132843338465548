import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '40px 56px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px 16px',
    },
  },
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 44,
  },
  insertIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  card: {
    padding: 16,
    '&:last-child': {
      color: 'red',
      padding: '16px 0px 0px 0px',
    },
  },
  name: {
    padding: 12,
  },
  materials: {
    margin: 'auto',
    width: '100%',
  },
}))

export default useStyles
