import { FC } from 'react'
import classNames from 'classnames'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Typography } from '@material-ui/core'

import { useStyles } from './styles'
import { fixationExerciseOverState } from '@atoms/fixation-exercise/fixation-exercise-stateOver'
import { questionIndexSelector } from '@atoms/fixation-exercise'

export const TodasQuestoes: FC = () => {
  const styles = useStyles()

  const { questions, questionIndex, showAllAnswers } = useRecoilValue(
    fixationExerciseOverState
  )

  const setActiveQuestionNumber = useSetRecoilState(questionIndexSelector)

  const computeQuestionCssClass = (
    index: number,
    userAnswer: number | null,
    correctAnswer: number
  ) => {
    return classNames(styles.questionItem, {
      active: !showAllAnswers && questionIndex === index,
      answered: !showAllAnswers && !!userAnswer,
      correct: showAllAnswers && correctAnswer === userAnswer,
      incorrect: showAllAnswers && correctAnswer !== userAnswer,
    })
  }

  return (
    <div className={styles.card}>
      <div className={styles.questionHeader}>
        <Typography className={styles.questionsTitle}>
          Todas as questões
        </Typography>
      </div>

      <div className={styles.questionsContainer}>
        {questions.map((question, index) => {
          const { alternativaCorretaId, respostaQuestao } = question

          return (
            <span
              key={question.questaoId}
              onClick={() => setActiveQuestionNumber(index)}
              className={computeQuestionCssClass(
                index,
                respostaQuestao,
                alternativaCorretaId
              )}
            >
              {index + 1}
            </span>
          )
        })}
      </div>
    </div>
  )
}
