import { FC } from 'react'
import useStyles from './styles'
import { Dialog, Button } from '@equipe-ninja/saraiva-ui'
import Typography from '@material-ui/core/Typography'
import { Link, useMediaQuery, useTheme } from '@material-ui/core'
import { redirecionarParaBibliotecaVirtual } from '@domain/redirecionamentos'

type DialogBibliotecaVirtualProps = {
  open: boolean
  onClose: () => void
}

export const DialogBibliotecaVirtual: FC<DialogBibliotecaVirtualProps> = (
  props
) => {
  const { open, onClose } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  const onClickButton = () => {
    redirecionarParaBibliotecaVirtual()
  }

  if (!open) return null

  return (
    <Dialog
      handleConfirm={() => onClose()}
      title="Biblioteca Virtual"
      primaryButton="fechar"
      confirmWithoutCheck={() => null}
      handleClose={() => onClose()}
      size="lg"
      open={open}
    >
      <div className={classes.dialogContent}>
        <div>
          <Typography variant="body2">
            A LFG garante a você acesso a um grande acervo de livros pela
            Biblioteca Virtual. Para logar siga os passos:
          </Typography>

          <div className={classes.stepsList}>
            <div>
              <Typography variant="body2">
                1. No site da Biblioteca Virtual, acesse{' '}
                <strong>{'"Clique aqui para efetuar login."'}</strong>
              </Typography>
            </div>

            <div>
              <Typography variant="body2">
                2. Selecione a opção <strong>Login Acadêmico.</strong>
              </Typography>
            </div>

            <div>
              <Typography variant="body2">
                3. Utilize como login o seu <strong>CPF</strong> cadastrado na
                LFG.
              </Typography>
            </div>

            <div>
              <Typography variant="body2">
                4. Utilize a sua <strong>senha</strong> já cadastrada na conta
                LFG.
              </Typography>
            </div>
          </div>

          <Button
            onClick={onClickButton}
            color="primary"
            variant="contained"
            fullWidth={isMobile}
            className={classes.marginTop24}
          >
            Ir para a biblioteca
          </Button>

          <div className={classes.captions}>
            <Typography variant="caption">
              Está com dúvidas? Cheque o serviço de{' '}
              <Link
                target="_blank"
                href="https://saraivaaprova.zendesk.com/hc/pt-br"
                className={classes.underlinedText}
              >
                suporte
              </Link>{' '}
              ou nosso{' '}
              <Link
                target="_blank"
                href="https://saraivaaprova.zendesk.com/hc/pt-br"
                className={classes.underlinedText}
              >
                FAQ
              </Link>{' '}
              para mais informações.
            </Typography>
          </div>
        </div>

        <div>
          <img
            className={classes.imageEffect}
            src="/assets/images/biblioteca_virtual.jpg"
            alt="imagem ilustrativa do site da biblioteca virtual"
          />
        </div>
      </div>
    </Dialog>
  )
}
