import { atom } from 'recoil'

export type CertificateConfiguration = {
  id: number
  cpf: string
  name: string
}

type CertificateState = {
  certificateConfiguration: CertificateConfiguration | null
  isFetching: boolean
}

export const certificateState = atom<CertificateState>({
  key: 'certificateState',
  default: {
    certificateConfiguration: null,
    isFetching: false
  }
})
