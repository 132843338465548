import { makeStyles } from '@material-ui/core/styles'
import LFGFaded from '@images/lfg-faded.svg'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: '24px 16px 24px 24px',
    border: `1px solid ${theme.palette.divider}`,

    height: '100%',
    minHeight: '272px',

    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    backgroundImage: `url(${LFGFaded})`,

    backgroundRepeat: 'no-repeat',
    backgroundPosition: '90% 80%',
    backgroundSize: '100px',

    '&:hover': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
  },

  purpleBorder: {
    position: 'absolute',
    width: 6,
    height: '100%',
    top: 0,
    left: 0,
    right: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '8px 0px 0px 8px'
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    margin: '16px 0',
    lineHeight: '24px'
  },

  linkContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  label: {
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.5,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase'
  }
}))

export default useStyles
