import { courseState } from '@atoms/course/course-state'
import { selector } from 'recoil'
import { guideTourState, GuideTourType } from './guide-tour-state'

export const selectedStepsSelector = selector({
  key: 'selectedStepsSelector',
  get: ({ get }) => {
    const existSecondPhase = get(courseState).course.secondPhaseExerciseExists

    const selectedType = get(guideTourState).selectedType

    let computedSelectedType: GuideTourType = selectedType

    if (selectedType === 'home' && existSecondPhase) {
      computedSelectedType = 'homeSegundaFase'
    }

    return get(guideTourState).steps[computedSelectedType]
  },
})
