import { useState, useEffect, useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ChavesArmazenamentoLocal } from '@domain/armazenamento-local'
import { GeneralContext } from '@contexts/GeneralContext'
import { getCourse } from '@services/course/get-course'
import { getProgress } from '@services/progressCourse/get-progress'
import { courseDataSelector } from '@atoms/course'
import { getMaterials } from '@services/complementary-material/get-complemantary-material'
import { getDisciplines } from '@services/discipline/get-disciplines'
import { buscarAulaPorIdNasDisciplinas, Disciplines } from '@domain/discipline'
import { useAuth } from '@contexts/auth'
import {
  ErroAcessoCursoNaoAutorizado,
  ErroInternoServidor,
} from '@domain/erros'
import { parseJwt } from '../auth'
import { parseRemoteDateTimeFromUTC } from '@utils/remote-datetime-utils'
import { redirecionarParaEcommerce } from '@domain/geral'
import { openNextClassExpanded } from '@domain/curso/curso'
import { ROUTES } from '@routes/app-routes'

type CoursePromiseLike<T> = (id: number) => Promise<T>
import { getUserIp } from '@services/externals'
import { trackingStateSelector } from '@atoms/tracking'

export const useFetchCourseData = () => {
  const navigateTo = useNavigate()

  function genericRequest<T>(
    id: number,
    service: CoursePromiseLike<T>,
    message: string
  ): Promise<T> {
    return service(id).catch((erro) => {
      if (erro instanceof ErroAcessoCursoNaoAutorizado) {
        throw new ErroAcessoCursoNaoAutorizado()
      }
      if (erro instanceof ErroInternoServidor) {
        navigateTo(ROUTES.INTERNAL_ERROR)
      }

      throw new Error(message)
    })
  }

  const { courseId: courseIdParam } = useParams()
  const { setErrorMessage } = useContext(GeneralContext)
  const [courseData, setCourseData] = useRecoilState(courseDataSelector)
  const setTrakingState = useSetRecoilState(trackingStateSelector)
  const { sessionToken } = useAuth()
  const { expiration_at } = parseJwt(String(sessionToken))
  const expirationDate = parseRemoteDateTimeFromUTC(expiration_at) as Date
  const [courseId] = useState(() => {
    const id = courseIdParam && parseInt(courseIdParam)
    const storedId = localStorage.getItem(
      ChavesArmazenamentoLocal.ULTIMO_CURSO_ACESSADO
    )
    const storedIdAsNumberOrNull = storedId && parseInt(storedId)

    if (!id) {
      if (!storedIdAsNumberOrNull) {
        setErrorMessage('Erro de acesso, voce será redirecionado(a)')

        setTimeout(() => {
          sessionToken &&
            redirecionarParaEcommerce(sessionToken, expirationDate)
        }, 5000)

        return
      }

      return storedIdAsNumberOrNull
    }

    localStorage.setItem(
      ChavesArmazenamentoLocal.ULTIMO_CURSO_ACESSADO,
      id.toString()
    )

    return id
  })

  const computeNextOpenState = (
    disciplines: Disciplines[],
    id: number
  ): openNextClassExpanded => {
    const [disciplineId, themeId, subThemeId] = buscarAulaPorIdNasDisciplinas(
      disciplines,
      id
    ) as [number, number, number]

    return { disciplineId, subThemeId, themeId }
  }

  const fetchCouseData = useCallback((id) => {
    const courseDetailsPromise = genericRequest(
      id,
      getCourse,
      'Falha ao buscar as informações do curso'
    )

    const courseProgressPromise = genericRequest(
      id,
      getProgress,
      'Falha ao buscar as informações do progresso do curso'
    )

    const courseContentPromise = genericRequest(
      id,
      getMaterials,
      'Falha ao buscar os materiais complementares'
    )

    const courseSubjectsPromise = genericRequest(
      id,
      getDisciplines,
      'Falha ao buscar as disciplinas'
    )

    setCourseData((prev) => ({ ...prev, isFetching: true }))

    courseDetailsPromise
      .then((course) => {
        Promise.all([
          courseProgressPromise,
          courseContentPromise,
          courseSubjectsPromise,
          getUserIp(),
        ])
          .then((responses) => {
            const [
              progress,
              { materialComplementar, simulados },
              { discipline, apostila },
              ip,
            ] = responses

            const openNextClassExpanded = computeNextOpenState(
              discipline,
              course.classCurrent.classId
            )

            setCourseData((prev) => ({
              ...prev,
              course,
              progress,
              discipline: discipline,
              apostila: apostila,
              materiaisComplementar: materialComplementar,
              simulados: simulados,
              openNextClassExpanded,
            }))

            setTimeout(() => {
              setTrakingState((prev) => ({
                ...prev,
                data: { ...prev.data, context_ip: ip },
                hasLoadedAllData: true,
                observableId: Date.now(),
              }))
            })
          })
          .catch((err: Error) => setErrorMessage(err.message))
          .finally(() =>
            setCourseData((prev) => ({ ...prev, isFetching: false }))
          )
      })
      .catch((error: Error | ErroAcessoCursoNaoAutorizado) => {
        if (error instanceof ErroAcessoCursoNaoAutorizado && sessionToken) {
          setErrorMessage('Seu acesso ao curso expirou')

          setTimeout(() => {
            sessionToken &&
              redirecionarParaEcommerce(sessionToken, expirationDate)
          }, 5000)

          return
        }
        setErrorMessage(error.message)
        setCourseData((prev) => ({ ...prev, isFetching: false }))
      })
  }, [])

  const refreshProgress = useCallback((id) => {
    const courseDetailsPromise = genericRequest(
      id,
      getCourse,
      'Falha ao buscar as informações do curso'
    )

    const courseProgressPromise = genericRequest(
      id,
      getProgress,
      'Falha ao buscar as informações do progresso do curso'
    )

    Promise.all([courseDetailsPromise, courseProgressPromise])
      .then((responses) => {
        const [course, progress] = responses

        setCourseData((prev) => {
          const openNextClassExpanded = computeNextOpenState(
            prev.discipline,
            course.classCurrent.classId
          )

          return {
            ...prev,
            progress,
            openNextClassExpanded,
            course: {
              ...prev.course,
              classCurrent: {
                ...prev.course.classCurrent,
                isFirstClass: course.classCurrent.isFirstClass,
              },
            },
          }
        })
      })
      .catch(() => null)
  }, [])

  useEffect(() => {
    courseId && fetchCouseData(courseId)
  }, [])

  useEffect(() => {
    courseId && courseData.classProgressRequestId && refreshProgress(courseId)
  }, [courseData.classProgressRequestId])

  return {
    courseId,
  }
}
