import { CriarNovaTentativa } from '@domain/exercicio-fixacao'
import httpClient from '@gateway/generalHttpRequest'

export const createNewAttempt: CriarNovaTentativa = (disciplineId) => {
  return new Promise((resolve, reject) => {
    const path = `/contents/api/v1/fixation_exercise/new_attempt?subject_id=${disciplineId}`

    httpClient
      .post<{ id: number }>(path)
      .then((response) => response.json())
      .then((data) => resolve({ idTentativa: data.id }))
      .catch((err) => reject(err))
  })
}
