export function handleUrlWithHttps(url: string): string {
  const httpProtocolsRegex = /(http(s?)):\/\//i

  const urlHasHttpProtocol = httpProtocolsRegex.test(url)

  if(!urlHasHttpProtocol) {
    return `https://${url}`
  }

  return url
}