import httpClient from '@gateway/generalHttpRequest'

export const getCertificatePdf = async (courseId: number) => {
  const path = `/offers/api/v1/certificate/${courseId}`

  const response = await httpClient.get(path)

  const blob = await response.blob()

  // return new Promise<string>((resolve) => {
  //   const reader = new FileReader()
  //   reader.onloadend = () => resolve(reader.result as string)
  //   reader.readAsDataURL(blob)
  // })
  return blob
}
