import { QuestaoExercicioFixacao } from '@domain/exercicio-fixacao'
import { DefaultValue, selector } from 'recoil'
import { fixationExerciseOverState } from '../fixation-exercise-stateOver'

const selectedQuestionIdUpdater = (
  listQuestion: QuestaoExercicioFixacao[]
):
  | Record<string, never>
  | { selectedQuestionId: number; questionIndex: number } => {
  let position = 0
  const initialQuestion = listQuestion.find((question, index) => {
    const isAnswerEmpty = question.respostaQuestao === null
    if (isAnswerEmpty) {
      position = index
    }
    return isAnswerEmpty
  })

  if (initialQuestion) {
    return {
      selectedQuestionId: initialQuestion?.questaoId,
      questionIndex: position,
    }
  }

  if (listQuestion.length !== 0) {
    return {
      selectedQuestionId: listQuestion?.[listQuestion.length - 1]?.questaoId,
      questionIndex: listQuestion.length - 1,
    }
  }

  return {}
}

export const questionsSelector = selector({
  key: 'questionsSelector',
  get: ({ get }) => {
    return {
      questions: get(fixationExerciseOverState).questions,
    }
  },

  set: ({ get, set }, data) => {
    if (!(data instanceof DefaultValue)) {
      set(fixationExerciseOverState, {
        ...get(fixationExerciseOverState),
        questions: data.questions,
        ...selectedQuestionIdUpdater(data.questions),
      })
    }
  },
})
