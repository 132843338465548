import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: 6,
    '@media screen and (max-width: 962px)': {
      border: 0,
      borderRadius: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      padding: 0,
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24)
    },
    '@media screen and (max-width: 603px)': {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24)
    },
    padding: theme.typography.pxToRem(16),
    position: 'relative'
  },
  questionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (min-width: 963px)': {
      marginBottom: theme.typography.pxToRem(16)
    },
    '@media screen and (max-width: 962px)': {
      padding: theme.typography.pxToRem(16)
    }
  },
  questionsTitle: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14)
  },
  questionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.typography.pxToRem(8),

    '@media screen and (max-width: 962px)': {
      display: 'flex',
      gap: theme.typography.pxToRem(16),
      overflow: 'auto',
      paddingTop: 0,
      padding: theme.typography.pxToRem(16),

      '& span': {
        flex: 1,
        maxWidth: 32,
        minWidth: 32
      }
    }
  },
  questionItem: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.23)',
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',

    '&.answered': {
      backgroundColor: '#e0e0e0'
    },

    '&.correct': {
      backgroundColor: '#8FC2B1'
    },

    '&.incorrect': {
      backgroundColor: '#D29598'
    },

    '&.active': {
      backgroundColor: '#4040A1',
      color: '#fff'
    }
  }
}))
