import { FC } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  CardActions,
  useMediaQuery,
} from '@material-ui/core'
import { PlayArrow } from '@material-ui/icons'
import useStyles from './styles'
import { useNavigate, generatePath } from 'react-router-dom'
import { ROUTES } from '@routes/app-routes'
import { useRecoilValue } from 'recoil'
import { courseDetailState } from '@atoms/course'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { trackingStateSelector } from '@atoms/tracking'

export const SubjectRedirector: FC = () => {
  const classes = useStyles()
  const courseState = useRecoilValue(courseDetailState)
  const trackingState = useRecoilValue(trackingStateSelector)
  const matches = useMediaQuery('(max-width:599px)')
  const {
    isFirstClass = false,
    classFullname = '',
    classId = '',
  } = courseState?.classCurrent || {}
  const navigateTo = useNavigate()

  const navigateToSubjects = () => {
    navigateTo(
      generatePath(ROUTES.DISCIPLINE, {
        courseId: courseState.id.toString(),
      })
    )
    Tracking.conteudoCurso(trackingState.data, tracking)
  }

  const navigateToClass = () => {
    navigateTo(
      generatePath(ROUTES.CLASS, {
        courseId: courseState.id.toString(),
        classId: classId.toString(),
      })
    )
    if (isFirstClass) {
      return Tracking.iniciarCurso(trackingState.data, tracking)
    }
    Tracking.continuarAula(trackingState.data, tracking)
  }

  return (
    <Container className={classes.container}>
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.cardContentStyle}>
          <Typography variant="h6">
            {isFirstClass ? 'Inicie seu Curso!' : 'Continue de onde parou'}
          </Typography>

          <Typography className={classes.subject}>
            {isFirstClass
              ? `Primeira aula: ${classFullname}`
              : `Aula atual: ${classFullname}`}
          </Typography>
        </CardContent>

        <CardActions className={classes.cardActionsStyle}>
          <Button
            className={classes.btnKeepOnSubjectTo}
            color="secondary"
            variant="contained"
            size="large"
            fullWidth={matches}
            startIcon={isFirstClass ? null : <PlayArrow />}
            onClick={navigateToClass}
          >
            {isFirstClass ? 'Começar estudos' : 'Continuar Aula'}
          </Button>

          <Button
            className={classes.btnGetOffSubjectTo}
            color="primary"
            size="large"
            fullWidth={matches}
            variant="outlined"
            onClick={navigateToSubjects}
          >
            ver conteúdo do curso
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}
