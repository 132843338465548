import { atom } from 'recoil'

type GeneralStateType = {
  /** Indica que o dialogo de biblioteca virtual deve ser exibido */
  isLibraryDialogOpen: boolean
}

export const generalState = atom<GeneralStateType>({
  key: 'generalState',
  default: {
    isLibraryDialogOpen: false,
  },
})
