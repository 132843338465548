import { FC } from 'react'
import ArrowBack from '@material-ui/icons/ArrowBack'
import useStyles from './styles.js'
import { Button } from '@equipe-ninja/saraiva-ui'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

export const BackButton: FC<{ className?: string; link?: string }> = ({
  className,
  link,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleClick = () => {
    if (link) {
      navigate(link)
      return
    }

    navigate(-1)
  }

  return (
    <Button
      color="primary"
      startIcon={<ArrowBack />}
      className={classNames(classes.button, className)}
      onClick={handleClick}
    >
      Voltar
    </Button>
  )
}
