export const redirecionamentoGenerico = (
  url: string,
  abrindoNovaAba = true
): void => {
  const target = url.match(/^http[s]?:\/\//) ? url : `https://${url}`
  if (abrindoNovaAba) {
    window.open(target, '_blank')
  } else {
    window.location.href = target
  }
}
