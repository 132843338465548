import { abriuCurso } from './tracking-abriu-curso'
import { progressoCurso } from './tracking-progresso-curso'
import { iniciarCurso } from './tracking-clicou-iniciar-curso'
import { continuarAula } from './tracking-clicou-continuar-aula'
import { conteudoCurso } from './tracking-clicou-conteudo-curso'
import { clicouGerarCertificado } from './tracking-clicou-gerar-certificado'
import { clicouFecharCertificado } from './tracking-clicou-fechar-certificado'
import { clicouValidarCertificado } from './tracking-clicou-validar-certificado'
import { clicouDeAcordo } from './tracking-clicou-de-acordo'
import { clicouCancelar } from './tracking-clicou-cancelar-certificado'
import { clicouProsseguirCertificado } from './tracking-clicou-prosseguir-certificado'
import { clicouArquivoCertificado } from './tracking-clicou-gerar-arquivo-certificado'
import { clicouMeusCertificados } from './tracking-clicou-meus-certificados'
import { clicouDisciplina } from './tracking-clicou-disciplina'
import { clicouTema } from './tracking-clicou-tema'
import { clicouAula } from './tracking-clicou-aula'
import { clicouMaterialAula } from './tracking-clicou-material-aula'
import { clicouMaterialComplementar } from './tracking-clicou-material-complementar'
import { clicouVerEnunciado } from './tracking-clicou-ver-enunciado'
import { clicouFazerAtividade } from './tracking-clicou-fazer-atividade'
import { clicouVerGabarito } from './tracking-clicou-ver-gabarito'
import { clicouMeusCursos } from './tracking-clicou-meus-cursos'
import { clicouBibliotecaVirtual } from './tracking-clicou-biblioteca-virtual'
import { clicouMaterialCurso } from './tracking-clicou-material-curso'
import { clicouAtividade } from './tracking-clicou-atividade'
import { clicouIniciarAtividade } from './tracking-clicou-iniciar-atividade'
import { clicouContinuarAtividade } from './tracking-clicou-continuar-atividade'
import { clicouRefazerAtividade } from './tracking-clicou-refazer-atividade'
import { clicouConfirmaRefazer } from './tracking-confirma-refazer'
import { clicouFinalizarAtividade } from './tracking-clicou-finalizar-atividade'
import { clicouVerUltimaTentativa } from './tracking-clicou-ver-ultima-tentativa'

export type { TrackingTask } from './tracking-task'

export type {
  TrackingData,
  TrackingTypes,
  trackingDataSlice,
} from './tracking-data'

export const Tracking = {
  abriuCurso,
  progressoCurso,
  iniciarCurso,
  continuarAula,
  conteudoCurso,
  clicouGerarCertificado,
  clicouFecharCertificado,
  clicouValidarCertificado,
  clicouDeAcordo,
  clicouCancelar,
  clicouProsseguirCertificado,
  clicouArquivoCertificado,
  clicouMeusCertificados,
  clicouDisciplina,
  clicouTema,
  clicouAula,
  clicouMaterialAula,
  clicouMaterialComplementar,
  clicouMaterialCurso,
  clicouVerEnunciado,
  clicouFazerAtividade,
  clicouVerGabarito,
  clicouMeusCursos,
  clicouBibliotecaVirtual,
  clicouAtividade,
  clicouIniciarAtividade,
  clicouContinuarAtividade,
  clicouRefazerAtividade,
  clicouConfirmaRefazer,
  clicouFinalizarAtividade,
  clicouVerUltimaTentativa,
}
