import { FC } from 'react'
import { useFetchCourseData } from '@hooks/use-fetch-course-data'
import { Outlet } from 'react-router-dom'
import { useTrackingInformation } from '@hooks/use-tracking-information'

export const CourseDataLayer: FC = () => {
  useFetchCourseData()
  useTrackingInformation()
  return <Outlet />
}
