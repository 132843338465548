export const computarTipoDispositivo = (
  userAgent: string
): 'mobile' | 'tablet' | 'desktop' => {
  const regexTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i
  const regexMobile =
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/

  if (userAgent.match(regexMobile)) return 'mobile'
  if (userAgent.match(regexTablet)) return 'tablet'

  return 'desktop'
}
