import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 0,
    marginBottom: 32,
    '@media (max-width: 599px)': {
      marginBottom: 24,
    },
  },

  icon: {
    fontSize: '0.85rem !important',
    fontWeight: 500,
  },
}))

export default useStyles
