export enum TrackingTypes {
  ABRIU_CURSO = 'abriu_curso',
  CLICOU_CURSO = 'clicou_iniciar_curso',
  PROGRESSO_CURSO = 'progresso_curso',
  CONTINUAR_CURSO = 'clicou_continuar_curso',
  CONTEUDO_CURSO = 'clicou_conteudo_curso',
  CLICOU_GERAR_CERTIFICADO = 'clicou_gerar_certificado',
  CLICOU_FECHAR = 'clicou_fechar',
  CLICOU_VALIDAR_CERTIFICADO = 'clicou_validar_dados_certificado',
  CLICOU_DE_ACORDO = 'clicou_de_acordo',
  CLICOU_CANCELAR = 'clicou_cancelar',
  CLICOU_PROSSEGUIR = 'clicou_prosseguir_certificado',
  CLICOU_ARQUIVO_CERTIFICADO = 'clicou_gerar_arquivo_certificado',
  CLICOU_MEUS_CERTIFICADOS = 'clicou_meus_certificados',
  CLICOU_DISCIPLINA = 'clicou_disciplina',
  CLICOU_TEMA = 'clicou_tema',
  CLICOU_AULA = 'clicou_aula',
  CLICOU_MATERIAL_AULA = 'clicou_material_aula',
  CLICOU_MATERIAL_COMPLEMENTAR = 'clicou_material_complementar',
  CLICOU_VER_ENUNCIADO = 'clicou_ver_enunciado',
  CLICOU_FAZER_ATIVIDADE = 'clicou_fazer_atividade',
  CLICOU_VER_GABARITO = 'clicou_ver_gabarito',
  CLICOU_MEUS_CURSOS = 'clicou_meus_cursos',
  CLICOU_BIBLIOTECA_VIRTUAL = 'clicou_biblioteca_virtual',
  CLICOU_MATERIAL_CURSO = 'clicou_material_curso',
  CLICOU_ATIVIDADE = 'clicou_atividade',
  CLICOU_INICIAR_ATIVIDADE = 'clicou_inicar_atividade',
  CLICOU_CONTINUAR_ATIVIDADE = 'clicou_continuar_atividade',
  CLICOU_REFAZER_ATIVIDADE = 'clicou_refazer_atividade',
  CLICOU_CONFIRMAR_REFAZER = 'clicou_confirma_refazer_atividade',
  CLICOU_FINALIZAR_ATIVIDADE = 'clicou_finalizar_atividade',
  CLICOU_VER_ULTIMA_TENTATIVA = 'clicou_ver_ultima_tentativa',
}

export type TrackingData = {
  user_id: number
  context_ip: string
  context_locale: string
  context_page_path: string
  context_page_referrer: string
  context_page_url: string
  context_screen: number
  context_user_agent: string
  device_category: string
  id_course: number
  name_course: string
  nome_categoria: string
  nome_subcategoria: string
  progresso_curso: string
}

const defaultKeys: Array<keyof TrackingData> = [
  'user_id',
  'context_ip',
  'context_locale',
  'context_page_path',
  'context_page_referrer',
  'context_page_url',
  'context_screen',
  'context_user_agent',
  'device_category',
  'id_course',
  'name_course',
  'nome_categoria',
  'nome_subcategoria',
]

export const trackingDataSlice = (
  data: TrackingData,
  keys: Array<keyof TrackingData> = defaultKeys
): Partial<TrackingData> => {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(data).filter(([key, value]) =>
      (keys as [string]).includes(key)
    )
  )
}
