import { ConteudoAgendado } from '@domain/conteudos-agendados'
import { ScheduledContent } from '../remote-types/scheduled-content'
import { ScheduledContentTypeAdapter } from '../adapters/scheduled-content-type'
import { RemoteDateUtils } from '@services/utils/remote-date-utils'

const inbound = (remoteData: ScheduledContent[]): ConteudoAgendado[] => {
  return remoteData.map<ConteudoAgendado>((d) => ({
    id: d.id,
    dataFinal: RemoteDateUtils.parseDateString(d.final_date),
    dataInicio: RemoteDateUtils.parseDateString(d.initial_date),
    dataGabarito: RemoteDateUtils.parseDateString(d.template_date),
    linkFormulario: d.form_link,
    tituloEnunciado: d.title,
    textoEnunciado: d.text_enunciated,
    textoGabarito: d.template,
    tipo: ScheduledContentTypeAdapter.inbound(d.type),
  }))
}

export const ScheduledContentAdapter = {
  inbound,
}
