import { ConteudoAgendado } from '../conteudo-agendado'
import { StatusConteudoAgendado } from '../status-conteudo-agendado'

export const computarStatusConteudoAgendado = (
  conteudoAgendado: ConteudoAgendado
): StatusConteudoAgendado => {
  const now = new Date()

  if (now < conteudoAgendado.dataInicio)
    return StatusConteudoAgendado.INDISPONIVEL

  if (now > conteudoAgendado.dataFinal) return StatusConteudoAgendado.CONCLUIDO

  return StatusConteudoAgendado.DISPONIVEL
}
