import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: 332,
    marginTop: 0,
  },
  primaryText: {
    color: theme.palette.text.primary,
    width: 400,
    fontSize: 14,
  },

  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: 10,
  },

  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  newReleasesRoundedIcon: {
    fontSize: 40,
    marginRight: 16,
    padding: 0,
    // borderRadius: 'initial',
    color: theme.palette.primary.dark,

    '&:hover': {
      backgroundColor: 'initial',
    },
  },

  widgetIcon: {
    color: '#FFFFFF',
    fontSize: 33,
  },
}))

export default useStyles
