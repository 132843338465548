import { FC, useState } from 'react'
import { Dialog } from '@equipe-ninja/saraiva-ui'

import useStyles from './styles'
import { useRecoilValue } from 'recoil'
import { conteudosAgendadosSelector } from '@atoms/conteudo-agendado'
import { ExerciceCard } from './ExerciseCard'

export const ExerciseList: FC = () => {
  const [exerciseDialog, setExerciseDialog] = useState({
    isOpen: false,
    title: '',
    description: '',
  })
  const classes = useStyles()
  const conteudosAgendados = useRecoilValue(conteudosAgendadosSelector)

  const handleSetDialog = (title: string, description: string) => {
    setExerciseDialog({ title: title, description: description, isOpen: true })
  }

  const handleCloseDialog = () => {
    setExerciseDialog({
      ...exerciseDialog,
      isOpen: false,
    })
  }

  return (
    <div className={classes.wrapper}>
      {conteudosAgendados?.map((conteudo) => (
        <ExerciceCard
          conteudo={conteudo}
          onVerEnunciado={handleSetDialog}
          onVerGabarito={handleSetDialog}
          key={conteudo.id}
        />
      ))}

      {exerciseDialog && (
        <Dialog
          title={exerciseDialog.title}
          primaryButton="fechar"
          size="lg"
          open={exerciseDialog.isOpen}
          handleConfirm={() => handleCloseDialog()}
          confirmWithoutCheck={() => handleCloseDialog()}
        >
          {exerciseDialog.description}
        </Dialog>
      )}
    </div>
  )
}
