import { BuscarConteudoAgendado } from '@domain/conteudos-agendados'
import HttpClient from '@gateway/generalHttpRequest'
import { getRequestResponseHandler } from '@services/utils/response-handlers'
import { ScheduledContent } from '../remote-types/scheduled-content'
import { ScheduledContentAdapter } from '../adapters/scheduled-content'

export const getCourseScheduledContent: BuscarConteudoAgendado = (courseId) => {
  const path = `/contents/api/v1/scheduled_contents_by_course?id=${courseId}`

  return new Promise((resolve, reject) => {
    HttpClient.get<ScheduledContent[]>(path, {})
      .then((rawData) => getRequestResponseHandler(rawData))
      .then((data) => {
        resolve(
          ScheduledContentAdapter.inbound(data).sort((a, b) =>
            a.id < b.id ? -1 : 1
          )
        )
      })
      .catch((err) => reject(err))
  })
}
