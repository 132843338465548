import queryString from 'query-string'
import { JwtPayload } from './types'

export const getSessionToken = (): string | (string | null)[] | null => {
  const searchValues = queryString.parse(window.location.search)

  return searchValues.sessionToken || localStorage.getItem('sessionToken')
}

export const setSessionToken = (sessionToken: string): void => {
  localStorage.setItem('sessionToken', sessionToken)
}

export const removeSessionToken = (): void => {
  localStorage.removeItem('sessionToken')
}

export const getApplicationToken = (): string => {
  return process.env.REACT_APP_TOKEN as string
}

export const isAuthenticated = (): boolean => {
  return getSessionToken() !== null
}

export const isUnauthorized = (status: number): boolean => {
  return status === 401
}

export const parseJwt = (token: string): JwtPayload => {
  const base64Url: string = token.split('.')[1]

  const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  const jsonPayload: string = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const redirectToLogin = (): void => {
  const { REACT_APP_TOKEN, REACT_APP_SSO_URL } = process.env

  const previousUrl = `${window.location.protocol}//${window.location.host}`

  const loginUrl = `${REACT_APP_SSO_URL}/auto-login?previousUrl=${previousUrl}&applicationToken=${REACT_APP_TOKEN}&theme=lfgModern`

  window.location.href = loginUrl
}
