import { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { ContentRedirector } from '@components/ContentRedirector'
import { CourseProgress } from '@components/CourseProgress'
import { SubjectRedirector } from '@components/SubjectRedirector'
import { AccessWindowedContentRedirector } from '@components/AccessWindowedContentRedirector'
import useAppStyles from '@components/AppStyles'
import useStyles from './styles'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { courseDetailState } from '@atoms/course/course-selector'
import { Tracking } from '@domain/trackings'
import { tracking } from '@services/tracking'
import { trackingStateSelector } from '@atoms/tracking'
import { materialComplementarSelector } from '@atoms/course'
import TutorialDialog from '@pages/Curso/TutorialDialog'
import { selectedTypeSelector } from '@atoms/guide-tour'
import Loading from './Loading'

export const Curso: FC = () => {
  const globalClasses = useAppStyles()
  const classes = useStyles()
  const courseState = useRecoilValue(courseDetailState)
  const { materiaisComplementar } = useRecoilValue(materialComplementarSelector)
  const trackingState = useRecoilValue(trackingStateSelector)
  const [previousTrackingId] = useState(trackingState.observableId)
  const setTourType = useSetRecoilState(selectedTypeSelector)

  useEffect(() => {
    if (
      trackingState.hasLoadedAllData &&
      previousTrackingId !== trackingState.observableId
    ) {
      setTimeout(() => {
        Tracking.abriuCurso(trackingState.data, tracking)
        Tracking.progressoCurso(trackingState.data, tracking)
      })
    }
  }, [trackingState.observableId])

  useEffect(() => {
    setTourType((prev) => ({ ...prev, selectedType: 'home' }))
  }, [setTourType])

  return (
    <>
      {courseState.isFetching ? (
        <div style={{ marginTop: 300 }}>
          <Loading />
        </div>
      ) : (
        <>
          <div style={{ width: '100%' }}>
            <Typography variant="h4" className={globalClasses.title}>
              Painel geral do curso
            </Typography>
            <Box className={classes.customGrid}>
              <Box className={classes.gridLeft}>
                <SubjectRedirector />

                <CourseProgress />
              </Box>

              {courseState.secondPhaseExerciseExists === true && (
                <Box
                  className={`${classes.gridRight} tour-home-passo-2-fase-OAB`}
                >
                  <AccessWindowedContentRedirector />
                </Box>
              )}
            </Box>
            {materiaisComplementar.length === 0 ? (
              <div></div>
            ) : (
              <>
                <Typography variant="h6" className={globalClasses.subtitle}>
                  <div className={'tour-home-passo1-material-complementar'}>
                    Materiais complementares
                  </div>
                </Typography>
                <ContentRedirector />
              </>
            )}
          </div>
          <TutorialDialog />
        </>
      )}
    </>
  )
}
