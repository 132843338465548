import { DialogBibliotecaVirtual } from '@pages/DialogBibliotecaVirtual'
import { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Header } from '@components/Header'

import useStyles from './styles'
import { generalState } from '@atoms/general'
import { useRecoilState } from 'recoil'

export const SimpleLayout: FC = () => {
  const styles = useStyles()
  const location = useLocation()
  const [state, setState] = useRecoilState(generalState)

  const handleCreatePageName = () => {
    const path = location.pathname.replace('/', '')
    const pageName = path.replaceAll('-', ' ')

    return pageName
  }

  return (
    <>
      <Header title={handleCreatePageName()} />

      <main className={styles.Main}>
        <div className={styles.Pages}>
          <Outlet />
        </div>

        <DialogBibliotecaVirtual
          open={state.isLibraryDialogOpen}
          onClose={() =>
            setState((prev) => ({ ...prev, isLibraryDialogOpen: false }))
          }
        />
      </main>
    </>
  )
}
