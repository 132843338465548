import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  dialogContent: {
    padding: '24px',
  },
  warning: {
    display: 'flex',
  },
  warningIcon: {
    color: theme.palette.primary.main,
    fontSize: 64,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  warningContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  btnHiddenInXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  btnShowUpXs: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))
