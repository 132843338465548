import {
  DadosTentativa,
  QuestaoExercicioFixacao,
  ResumoExercicioFixacao,
} from '@domain/exercicio-fixacao'
import { atom } from 'recoil'

type FixationExerciseStateType = {
  disciplineId: number
  attemptId: number
  questions: QuestaoExercicioFixacao[]
  selectedQuestion: QuestaoExercicioFixacao | null
  questionIndex: number
  isExecutingLog: boolean
  selectedQuestionId: number
  attempt: DadosTentativa | null
  overview: ResumoExercicioFixacao | null
  resetObservable: number
  showCurrentAnswer: boolean
  showAllAnswers: boolean
  isExecutingExercise: boolean
}

export const fixationExerciseOverState = atom<FixationExerciseStateType>({
  key: 'fixationExerciseOverState',
  default: {
    disciplineId: 0,
    overview: null,
    attemptId: 0,
    questions: [],
    selectedQuestion: null,
    questionIndex: 0,
    isExecutingLog: false,
    selectedQuestionId: 0,
    attempt: null,
    resetObservable: 0,
    showCurrentAnswer: false,
    showAllAnswers: false,
    isExecutingExercise: false,
  },
})
