export const typeAdapter = <
  T extends Record<W, string>,
  U extends Record<W, string>,
  V,
  X,
  W extends string
>(
  LOCAL: T,
  REMOTE: U,
  typeName: string,
  defaultValue?: { inbound: V; outbound: X }
) => {
  const defaultValues = { inbound: null, outbound: null }
  const emptyValues = { ...defaultValues, ...defaultValue }

  const inbound = (type: U[keyof U]): T[keyof T] => {
    if (type === null) {
      return emptyValues.inbound as unknown as T[keyof T]
    }

    const [key] = Object.entries(REMOTE).find(
      (keyValueArr) => keyValueArr[1] === type
    ) ?? [null]

    if (!key) {
      throw new Error(
        `${String(type)} is not a valid remote value for ${typeName} type`
      )
    }

    return LOCAL[key as keyof T]
  }

  const outbound = (type: T[keyof T]): U[keyof U] => {
    if (type === null) {
      return emptyValues.outbound as U[keyof U]
    }

    const [key] = Object.entries(LOCAL).find(
      (keyValueArr) => keyValueArr[1] === type
    ) ?? [null]

    if (!key) {
      throw new Error(
        `${String(type)} is not a valid local value for ${typeName} type`
      )
    }

    return REMOTE[key as keyof U]
  }

  return {
    inbound,
    outbound,
  }
}
