import { useEffect, useState } from 'react'

import { useStyles } from './styles'
import BoasVindas from '@images/boas-vindas-web.png'
import MaterialEstudo from '@images/material-de-estudo-web.png'
import { ChavesArmazenamentoLocal } from '@domain/armazenamento-local'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'

import { Close } from '@material-ui/icons'
import { Button } from '@equipe-ninja/saraiva-ui'

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function TutorialDialogTitle(props: DialogTitleProps) {
  const { children, onClose } = props
  const classes = useStyles()

  return (
    <DialogTitle>
      <div className={classes.topDialog}>
        {children}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  )
}

const dialogContents = [
  {
    title: 'Boas-Vindas',
    content: (
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 500, color: '#0A5A51' }}>
          Desejamos boas-vindas à nova plataforma de aprendizagem da LFG
        </span>
        , construída para aprimorar a sua jornada de estudo.
      </Typography>
    ),
    image: BoasVindas,
  },
  {
    title: 'Materiais de estudo',
    content: (
      <Typography variant="body2" gutterBottom>
        Preparamos explicações para você melhor usufruir dos materiais de estudo{' '}
        <span style={{ fontWeight: 500, color: '#0A5A51' }}>
          disponíveis em algumas páginas da plataforma.
        </span>{' '}
        Para acessar basta clicar no ícone de informação localizado no topo da
        página ao lado direito
      </Typography>
    ),
    image: MaterialEstudo,
  },
]

export default function TutorialDialog() {
  const [stepper, setStepper] = useState(0)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleNextStreeper = () => {
    setStepper((prev) => prev + 1)
  }
  const handleBackStreeper = () => {
    setStepper((prev) => prev - 1)
  }
  const handleClose = () => {
    localStorage.setItem(ChavesArmazenamentoLocal.PRIMEIRO_ACESSO, 'true')
    setOpen(false)
  }

  useEffect(() => {
    if (!localStorage.getItem(ChavesArmazenamentoLocal.PRIMEIRO_ACESSO)) {
      setOpen(true)
    }
  }, [])

  return (
    <div>
      <Dialog className={classes.root} onClose={handleClose} open={open}>
        <TutorialDialogTitle onClose={handleClose}>
          {dialogContents[stepper].title}
        </TutorialDialogTitle>
        <DialogContent dividers className={classes.contentDialog}>
          {
            <img
              style={{ paddingBottom: 24 }}
              src={dialogContents[stepper].image}
            />
          }
          {<div>{dialogContents[stepper].content}</div>}
        </DialogContent>
        <DialogActions className={classes.bottomDialog}>
          <div className={classes.stepperAling}>
            {dialogContents.map((_, index) => (
              <div
                style={{
                  borderRadius: 50,
                  width: 10,
                  height: 10,
                  marginRight: 8,
                }}
                key={index}
                className={
                  stepper === index ? classes.stepperOn : classes.stepperOff
                }
              />
            ))}
          </div>
          <div className={classes.buttonAling}>
            <Button
              color="primary"
              onClick={stepper === 0 ? handleNextStreeper : handleBackStreeper}
              className={stepper === 0 ? classes.buttonOn : classes.buttonOff}
            >
              {stepper === 0 ? 'Próximo' : 'Anterior'}
            </Button>
            {stepper === 0 ? null : (
              <Button color="primary" onClick={handleClose}>
                Fechar
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
