import { selector } from 'recoil'
import { courseState } from '../course-state'

export const materialComplementarSelector = selector({
  key: 'materialComplementarSelector',
  get: ({ get }) => {
    return {
      courseId: get(courseState).course.id,
      materiaisComplementar: get(courseState).materiaisComplementar,
    }
  },
})
