import { FinalizarTentativa } from '@domain/exercicio-fixacao'
import httpClient from '@gateway/generalHttpRequest'

export const finishAttempt: FinalizarTentativa = (attemptId) => {
  return new Promise((resolve, reject) => {
    const path = `contents/api/v1/fixation_exercise/attempt/${attemptId}/finish`

    httpClient
      .put<void>(path)
      .then((response) => response.json())
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}
