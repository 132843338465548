import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    accordion: {
      background: theme.palette.common.white,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '8px',
    },
    accordionSummary: {
      background: theme.palette.common.white,
      borderRadius: '8px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      minHeight: '52px',
      padding: '0px 24px',
      '& > .MuiIconButton-root': {
        padding: '16px',
      },
      '& > .MuiAccordionSummary-expandIcon.Mui-expanded': {
        color: '#000082',
      },
      '& > .MuiAccordionSummary-content.Mui-expanded ': {
        margin: '12px 0',
      },
      '&.Mui-expanded': {
        margin: '0px',
        borderRadius: '8px 8px 0 0',
        minHeight: '52px',
      },
      '@media (max-width: 599px)': {
        padding: '0px 24px 0px 16px',
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      borderRadius: '0 0 8px 8px',
      overflow: 'hidden',
    },
    heading: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headingName: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '150%',
      color: '#000082',
      '@media (max-width: 599px)': {
        fontSize: '14px',
      },
    },
  })
)
