import { Curso } from '@domain/curso'
import { RemoteCourseData } from '../remote-fields/course-data'

const inbound = (data: RemoteCourseData): Curso => {
  return {
    categoria: data?.course?.category?.id
      ? {
          id: data?.course?.category?.id,
          nome: data?.course?.category?.name,
        }
      : null,
    subcategoria: data?.course?.subcategory?.id
      ? {
          id: data?.course?.subcategory?.id,
          nome: data?.course?.subcategory?.name,
        }
      : null,
    id: data?.course?.course_id,
    name: data?.course?.course_name,
    classCurrent: {
      classId: data?.next_class?.class_id,
      classTitle: data?.next_class?.class_name,
      classFullname: data?.next_class?.class_full_name,
      classRelationshipId: data?.next_class?.class_relationship_id,
      isFirstClass: data?.next_class?.is_first_class,
    },
    secondPhaseExercise: {
      dataFinal: data?.second_phase_exercise?.date_final,
      dataInicial: data?.second_phase_exercise?.date_initial,
      linkForms: data?.second_phase_exercise?.form_link,
      answerSheet: data?.second_phase_exercise?.template,
      answerSheetDate: data?.second_phase_exercise?.template_date,
      textEnunciated: data?.second_phase_exercise?.text_enunciated,
      title: data?.second_phase_exercise?.title,
      type: data?.second_phase_exercise?.type,
    },
    secondPhaseExerciseExists: data?.second_phase_exercise_exists,
    secondPhaseExerciseCount: data?.second_phase_exercise_count,
  }
}

export const CourseAdapter = {
  inbound,
}
