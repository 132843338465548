import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container500: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'calc(100vh - 200px)',
    margin: '232px auto 24px',
    width: '100%',
    maxWidth: '1280px',
    '@media (max-width:959px)': {
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
      marginTop: '94px',
      height: 'calc(100vh - 157px)',
    },
    '@media (max-width:657px)': {
      height: 'calc(100vh - 219px)',
    },
    '@media (max-width:492px)': {
      height: 'calc(100vh - 245px)',
    },
  },
  container404: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '232px auto 24px',
    width: '100%',
    maxWidth: '1280px',
    '@media (max-width:959px)': {
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
      marginTop: '94px',
    },
  },
  content500: {
    '@media (min-width:960px)': { minWidth: '352px' },
    height: '401px',
    '@media (max-width:959px)': {
      margin: '0 auto',
    },
  },
  content404: {
    width: '433px',
    marginRight: 24,
    '@media (max-width:959px)': {
      margin: '0',
      minWidth: '328px',
      width: '100%',
    },
  },
  container_text: { color: theme.palette.primary.dark },
  textColor: { color: theme.palette.text.secondary, textAlign: 'left' },
  text: { margin: 0, fontSize: '16px' },
  textReload: { marginTop: 40, fontSize: '16px' },
  error: {
    fontWeight: 600,
    fontSize: 34,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
    '@media (max-width:959px)': {
      textAlign: 'center',
    },
  },
  page_not_found: {
    fontSize: 20,
    height: '25px',
    lineHeight: '160%',
    '@media (max-width:959px)': {
      textAlign: 'center',
    },
  },
  page_not_foundText: {
    marginTop: 32,
    marginBottom: 12,
    fontSize: '16px',
    textAlign: 'left',
  },
  list_color: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 400,
  },
  list: { paddingLeft: 20, marginBottom: 40 },
  button: {
    border: '1px solid rgba(64,64,161,.5)',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '26px',
    margin: '32px 0 0',
    textAlign: 'center',
    width: '101px',
  },
  link: {
    textDecoration: 'none',
  },
  img: {
    maxWidth: 719,
    '@media (max-width:959px)': {
      margin: '0 auto 20px',
    },
  },
  img404: {
    maxWidth: 618,
    '@media (max-width:959px)': {
      margin: '0 auto 20px',
    },
  },
  image: { objectFit: 'cover', width: '100%' },
}))
