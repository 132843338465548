import { BuscaResumoExercicioFixacao } from '@domain/exercicio-fixacao'
import { getRequestResponseHandler } from '@services/utils/response-handlers'
import httpClient from '../../gateway/generalHttpRequest'
import { OverviewAdapter } from './adapter/fixation-exercise-overview'
import { FixationExerciseOverview } from './remote-fields/fixation-exercise-overview'

const defaultExercises = {
  emProgresso: false,
  quantidadeQuestoes: 0,
  subjectId: 0,
  tentativaAtual: 0,
  totalTentativas: 0,
  userId: 0,
}

const getFixationExerciseExist = (
  disciplineId: number
): Promise<[boolean, number]> => {
  return new Promise((resolve) => {
    const path = `/contents/api/v1/fixation_exercise?subject_id=${disciplineId}&per_page=10&page=1`
    httpClient
      .get<object & { count: number }>(path)
      .then((response) => response.json())
      .then((data) => {
        if (Object.prototype.hasOwnProperty.call(data, 'count')) {
          resolve([true, data.count])
        } else {
          resolve([false, 0])
        }
      })
      .catch(() => resolve([false, 0]))
  })
}

export const getFixationExerciseOverview: BuscaResumoExercicioFixacao = (
  disciplineId
) => {
  const path = `/contents/api/v1/fixation_exercise/info_by_subject?subject_id=${disciplineId}`

  if (disciplineId === 0) {
    return Promise.resolve({ ...defaultExercises, existeExercicio: false })
  }

  return new Promise((resolve) => {
    getFixationExerciseExist(disciplineId).then(([hasExercise, count]) => {
      if (!hasExercise) {
        resolve({ ...defaultExercises, existeExercicio: false })

        return
      }

      httpClient
        .get(path)
        .then((response) => getRequestResponseHandler(response))
        .then((jsonData: FixationExerciseOverview) =>
          resolve({
            ...OverviewAdapter.inbound(jsonData),
            existeExercicio: true,
            subjectId: disciplineId,
            quantidadeQuestoes: count,
          })
        )
        .catch(() =>
          resolve({
            ...defaultExercises,
            existeExercicio: true,
            subjectId: disciplineId,
            quantidadeQuestoes: count,
          })
        )
    })
  })
}
