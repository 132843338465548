import { QuestaoExercicioFixacao } from '../questão-exercicio-fixacao'

export const alternativaCorretaQuestao = (
  questao: QuestaoExercicioFixacao
): string => {
  if (!questao) {
    return ''
  }

  const indexAlternativa = questao.alternativas.findIndex(
    (alternativa) => alternativa.alternativaId === questao.alternativaCorretaId
  )

  switch (indexAlternativa) {
    case 0:
      return 'A'
    case 1:
      return 'B'
    case 2:
      return 'C'
    case 3:
      return 'D'
    case 4:
      return 'E'
    default:
      return ''
  }
}
