export enum ScheduledContentType {
  PECA_PROCESSUAL = 'peca_processual',
  BLOCO_QUESTOES = 'bloco_questoes',
}

export type ScheduledContent = {
  active: boolean
  course_id: number
  final_date: string
  form_link: string
  id: number
  initial_date: string
  inserted_at: string
  template: string
  template_date: string
  text_enunciated: string
  title: string
  type: ScheduledContentType
  updated_at: '2022-11-17T12:34:38'
}
