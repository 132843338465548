import { makeStyles } from '@material-ui/core/styles'

const useStyles = (props: { colorTag: string; variant: string } | undefined) =>
  makeStyles((theme) => ({
    container: {
      backgroundColor: theme.palette.background.paper,
      display: 'inline-flex',
      width: '100%',
      maxWidth: 312,
      height: 70,
      padding: 0,
      margin: props?.variant === 'classroom' ? '16px 16px 0px 0px' : '0',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      cursor: 'pointer',
      '&:last-child': {
        marginRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        margin:
          props?.variant === 'classroom'
            ? '16px 12px 0px 0px'
            : '0px 12px 16px 0px',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
    leftTag: {
      width: 9,
      margin: 0,
      height: 70,
      left: 0,
      backgroundColor:
        props?.colorTag === 'primary'
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
      borderRadius: '4px 0px 0px 4px',
    },
    name: {
      padding: 24,
      textAlign: 'left',
      width: 'calc(100% - 40px)',
    },
    link: {
      color: theme.palette.text.primary,
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      width: '100%',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    linkIconPosition: {
      position: 'absolute',
      right: '24px',
    },
  }))

export default useStyles
