import { MaterialComplementar } from '@domain/curso'
import httpClient from '@gateway/generalHttpRequest'
import { getRequestResponseHandler } from '@services/utils/response-handlers'
import { MaterialComplementarAdapter } from './adapter-material'

const NOME_SIMULADO = 'Simulado'

export const getMaterials = (
  courseId: number
): Promise<{
  materialComplementar: MaterialComplementar[]
  simulados: MaterialComplementar[]
}> => {
  const { inbound } = MaterialComplementarAdapter
  return new Promise((resolve, reject) => {
    const path = `/contents/api/v1/complementary_material/?course_id=${courseId}`

    httpClient
      .get(path)
      .then((response) => getRequestResponseHandler(response))
      .then((data) => {
        const materiaisComSimulados = inbound(data)
        resolve({
          materialComplementar: materiaisComSimulados.filter(
            (materiais) => materiais.nome !== NOME_SIMULADO
          ),
          simulados: materiaisComSimulados.filter(
            (simulado) => simulado.nome === NOME_SIMULADO
          ),
        })
      })
      .catch((err) => reject(err))
  })
}
