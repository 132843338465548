import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ProgressBar } from '@equipe-ninja/saraiva-ui'
import useStyles from './styles'

export default function Loading() {
  const [value, setValue] = useState(0)
  const [subTitle, setSubTitle] = useState('')
  const [counter, setCounter] = useState(0)

  const PROGRESS_BAR_GROWTH_RATE = 0.5

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const classes = useStyles()

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter <= 2) {
        setValue(40)
      }

      if (counter > 2 && counter % 2 === 0 && value <= 90) {
        setValue(
          (prevValue) => prevValue + (PROGRESS_BAR_GROWTH_RATE / 100) * 100
        )
      }

      if (counter === 10) {
        setSubTitle('Seu curso está sendo carregado')
      }

      if (counter === 40) {
        setSubTitle(
          'Aguarde, cursos grandes como esse podem demorar mais que o esperado para carregar.'
        )
      }

      if (counter === 120) {
        setSubTitle(
          'O carregamento está demorando mais que o esperado. Verifique sua conexão ou aguarde mais um pouco.'
        )
      }

      setCounter((prevCounter) => prevCounter + 2)
    }, 2000)

    return () => clearInterval(interval)
  }, [counter, value])

  return (
    <Box className={classes.container}>
      <Typography component="h2" className={classes.title}>
        Carregando...
      </Typography>

      <ProgressBar
        color="primary"
        small
        value={value}
        className={classes.progressBar}
        size={isDesktop ? 'md' : 'sm'}
      />

      {subTitle && (
        <Typography component="p" className={classes.subTitle}>
          {subTitle}
        </Typography>
      )}
    </Box>
  )
}
