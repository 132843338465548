import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: 6,
    '@media screen and (max-width: 962px)': {
      border: 0,
      borderRadius: 0
    },
    padding: theme.typography.pxToRem(24)
  },
  questionBadge: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    width: 'fit-content',
    textTransform: 'uppercase',
    padding: theme.typography.pxToRem(5),
    lineHeight: '15px',
    verticalAlign: 'center',
    borderRadius: 8
  },
  questionEnunciation: {
    lineHeight: '150%',
    letterSpacing: '0.15px',
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(32)
  },
  questionRadioGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& .question-alternative': {
      padding: theme.typography.pxToRem(14),
      borderRadius: 8,
      width: '100%'
    },

    '& .question-alternative.incorrect': {
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #A42C31'
    },
    '& .question-alternative.correct': {
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #1F8563'
    },

    '& .question-alternative .answer-message': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.typography.pxToRem(8),
      marginLeft: theme.typography.pxToRem(36),
      marginTop: theme.typography.pxToRem(12)
    }
  },
  questionAlternativeLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(7)
  },
  answerVisibilityButton: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.typography.pxToRem(18)
  },

  radio: {
    padding: 8,
    transform: 'translateY(-8px)'
  }
}))
