import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: 0,
  },
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  boxTitleMob: {
    display: 'flex',
    background: theme.palette.background.paper,
    alignItems: 'center',
    padding: '16px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  checkCircle: {
    color: theme.palette.secondary.light,
    width: 32,
    height: 32,
    marginRight: 16,
    zIndex: 2,
    position: 'relative',
  },
  videoCam: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 32,
    height: 32,
    marginRight: 16,
  },
  buttonPrev: {
    paddingTop: 2,
    paddingBottom: 2,
    margin: 0,
    flexShrink: 0,
    flexGrow: 0,
    marginLeft: 30,
    '& > span:first-child > span:first-child': {
      [theme.breakpoints.down(1100)]: {
        margin: 0,
      },
    },
    '&:focus': {
      opac: 'none',
    },
    '&:disabled': {
      background: theme.palette.grey[200],
    },
  },
  nextPrevButtonText: {
    [theme.breakpoints.down(1100)]: {
      display: 'none',
    },
  },
  nextPrevIcon: {
    margin: 0,
  },
  buttonPrevMob: {
    cursor: 'pointer',
  },
  buttonNext: {
    color: theme.palette.primary.dark,
    marginLeft: 16,
    flexShrink: 0,
    flexGrow: 0,
    background: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
    paddingTop: 2,
    paddingBottom: 2,
    margin: 0,
    marginRight: 30,
    '& > span:first-child > span:first-child': {
      [theme.breakpoints.down(1100)]: {
        margin: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      float: 'right',
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main,
      opacity: 0.8,
    },
  },
  buttonNextMob: {
    marginLeft: 16,
    cursor: 'pointer',
  },
  boxClassName: {
    display: 'flex',
    marginLeft: 16,
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '85%',
    },
  },
  classTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  video: {
    width: '100%',
  },
  iframe: {
    width: '100%',
    height: '60vh',
    minWidth: 360,
    minHeight: 400,
    border: 'none',
  },
  checkedColorEffect: {
    position: 'absolute',
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.dark,
    zIndex: 1,
    left: 4,
    top: 4,
    borderRadius: '50%',
  },
}))

export default useStyles
