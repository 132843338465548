import { MaterialsProps } from '@domain/discipline'
import { isMediaMaterial } from '@domain/material/business'
import { InsertDriveFile, Link } from '@material-ui/icons'
import { ROUTES } from '@routes/app-routes'
import { FC } from 'react'
import { useNavigate, generatePath, useParams } from 'react-router-dom'
import { useStyles } from './styles'
import cls from 'classnames'
import { Tracking } from '@domain/trackings'
import { useRecoilValue } from 'recoil'
import { trackingStateSelector } from '@atoms/tracking'
import { tracking } from '@services/tracking'
import { TipoDownload } from '@domain/geral'

type RowClassMaterialProps = {
  material: MaterialsProps
  classId: number
  disciplineName: string
}

export const RowClassMaterial: FC<RowClassMaterialProps> = ({
  material,
  classId,
}) => {
  const classes = useStyles()
  const navigateTo = useNavigate()
  const { courseId } = useParams()
  const trackingState = useRecoilValue(trackingStateSelector)

  const handleOpenMaterial = () => {
    const fileName = String(material?.fileName.split('.')[1]).toUpperCase()
    Tracking.clicouMaterialAula(
      trackingState.data,
      tracking,
      material.nameMaterial,
      fileName
    )

    if (material.fileName) {
      const fileDownloadPath = generatePath(ROUTES.FILE_DOWNLOAD, {
        courseId: courseId ?? '',
        filename: encodeURI(material.fileName),
        type: TipoDownload.CONTEUDO,
      })

      window.open(fileDownloadPath, '_blank')
      return
    }

    courseId &&
      navigateTo(
        generatePath(ROUTES.CLASS, {
          courseId: courseId,
          classId: classId.toString(),
        })
      )
  }

  const isMedia = isMediaMaterial(material)

  return (
    <div
      className={cls(classes.contentTheme, isMedia && classes.hidden)}
      aria-hidden={isMedia}
    >
      <div className={classes.divLinkMaterial} onClick={handleOpenMaterial}>
        <InsertDriveFile className={classes.fileIcon} />

        <span className={classes.nameContentTheme}>
          {material.nameMaterial}
        </span>
      </div>

      <Link
        className={classes.link}
        onClick={handleOpenMaterial}
        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
      />
    </div>
  )
}
