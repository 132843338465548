import { Snackbar } from '@equipe-ninja/saraiva-ui'
import { useContext } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'
import snackbarStyle from './styles'

function AlertMessage() {
  const snackbarStyles = snackbarStyle()
  const {
    successMessage,
    errorMessage,
    infoMessage,
    warningMessage,
    setErrorMessage,
    setInfoMessage,
    setSuccessMessage,
    setWarningMessage,
  } = useContext(GeneralContext)

  const autoHideDuration = 5000

  return (
    <>
      <Snackbar
        severity="success"
        show={!!successMessage}
        handleClose={() => setSuccessMessage('')}
        autoHideDuration={autoHideDuration}
        classes={() => snackbarStyles}
      >
        {successMessage}
      </Snackbar>

      <Snackbar
        severity="error"
        show={!!errorMessage}
        handleClose={() => setErrorMessage('')}
        classes={() => snackbarStyles}
      >
        {errorMessage}
      </Snackbar>

      <Snackbar
        severity="info"
        show={!!infoMessage}
        handleClose={() => setInfoMessage('')}
        autoHideDuration={autoHideDuration}
        classes={() => snackbarStyles}
      >
        {infoMessage}
      </Snackbar>

      <Snackbar
        severity="warning"
        show={!!warningMessage}
        handleClose={() => setWarningMessage('')}
        autoHideDuration={autoHideDuration}
        classes={() => snackbarStyles}
      >
        {warningMessage}
      </Snackbar>
    </>
  )
}

export default AlertMessage
